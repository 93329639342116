import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.1";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Register from "pages/Auth/UserRegistration";
import { Provider } from "react-redux";
import { reducers } from "reducers";
import { configureStore } from "@reduxjs/toolkit";
import ResetPassword from "pages/Auth/ResetPassword";
import UserVerifyEmail from "pages/Auth/UserVerifyEmail";
import React from "react";
import UnsubscribeScreen from "pages/Unsubscriber/Unsubscribe";

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = configureStore({ reducer: reducers });
export default store;

const App = () => {
  const token = localStorage.getItem("p")
    ? JSON.parse(localStorage.getItem("p"))?.token
    : null;
  const navigate = useNavigate();
  React.useEffect(() => {
    const publicPaths = [
      "/sub-user/registration",
      "/user-reset-password",
      "/user-verify-email",
      "/auth/register",
      "/user/unsubscribe",
    ];

    // Check if the current path contains one of the public paths
    if (
      !token &&
      !publicPaths.some((path) => window.location.pathname.includes(path))
    ) {
      navigate("/auth/login");
    }
  }, [token, navigate]);

  return (
    <Routes>
      <Route path="/a/*" element={<AdminLayout />} />
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path="/sub-user/registration/*" element={<Register />} />
      <Route path="/user-reset-password/*" element={<ResetPassword />} />
      <Route path="/user-verify-email/*" element={<UserVerifyEmail />} />
      <Route path="/user/unsubscribe/*" element={<UnsubscribeScreen />} />
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
};

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
