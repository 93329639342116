/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "pages/Auth/Login";
// import Register from "pages/Auth/Register";
import RegistrationDetails from "pages/Auth/RegistrationDetails";
import CreateCampaignStep1 from "pages/Campaign/CreateCampaign/CreateCampaignStep1";
import CreateCampaignStep2 from "pages/Campaign/CreateCampaign/CreateCampaignStep2";
import ListCampaign from "pages/Campaign/ListCampaign";
import Dashboard from "pages/Dashboards/Dashboard";
import EmailConfiguration from "pages/Settings/EmailConfig/EmailConfiguration";
import UnsubscribeList from "pages/Unsubscriber/UnsubscribeList";
import Organization from "pages/Settings/Organization";
import AddSubUser from "pages/Settings/AddSubUser";
import Profile from "pages/Settings/Profile";
import EditEmailConfig from "pages/Settings/EmailConfig/EditEmailConfig";
import Plans from "pages/Billing/Plans";
import PaymentHistory from "pages/Billing/PlanHistory";
import CampaignDetails from "pages/Campaign/CampaignDetails/CampaignDetails";

const routes = [
  {
    show: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    component: <Dashboard />,
    layout: "/a",
    roles: ["TENANT_ADMIN", "TENANT_USER"],
  },
  {
    show: false,
    path: "/login/*",
    name: "Login",
    icon: "ni ni-ungroup text-orange",
    component: <Login />,
    layout: "/auth",
    roles: ["TENANT_ADMIN", "TENANT_USER"],
  },
  // {
  //   show: false,
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-ungroup text-orange",
  //   component: <Register />,
  //   layout: "/auth",
  // },
  // {
  //   show: false,
  //   path: "/register",
  //   name: "Registration Details",
  //   icon: "ni ni-ungroup text-orange",
  //   component: <RegistrationDetails />,
  //   layout: "/auth",
  //   roles: ["TENANT_ADMIN", "TENANT_USER"],
  // },
  // {
  //   show: false,
  //   path: "/reset/password",
  //   name: "Registration Details",
  //   icon: "ni ni-ungroup text-orange",
  //   component: <ResetPassword />,
  //   layout: "/auth",
  // },
  {
    show: true,
    collapse: false,
    path: "/campaigns",
    name: "Campaigns",
    icon: "fa-solid fa-envelopes-bulk text-orange",
    miniName: "C",
    component: <ListCampaign />,
    layout: "/a",
    roles: ["TENANT_ADMIN", "TENANT_USER"],
  },
  {
    show: false,
    collapse: true,
    name: "Campaigns",
    icon: "fa-solid fa-envelopes-bulk text-orange",
    state: "campaignCollapse",
    roles: ["TENANT_ADMIN", "TENANT_USER"],
    views: [
      {
        show: true,
        path: "/campaigns/step-1",
        name: "Create Campaign",
        miniName: "C",
        component: <CreateCampaignStep1 />,
        layout: "/a",
        roles: ["TENANT_ADMIN", "TENANT_USER"],
      },
      {
        show: false,
        path: "/campaigns/step-2",
        component: <CreateCampaignStep2 />,
        layout: "/a",
        roles: ["TENANT_ADMIN", "TENANT_USER"],
      },
      // {
      //   show: false,
      //   path: "/campaign/step-3",
      //   component: <CreateCampaignStep2 />,
      //   layout: "/a",
      //   roles: ["TENANT_ADMIN", "TENANT_USER"],
      // },
      // {
      //   show: true,
      //   path: "/campaigns",
      //   name: "Campaign List",
      //   miniName: "LI",
      //   component: <ListCampaign />,
      //   layout: "/a",
      //   roles: ["TENANT_ADMIN", "TENANT_USER"],
      // },
      {
        show: false,
        path: "/campaigns/details",
        component: <CampaignDetails />,
        layout: "/a",
        roles: ["TENANT_ADMIN", "TENANT_USER"],
      },
      // {
      //   show: true,
      //   path: "/invite/list",
      //   name: "Invite List",
      //   miniName: "IL",
      //   component: <InviteLog />,
      //   layout: "/admin",
      //   roles: ["TENANT_ADMIN" , "TENANT_USER"],
      // },
    ],
  },
  // {
  //   show: true,
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "ni ni-calendar-grid-58 text-primary",
  //   component: <CalendarView />,
  //   layout: "/admin",
  //   roles: ["TENANT_ADMIN" , "TENANT_USER"],
  // },
  {
    show: true,
    collapse: true,
    name: "Billing",
    icon: "fa fa-file-invoice-dollar text-red",
    state: "billingCollapse",
    role: "TenantAdmin",
    roles: ["TENANT_ADMIN"],
    views: [
      {
        show: true,
        path: "/plans",
        name: "Plans",
        miniName: "PS",
        component: <Plans />,
        layout: "/a",
        roles: ["TENANT_ADMIN"],
      },
      {
        show: true,
        path: "/payments",
        name: "Payment History",
        miniName: "PH",
        component: <PaymentHistory />,
        layout: "/a",
        roles: ["TENANT_ADMIN"],
      },
    ],
  },
  {
    collapse: true,
    show: true,
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-gray",
    state: "settingCollapse",
    roles: ["TENANT_ADMIN"],
    views: [
      {
        path: "/configurations",
        show: true,
        name: "Email Configuration",
        miniName: "EM",
        component: <EmailConfiguration />,
        layout: "/a",
        roles: ["TENANT_ADMIN"],
      },
      {
        path: "/email/edit",
        show: false,
        component: <EditEmailConfig />,
        layout: "/a",
        roles: ["TENANT_ADMIN", "TENANT_USER"],
      },
      // {
      //   path: "/sender/email",
      //   show: true,
      //   name: "Sender Email",
      //   miniName: "SE",
      //   component: <EmailConfiguration />,
      //   layout: "/admin",
      // },
      {
        path: "/sub-users",
        show: true,
        name: "Sub Users",
        miniName: "ASU",
        roles: ["TENANT_ADMIN", "TENANT_USER"],
        component: <AddSubUser />,
        layout: "/a",
      },
      {
        path: "/organization",
        show: true,
        name: "Organization",
        miniName: "O",
        roles: ["TENANT_ADMIN", "TENANT_USER"],
        component: <Organization />,
        layout: "/a",
      },
      // {
      //   path: "/download/request",
      //   show: true,
      //   name: "Download Request",
      //   miniName: "EM",
      //   component: <RequestList />,
      //   layout: "/admin",
      // },
      {
        path: "/user/profile",
        show: false,
        name: "User Profile",
        roles: ["TENANT_ADMIN", "TENANT_USER"],
        miniName: "UP",
        component: <Profile />,
        layout: "/a",
      },
    ],
  },
  {
    show: true,
    path: "/unsubscribers",
    name: "Unsubscribers",
    icon: "ni ni-bullet-list-67 text-primary",
    component: <UnsubscribeList />,
    layout: "/a",
    roles: ["TENANT_ADMIN", "TENANT_USER"],
  },
];

export default routes;
