/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useState } from "react";
// react library for routing
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

function AdminNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location;
  const [login, setLogin] = useState(searchParams.pathname === "/auth/login");

  React.useEffect(() => {
    // Gets the search params (e.g., "?error=xyz")
    if (login) {
      if (searchParams.search) {
        return;
      } else {
        navigate("/auth/login");
      }
      navigate("/auth/login");
    } else {
      navigate("/auth/register");
    }
  }, [login]);
  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark navbar-transparent"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand>
            <img
              alt="..."
              className="navbar-brand-img"
              src={require("assets/img/brand/logo.png")}
              style={{ width: "150px", height: "auto" }}
            />
            {/* <img
              alt="..."
              src={require("assets/img/brand/argon-react-white.png")}
            /> */}
          </NavbarBrand>
          <Nav className="align-items-lg-center ml-lg-auto" navbar>
            {/* <NavItem>
                <span
                  className="nav-link-inner text-white"
                  style={styles.login}
                  onClick={() => setLogin(!login)}
                >
                  {login ? "Register" : "Login"}
                </span>
              </NavItem> */}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
const styles = {
  login: {
    cursor: "pointer",
  },
};
