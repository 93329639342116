import { getEmailConfig } from "action/Settings/emailConfiguration";
import { cyanBlue } from "components/Common/constants";
import { formatDateWithTime } from "components/CommonFunctions";
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, Label, Col } from "reactstrap";

const EmailConfigDetails = ({
  showDetailsModal,
  toggleDetailsModal,
  emailConfigID,
}) => {
  const [emailConfigDetails, setEmailConfigDetails] = useState({
    ID: 0,
    mailingService: {
      ID: 0,
      name: "",
    },
    userResponse: {
      ID: 0,
      email: "",
      firstName: "",
      lastName: "",
    },
    title: "",
    integrationType: "",
    smtpValueJson: {
      smtpServerURL: "",
      encryptionType: "",
      port: 0,
      accessKey: "",
      secretKey: "",
      senderName: "",
      email: "",
      replyToEmail: "",
    },
    apiValueJson: {
      senderName: "",
      email: "",
      apiKey: "",
    },
    createdAt: "",
  });
  const [showLoaderOrError, setShowLoadeOrError] = useState({
    loader: true,
    error: "",
    errorCode: 0,
  });

  useEffect(() => {
    if (emailConfigID && emailConfigID !== 0) {
      if (!showLoaderOrError.loader) {
        setShowLoadeOrError({
          loader: true,
          error: "",
          errorCode: 0,
        });
      }
      getEmailConfigDetails(emailConfigID);
    }
  }, [emailConfigID]);

  const getEmailConfigDetails = async (ID) => {
    const res = await getEmailConfig(ID);
    if (res.code === 200) {
      setEmailConfigDetails(res.data);
      setShowLoadeOrError({ loader: false, error: "" });
    } else {
      setShowLoadeOrError({
        loader: false,
        error: res.message ? res.message : "Something went wrong",
        errorCode: res.code ? res.code : 0,
      });
    }
  };

  return (
    <Modal
      isOpen={showDetailsModal}
      toggle={toggleDetailsModal}
      scrollable={true}
      size="lg"
      // style={{ maxWidth: "50%" }}
      // centered
    >
      {showLoaderOrError.loader ? (
        <>
          <ModalHeader toggle={toggleDetailsModal}>
            Email Configuration Details
          </ModalHeader>
          <ModalBody style={styles.loaderContainer}>
            <i
              className="fa fa-spinner fa-spin fa-2xl"
              style={styles.loaderColor}
            />
          </ModalBody>
        </>
      ) : showLoaderOrError.error ? (
        <>
          <ModalHeader toggle={toggleDetailsModal}>
            Email Configuration Details
          </ModalHeader>
          <ModalBody style={styles.loaderContainer}>
            <i
              className="fa-solid fa-circle-exclamation fa-xl"
              style={styles.errorIcon}
            />
            {showLoaderOrError.errorCode !== 0 && (
              <span className="h1 font-weight-bold mb-0 mt-2">
                {showLoaderOrError.errorCode}
              </span>
            )}
            <p className="mt-0">{showLoaderOrError.error}</p>
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader toggle={toggleDetailsModal}>
            Email Configuration Details
          </ModalHeader>
          <ModalBody className="pt-0">
            <div style={{ padding: "0.6rem" }}>
              <div className="row mb-2" style={styles.rowLabelValue}>
                <Label md="3" style={styles.label}>
                  Created On:
                </Label>
                <Col md="9" className="p-0 text-gray">
                  <div style={styles.value}>
                    {formatDateWithTime(emailConfigDetails.createdAt)}
                  </div>
                </Col>
              </div>
              <div className="row mb-2" style={styles.rowLabelValue}>
                <Label md="3" style={styles.label}>
                  Created By:
                </Label>
                <Col md="9" className="p-0 text-gray">
                  <div style={styles.value}>
                    {`${emailConfigDetails.userResponse.firstName} ${emailConfigDetails.userResponse.lastName}`}
                  </div>
                </Col>
              </div>
              <hr className="my-3" />
              <div className="row mb-2" style={styles.rowLabelValue}>
                <Label md="3" style={styles.label}>
                  Title:
                </Label>
                <Col md="9" className="p-0 text-gray">
                  <div style={styles.value}>{emailConfigDetails.title}</div>
                </Col>
              </div>
              <div className="row mb-2" style={styles.rowLabelValue}>
                <Label md="3" style={styles.label}>
                  Emailing Service:
                </Label>
                <Col md="9" className="p-0 text-gray">
                  <div style={styles.value}>
                    {emailConfigDetails.mailingService.name}
                  </div>
                </Col>
              </div>
              {/* {(emailConfigDetails.mailingService.name === "SendGrid" ||
              emailConfigDetails.mailingService.name === "SendInBlue") && ( */}
              <div className="row mb-2" style={styles.rowLabelValue}>
                <Label md="3" style={styles.label}>
                  Integration Method:
                </Label>
                <Col md="9" className="p-0 text-gray">
                  <div style={styles.value}>
                    {emailConfigDetails.integrationType}
                  </div>
                </Col>
              </div>
              {/* )} */}
              {emailConfigDetails.mailingService.name === "SendGrid" ||
              emailConfigDetails.mailingService.name === "Custom" ||
              emailConfigDetails.mailingService.name === "SendInBlue" ? (
                emailConfigDetails.integrationType === "SMTP" ? (
                  <>
                    <div className="row mb-2" style={styles.rowLabelValue}>
                      <Label md="3" style={styles.label}>
                        SMTP URL:
                      </Label>
                      <Col md="9" className="p-0 text-gray">
                        <div style={styles.value}>
                          {emailConfigDetails.smtpValueJson.smtpServerURL}
                        </div>
                      </Col>
                    </div>
                    <div className="row mb-2" style={styles.rowLabelValue}>
                      <Label md="3" style={styles.label}>
                        Encryption Type:
                      </Label>
                      <Col md="9" className="p-0 text-gray">
                        <div style={styles.value}>
                          {emailConfigDetails.smtpValueJson.encryptionType}
                        </div>
                      </Col>
                    </div>
                    <div className="row mb-2" style={styles.rowLabelValue}>
                      <Label md="3" style={styles.label}>
                        Port:
                      </Label>
                      <Col md="9" className="p-0 text-gray">
                        <div style={styles.value}>
                          {emailConfigDetails.smtpValueJson.port}
                        </div>
                      </Col>
                    </div>
                    <div className="row mb-2" style={styles.rowLabelValue}>
                      <Label md="3" style={styles.label}>
                        Access Key / Username:
                      </Label>
                      <Col md="9" className="p-0 text-gray">
                        <div style={styles.value}>
                          {emailConfigDetails.smtpValueJson.accessKey}
                        </div>
                      </Col>
                    </div>
                    <div className="row mb-2" style={styles.rowLabelValue}>
                      <Label md="3" style={styles.label}>
                        Secret Key / Password:
                      </Label>
                      <Col md="9" className="p-0 text-gray">
                        <div style={styles.value}>
                          {emailConfigDetails.smtpValueJson.secretKey}
                        </div>
                      </Col>
                    </div>
                  </>
                ) : emailConfigDetails.integrationType === "API" ? (
                  <div className="row mb-2" style={styles.rowLabelValue}>
                    <Label md="3" style={styles.label}>
                      API key:
                    </Label>
                    <Col md="9" className="p-0 text-gray">
                      <div style={styles.value}>
                        {emailConfigDetails.apiValueJson.apiKey}
                      </div>
                    </Col>
                  </div>
                ) : null
              ) : null}
              <hr className="my-3" />
              {emailConfigDetails.mailingService.name !== "" &&
                emailConfigDetails.integrationType !== "" && (
                  <>
                    {emailConfigDetails[
                      emailConfigDetails.integrationType === "SMTP"
                        ? "smtpValueJson"
                        : "apiValueJson"
                    ].senderName && (
                      <div className="row mb-2" style={styles.rowLabelValue}>
                        <Label md="3" style={styles.label}>
                          Sender Name:
                        </Label>
                        <Col md="9" className="p-0 text-gray">
                          <div style={styles.value}>
                            {
                              emailConfigDetails[
                                emailConfigDetails.integrationType === "SMTP"
                                  ? "smtpValueJson"
                                  : "apiValueJson"
                              ].senderName
                            }
                          </div>
                        </Col>
                      </div>
                    )}
                    {emailConfigDetails[
                      emailConfigDetails.integrationType === "SMTP"
                        ? "smtpValueJson"
                        : "apiValueJson"
                    ].email && (
                      <div className="row mb-2" style={styles.rowLabelValue}>
                        <Label md="3" style={styles.label}>
                          Email:
                        </Label>
                        <Col md="9" className="p-0 text-gray">
                          <div style={styles.value}>
                            {
                              emailConfigDetails[
                                emailConfigDetails.integrationType === "SMTP"
                                  ? "smtpValueJson"
                                  : "apiValueJson"
                              ].email
                            }
                          </div>
                        </Col>
                      </div>
                    )}
                    {emailConfigDetails[
                      emailConfigDetails.integrationType === "SMTP"
                        ? "smtpValueJson"
                        : "apiValueJson"
                    ].replyToEmail && (
                      <div className="row mb-2" style={styles.rowLabelValue}>
                        <Label md="3" style={styles.label}>
                          Reply-to Email:
                        </Label>
                        <Col md="9" className="p-0 text-gray">
                          <div style={styles.value}>
                            {
                              emailConfigDetails[
                                emailConfigDetails.integrationType === "SMTP"
                                  ? "smtpValueJson"
                                  : "apiValueJson"
                              ].replyToEmail
                            }
                          </div>
                        </Col>
                      </div>
                    )}
                  </>
                )}
            </div>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export default EmailConfigDetails;

const styles = {
  rowLabelValue: { display: "flex", alignItems: "flex-start" },
  label: {
    // wordWrap: "break-word",
    // border: "1px solid #dee2e6",
    // borderRadius: "0.4rem",
    padding: "0.2rem",
  },
  value: {
    wordWrap: "break-word",
    // border: "1px solid #dee2e6",
    borderRadius: "0.4rem",
    padding: "0.2rem",
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "60vh",
  },
  loaderColor: { color: cyanBlue },
  errorIcon: { fontSize: "4rem", marginBottom: "1.5rem" },
};
