/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { formatDateWithTime } from "components/CommonFunctions";

// ##############################
// // // data for populating the calendar in Calendar view
// #############################

var today = new Date();
var y = today.getFullYear();
var m = today.getMonth();
var d = today.getDate();

export const mapData = [
  {
    label: "Attendee Email",
    maxLength: 100,
    name: "email",
    errMsg: "Please enter a valid email address.",
  },
  {
    label: "First Name",
    maxLength: 50,
    errMsg: "First name should contain only alphabets and cannot be empty.",
    name: "firstName",
  },
  {
    label: "Last Name",
    maxLength: 50,
    errMsg: "Last name should contain only alphabets and cannot be empty.",
    name: "lastName",
  },
  {
    label: "Field1",
    maxLength: 255,
    name: "field1",
    errMsg: "Field1 cannot be empty. Please enter a valid value.",
  },
  {
    label: "Field2",
    maxLength: 255,
    name: "field2",
    errMsg: "Field2 cannot be empty. Please enter a valid value.",
  },
  {
    label: "Field3",
    maxLength: 255,
    name: "field3",
    errMsg: "Field3 cannot be empty. Please enter a valid value.",
  },
  {
    label: "Field4",
    maxLength: 255,
    name: "field4",
    errMsg: "Field4 cannot be empty. Please enter a valid value.",
  },
  {
    label: "Field5",
    maxLength: 255,
    name: "field5",
    errMsg: "Field5 cannot be empty. Please enter a valid value.",
  },
];

const events = [
  {
    id: 1,
    title: "Call with Dave",
    start: new Date(y, m, 1),
    allDay: true,
    className: "bg-red",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },

  {
    id: 2,
    title: "Lunch meeting",
    start: new Date(y, m, d - 1, 10, 30),
    allDay: true,
    className: "bg-orange",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },

  {
    id: 3,
    title: "All day conference",
    start: new Date(y, m, d + 7, 12, 0),
    allDay: true,
    className: "bg-green",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },

  {
    id: 4,
    title: "Meeting with Mary",
    start: new Date(y, m, d - 2),
    allDay: true,
    className: "bg-blue",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },

  {
    id: 5,
    title: "Winter Hackaton",
    start: new Date(y, m, d + 1, 19, 0),
    allDay: true,
    className: "bg-red",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },

  {
    id: 6,
    title: "Digital event",
    start: new Date(y, m, 21),
    allDay: true,
    className: "bg-warning",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },

  {
    id: 7,
    title: "Marketing event",
    start: new Date(y, m, 21),
    allDay: true,
    className: "bg-purple",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },

  {
    id: 8,
    title: "Dinner with Family",
    start: new Date(y, m, 19),
    allDay: true,
    className: "bg-red",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },

  {
    id: 9,
    title: "Black Friday",
    start: new Date(y, m, 23),
    allDay: true,
    className: "bg-blue",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },

  {
    id: 10,
    title: "Cyber Week",
    start: new Date(y, m, 2),
    allDay: true,
    className: "bg-yellow",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
  },
];

// ##############################
// // // data for populating the calendar in Widgest view
// #############################

const widgetEvents = [
  {
    title: "Lunch meeting",
    start: new Date().getFullYear() + "-11-21",
    end: new Date().getFullYear() + "-11-22",
    className: "bg-orange",
  },
  {
    title: "All day conference",
    start: new Date(y, m - 1, 28),
    allDay: true,
    className: "bg-green",
  },
  {
    title: "Meeting with Mary",
    start: new Date(y, m, 2),
    allDay: true,
    className: "bg-blue",
  },
  {
    title: "Winter Hackaton",
    start: new Date(y, m, 4),
    allDay: true,
    className: "bg-red",
  },
  {
    title: "Digital event",
    start: new Date(y, m, 8),
    end: new Date(y, m, 10),
    allDay: true,
    className: "bg-warning",
  },
  {
    title: "Marketing event",
    start: new Date(y, m, 11),
    allDay: true,
    className: "bg-purple",
  },
  {
    title: "Dinner with Family",
    start: new Date(y, m, 20),
    allDay: true,
    className: "bg-red",
  },
  {
    title: "Black Friday",
    start: new Date(y, m, 24),
    allDay: true,
    className: "bg-blue",
  },
  {
    title: "Cyber Week",
    start: new Date(y, m, 3),
    allDay: true,
    className: "bg-yellow",
  },
];

// ##############################
// // // data for populating the table in ReactTables view
// #############################

const dataTable = [
  {
    email: "example@gmail.com",
    name: "Tiger Nixon",
    position: "System Architect",
    office: "Edinburgh",
    campaignName: "Campaign_Name",
    start_date: "2011/04/25",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Garrett Winters",
    position: "Accountant",
    office: "Tokyo",
    campaignName: "Campaign_Name",
    start_date: "2011/07/25",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Ashton Cox",
    position: "Junior Technical Author",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2009/01/12",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Cedric Kelly",
    position: "Senior Javascript Developer",
    office: "Edinburgh",
    campaignName: "Campaign_Name",
    start_date: "2012/03/29",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Airi Satou",
    position: "Accountant",
    office: "Tokyo",
    campaignName: "Campaign_Name",
    start_date: "2008/11/28",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Brielle Williamson",
    position: "Integration Specialist",
    office: "New York",
    campaignName: "Campaign_Name",
    start_date: "2012/12/02",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Herrod Chandler",
    position: "Sales Assistant",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2012/08/06",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Rhona Davidson",
    position: "Integration Specialist",
    office: "Tokyo",
    campaignName: "Campaign_Name",
    start_date: "2010/10/14",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Colleen Hurst",
    position: "Javascript Developer",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2009/09/15",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Sonya Frost",
    position: "Software Engineer",
    office: "Edinburgh",
    campaignName: "Campaign_Name",
    start_date: "2008/12/13",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Jena Gaines",
    position: "Office Manager",
    office: "London",
    campaignName: "Campaign_Name",
    start_date: "2008/12/19",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Quinn Flynn",
    position: "Support Lead",
    office: "Edinburgh",
    campaignName: "Campaign_Name",
    start_date: "2013/03/03",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Charde Marshall",
    position: "Regional Director",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2008/10/16",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Haley Kennedy",
    position: "Senior Marketing Designer",
    office: "London",
    campaignName: "Campaign_Name",
    start_date: "2012/12/18",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Tatyana Fitzpatrick",
    position: "Regional Director",
    office: "London",
    campaignName: "Campaign_Name",
    start_date: "2010/03/17",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Michael Silva",
    position: "Marketing Designer",
    office: "London",
    campaignName: "Campaign_Name",
    start_date: "2012/11/27",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Paul Byrd",
    position: "Chief Financial Officer (CFO)",
    office: "New York",
    campaignName: "Campaign_Name",
    start_date: "2010/06/09",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Gloria Little",
    position: "Systems Administrator",
    office: "New York",
    campaignName: "Campaign_Name",
    start_date: "2009/04/10",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Bradley Greer",
    position: "Software Engineer",
    office: "London",
    campaignName: "Campaign_Name",
    start_date: "2012/10/13",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Dai Rios",
    position: "Personnel Lead",
    office: "Edinburgh",
    campaignName: "Campaign_Name",
    start_date: "2012/09/26",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Jenette Caldwell",
    position: "Development Lead",
    office: "New York",
    campaignName: "Campaign_Name",
    start_date: "2011/09/03",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Yuri Berry",
    position: "Chief Marketing Officer (CMO)",
    office: "New York",
    campaignName: "Campaign_Name",
    start_date: "2009/06/25",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Caesar Vance",
    position: "Pre-Sales Support",
    office: "New York",
    campaignName: "Campaign_Name",
    start_date: "2011/12/12",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Doris Wilder",
    position: "Sales Assistant",
    office: "Sidney",
    campaignName: "Campaign_Name",
    start_date: "2010/09/20",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Angelica Ramos",
    position: "Chief Executive Officer (CEO)",
    office: "London",
    campaignName: "Campaign_Name",
    start_date: "2009/10/09",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Gavin Joyce",
    position: "Developer",
    office: "Edinburgh",
    campaignName: "Campaign_Name",
    start_date: "2010/12/22",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Jennifer Chang",
    position: "Regional Director",
    office: "Singapore",
    campaignName: "Campaign_Name",
    start_date: "2010/11/14",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Brenden Wagner",
    position: "Software Engineer",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2011/06/07",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Fiona Green",
    position: "Chief Operating Officer (COO)",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2010/03/11",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Shou Itou",
    position: "Regional Marketing",
    office: "Tokyo",
    campaignName: "Campaign_Name",
    start_date: "2011/08/14",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Michelle House",
    position: "Integration Specialist",
    office: "Sidney",
    campaignName: "Campaign_Name",
    start_date: "2011/06/02",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Suki Burks",
    position: "Developer",
    office: "London",
    campaignName: "Campaign_Name",
    start_date: "2009/10/22",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Prescott Bartlett",
    position: "Technical Author",
    office: "London",
    campaignName: "Campaign_Name",
    start_date: "2011/05/07",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Gavin Cortez",
    position: "Team Leader",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2008/10/26",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Martena Mccray",
    position: "Post-Sales support",
    office: "Edinburgh",
    campaignName: "Campaign_Name",
    start_date: "2011/03/09",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Unity Butler",
    position: "Marketing Designer",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2009/12/09",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Howard Hatfield",
    position: "Office Manager",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2008/12/16",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Hope Fuentes",
    position: "Secretary",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2010/02/12",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Vivian Harrell",
    position: "Financial Controller",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2009/02/14",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Timothy Mooney",
    position: "Office Manager",
    office: "London",
    campaignName: "Campaign_Name",
    start_date: "2008/12/11",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Jackson Bradshaw",
    position: "Director",
    office: "New York",
    campaignName: "Campaign_Name",
    start_date: "2008/09/26",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Olivia Liang",
    position: "Support Engineer",
    office: "Singapore",
    campaignName: "Campaign_Name",
    start_date: "2011/02/03",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Bruno Nash",
    position: "Software Engineer",
    office: "London",
    campaignName: "Campaign_Name",
    start_date: "2011/05/03",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Sakura Yamamoto",
    position: "Support Engineer",
    office: "Tokyo",
    campaignName: "Campaign_Name",
    start_date: "2009/08/19",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Thor Walton",
    position: "Developer",
    office: "New York",
    campaignName: "Campaign_Name",
    start_date: "2013/08/11",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Finn Camacho",
    position: "Support Engineer",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2009/07/07",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Serge Baldwin",
    position: "Data Coordinator",
    office: "Singapore",
    campaignName: "Campaign_Name",
    start_date: "2012/04/09",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Zenaida Frank",
    position: "Software Engineer",
    office: "New York",
    campaignName: "Campaign_Name",
    start_date: "2010/01/04",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Zorita Serrano",
    position: "Software Engineer",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2012/06/01",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Jennifer Acosta",
    position: "Junior Javascript Developer",
    office: "Edinburgh",
    campaignName: "Campaign_Name",
    start_date: "2013/02/01",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Cara Stevens",
    position: "Sales Assistant",
    office: "New York",
    campaignName: "Campaign_Name",
    start_date: "2011/12/06",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Hermione Butler",
    position: "Regional Director",
    office: "London",
    campaignName: "Campaign_Name",
    start_date: "2011/03/21",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Lael Greer",
    position: "Systems Administrator",
    office: "London",
    campaignName: "Campaign_Name",
    start_date: "2009/02/27",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Jonas Alexander",
    position: "Developer",
    office: "San Francisco",
    campaignName: "Campaign_Name",
    start_date: "2010/07/14",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Shad Decker",
    position: "Regional Director",
    office: "Edinburgh",
    campaignName: "Campaign_Name",
    start_date: "2008/11/13",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Michael Bruce",
    position: "Javascript Developer",
    office: "Singapore",
    campaignName: "Campaign_Name",
    start_date: "2011/06/27",
    reason: "No longer relevant",
  },
  {
    email: "example@gmail.com",
    name: "Donna Snider",
    position: "Customer Support",
    office: "New York",
    campaignName: "Campaign_Name",
    start_date: "2011/01/25",
    reason: "No longer relevant",
  },
];

const campaignTable = [
  {
    firstName: "Tiger",
    lastName: "Nixon",
    email: "tiger@dummy.com",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
  },
  {
    firstName: "Ashton",
    lastName: "Nixon",
    email: "tiger@dummy.com",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
  },
];

const data = [
  {
    srNo: 1,
    name: "John Doe",
    office: "Acme Corp",
    position: "12345",
    time: "10:30 AM",
    type: "Success",
    credit: 1,
    massage: "Welcome to the team!",
    ipAddress: "192.168.1.1",
    createdBy: "Admin",
    createdOn: "2024-08-01",
  },
  {
    srNo: 2,
    name: "Jane Smith",
    office: "Beta Inc",
    position: "67890",
    time: "02:15 PM",
    type: "Success",
    credit: 1,
    massage: "Thank you for your contribution.",
    ipAddress: "192.168.1.2",
    createdBy: "Admin",
    createdOn: "2024-08-02",
  },
  {
    srNo: 3,
    name: "Alice Johnson",
    office: "Gamma LLC",
    position: "11223",
    time: "08:45 AM",
    type: "Success",
    credit: 1,
    massage: "Please review the attached report.",
    ipAddress: "192.168.1.3",
    createdBy: "Admin",
    createdOn: "2024-08-03",
  },
  {
    srNo: 4,
    name: "Bob Brown",
    office: "Delta Ltd",
    position: "44556",
    time: "05:00 PM",
    type: "Success",
    credit: 1,
    massage: "Meeting scheduled for next week.",
    ipAddress: "192.168.1.4",
    createdBy: "Admin",
    createdOn: "2024-08-04",
  },
  {
    srNo: 5,
    name: "Emily Davis",
    office: "Epsilon Enterprises",
    position: "78901",
    time: "11:00 AM",
    type: "Success",
    credit: 1,
    massage: "Please confirm the new schedule.",
    ipAddress: "192.168.1.5",
    createdBy: "Admin",
    createdOn: "2024-08-05",
  },
  {
    srNo: 6,
    name: "Michael Green",
    office: "Zeta Solutions",
    position: "23456",
    time: "09:30 AM",
    type: "Success",
    credit: 1,
    massage: "Reminder: Complete your tasks.",
    ipAddress: "192.168.1.6",
    createdBy: "Admin",
    createdOn: "2024-08-06",
  },
  {
    srNo: 7,
    name: "Samantha Wilson",
    office: "Eta Tech",
    position: "34567",
    time: "03:00 PM",
    type: "Success",
    credit: 1,
    massage: "Final call for project updates.",
    ipAddress: "192.168.1.7",
    createdBy: "Admin",
    createdOn: "2024-08-07",
  },
  {
    srNo: 8,
    name: "Daniel Carter",
    office: "Theta Innovations",
    position: "45678",
    time: "12:30 PM",
    type: "Success",
    credit: 1,
    massage: "Document review is complete.",
    ipAddress: "192.168.1.8",
    createdBy: "Admin",
    createdOn: "2024-08-08",
  },
  {
    srNo: 7,
    name: "Samantha Wilson",
    office: "Eta Tech",
    position: "34567",
    time: "03:00 PM",
    type: "Success",
    credit: 1,
    massage: "Final call for project updates.",
    ipAddress: "192.168.1.7",
    createdBy: "Admin",
    createdOn: "2024-08-07",
  },
  {
    srNo: 8,
    name: "Daniel Carter",
    office: "Theta Innovations",
    position: "45678",
    time: "12:30 PM",
    type: "Success",
    credit: 1,
    massage: "Document review is complete.",
    ipAddress: "192.168.1.8",
    createdBy: "Admin",
    createdOn: "2024-08-08",
  },
  {
    srNo: 7,
    name: "Samantha Wilson",
    office: "Eta Tech",
    position: "34567",
    time: "03:00 PM",
    type: "Success",
    credit: 1,
    massage: "Final call for project updates.",
    ipAddress: "192.168.1.7",
    createdBy: "Admin",
    createdOn: "2024-08-07",
  },
  {
    srNo: 8,
    name: "Daniel Carter",
    office: "Theta Innovations",
    position: "45678",
    time: "12:30 PM",
    type: "Success",
    credit: 1,
    massage: "Document review is complete.",
    ipAddress: "192.168.1.8",
    createdBy: "Admin",
    createdOn: "2024-08-08",
  },
  {
    srNo: 7,
    name: "Samantha Wilson",
    office: "Eta Tech",
    position: "34567",
    time: "03:00 PM",
    type: "Success",
    credit: 1,
    massage: "Final call for project updates.",
    ipAddress: "192.168.1.7",
    createdBy: "Admin",
    createdOn: "2024-08-07",
  },
  {
    srNo: 8,
    name: "Daniel Carter",
    office: "Theta Innovations",
    position: "45678",
    time: "12:30 PM",
    type: "Success",
    credit: 1,
    massage: "Document review is complete.",
    ipAddress: "192.168.1.8",
    createdBy: "Admin",
    createdOn: "2024-08-08",
  },
];

export default data;

const timezones = [
  { ID: "Pacific/Midway", name: "(GMT-11:00) Midway Island, Samoa" },
  { ID: "America/Adak", name: "(GMT-10:00) Hawaii-Aleutian" },
  { ID: "Etc/GMT+10", name: "(GMT-10:00) Hawaii" },
  { ID: "Pacific/Marquesas", name: "(GMT-09:30) Marquesas Islands" },
  { ID: "Pacific/Gambier", name: "(GMT-09:00) Gambier Islands" },
  { ID: "America/Anchorage", name: "(GMT-09:00) Alaska" },
  { ID: "America/Los_Angeles", name: "(GMT-08:00) Pacific Time (US & Canada)" },
  { ID: "America/Tijuana", name: "(GMT-08:00) Tijuana, Baja California" },
  { ID: "America/Denver", name: "(GMT-07:00) Mountain Time (US & Canada)" },
  { ID: "America/Chihuahua", name: "(GMT-07:00) Chihuahua, La Paz, Mazatlan" },
  { ID: "America/Phoenix", name: "(GMT-07:00) Arizona" },
  { ID: "America/Chicago", name: "(GMT-06:00) Central Time (US & Canada)" },
  { ID: "America/Regina", name: "(GMT-06:00) Saskatchewan" },
  { ID: "America/Mexico_City", name: "(GMT-06:00) Mexico City" },
  { ID: "America/Belize", name: "(GMT-06:00) Central America" },
  { ID: "America/New_York", name: "(GMT-05:00) Eastern Time (US & Canada)" },
  { ID: "America/Havana", name: "(GMT-05:00) Cuba" },
  { ID: "America/Bogota", name: "(GMT-05:00) Bogota, Lima, Quito" },
  { ID: "America/Caracas", name: "(GMT-04:30) Caracas" },
  { ID: "America/Santiago", name: "(GMT-04:00) Santiago" },
  { ID: "America/La_Paz", name: "(GMT-04:00) La Paz" },
  { ID: "Atlantic/Stanley", name: "(GMT-04:00) Falkland Islands" },
  { ID: "America/Campo_Grande", name: "(GMT-04:00) Brazil" },
  { ID: "America/Goose_Bay", name: "(GMT-04:00) Atlantic Time (Goose Bay)" },
  { ID: "America/Glace_Bay", name: "(GMT-04:00) Atlantic Time (Canada)" },
  { ID: "America/St_Johns", name: "(GMT-03:30) Newfoundland" },
  { ID: "America/Argentina/Buenos_Aires", name: "(GMT-03:00) Buenos Aires" },
  { ID: "America/Sao_Paulo", name: "(GMT-03:00) Brasilia" },
  { ID: "America/Godthab", name: "(GMT-03:00) Greenland" },
  { ID: "America/Montevideo", name: "(GMT-03:00) Montevideo" },
  { ID: "Atlantic/South_Georgia", name: "(GMT-02:00) South Georgia" },
  { ID: "Atlantic/Azores", name: "(GMT-01:00) Azores" },
  { ID: "Atlantic/Cape_Verde", name: "(GMT-01:00) Cape Verde Is." },
  {
    ID: "Europe/Dublin",
    name: "(GMT+00:00) Dublin, Edinburgh, Lisbon, London",
  },
  { ID: "Europe/London", name: "(GMT+00:00) London" },
  { ID: "Africa/Abidjan", name: "(GMT+00:00) Monrovia, Reykjavik" },
  {
    ID: "Europe/Amsterdam",
    name: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  },
  {
    ID: "Europe/Belgrade",
    name: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  },
  {
    ID: "Europe/Brussels",
    name: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
  },
  { ID: "Africa/Algiers", name: "(GMT+01:00) West Central Africa" },
  { ID: "Africa/Windhoek", name: "(GMT+01:00) Windhoek" },
  { ID: "Asia/Beirut", name: "(GMT+02:00) Beirut" },
  { ID: "Africa/Cairo", name: "(GMT+02:00) Cairo" },
  { ID: "Asia/Gaza", name: "(GMT+02:00) Gaza" },
  { ID: "Africa/Blantyre", name: "(GMT+02:00) Harare, Pretoria" },
  { ID: "Asia/Jerusalem", name: "(GMT+02:00) Jerusalem" },
  { ID: "Europe/Minsk", name: "(GMT+03:00) Minsk" },
  { ID: "Asia/Baghdad", name: "(GMT+03:00) Baghdad" },
  {
    ID: "Europe/Moscow",
    name: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
  },
  { ID: "Africa/Nairobi", name: "(GMT+03:00) Nairobi" },
  { ID: "Asia/Tehran", name: "(GMT+03:30) Tehran" },
  { ID: "Asia/Dubai", name: "(GMT+04:00) Abu Dhabi, Muscat" },
  { ID: "Asia/Yerevan", name: "(GMT+04:00) Yerevan" },
  { ID: "Asia/Kabul", name: "(GMT+04:30) Kabul" },
  { ID: "Asia/Yekaterinburg", name: "(GMT+05:00) Ekaterinburg" },
  { ID: "Asia/Karachi", name: "(GMT+05:00) Karachi, Tashkent" },
  {
    ID: "Asia/Kolkata",
    name: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
  },
  { ID: "Asia/Kathmandu", name: "(GMT+05:45) Kathmandu" },
  { ID: "Asia/Dhaka", name: "(GMT+06:00) Astana, Dhaka" },
  { ID: "Asia/Novosibirsk", name: "(GMT+06:00) Novosibirsk" },
  { ID: "Asia/Rangoon", name: "(GMT+06:30) Yangon (Rangoon)" },
  { ID: "Asia/Bangkok", name: "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
  { ID: "Asia/Krasnoyarsk", name: "(GMT+07:00) Krasnoyarsk" },
  {
    ID: "Asia/Shanghai",
    name: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
  },
  { ID: "Asia/Irkutsk", name: "(GMT+08:00) Irkutsk, Ulaan Bataar" },
  { ID: "Australia/Perth", name: "(GMT+08:00) Perth" },
  { ID: "Asia/Tokyo", name: "(GMT+09:00) Osaka, Sapporo, Tokyo" },
  { ID: "Asia/Seoul", name: "(GMT+09:00) Seoul" },
  { ID: "Australia/Adelaide", name: "(GMT+09:30) Adelaide" },
  { ID: "Australia/Darwin", name: "(GMT+09:30) Darwin" },
  { ID: "Australia/Brisbane", name: "(GMT+10:00) Brisbane" },
  { ID: "Australia/Sydney", name: "(GMT+10:00) Canberra, Melbourne, Sydney" },
  { ID: "Pacific/Guam", name: "(GMT+10:00) Guam, Port Moresby" },
  { ID: "Asia/Vladivostok", name: "(GMT+10:00) Vladivostok" },
  { ID: "Pacific/Fiji", name: "(GMT+12:00) Fiji, Kamchatka, Marshall Is." },
  { ID: "Pacific/Auckland", name: "(GMT+12:00) Auckland, Wellington" },
  { ID: "Pacific/Tongatapu", name: "(GMT+13:00) Nuku'alofa" },
];

const campaignList = [
  {
    srNo: 1,
    createDate: "2024-08-08 13:00",
    campaignName: "Animal Welfare",
    timeZone: "(GMT+05:45) Kathmandu",
    startDate: "2024-08-30 13:00",
    endDate: "2024-08-30 15:00",
    status: "Pending",
  },
  {
    srNo: 2,
    createDate: "2024-08-05 13:00",
    campaignName: "GO Summit",
    timeZone: "(GMT+05:30) IST",
    startDate: "2024-08-25 13:00",
    endDate: "2024-08-25 15:00",
    status: "In Progress",
  },
  {
    srNo: 3,
    createDate: "2024-07-20 10:30",
    campaignName: "Clean Energy Initiative",
    timeZone: "(GMT+08:00) Singapore",
    startDate: "2024-09-01 09:00",
    endDate: "2024-09-01 17:00",
    status: "Pending",
  },
  {
    srNo: 4,
    createDate: "2024-07-15 14:45",
    campaignName: "Healthcare Awareness",
    timeZone: "(GMT+03:00) Moscow",
    startDate: "2024-09-15 10:00",
    endDate: "2024-09-15 18:00",
    status: "Completed",
  },
  {
    srNo: 5,
    createDate: "2024-08-10 16:00",
    campaignName: "Education for All",
    timeZone: "(GMT+00:00) UTC",
    startDate: "2024-08-20 11:00",
    endDate: "2024-08-20 14:00",
    status: "Completed",
  },
  {
    srNo: 6,
    createDate: "2024-06-18 09:00",
    campaignName: "Sustainable Cities",
    timeZone: "(GMT-03:00) Buenos Aires",
    startDate: "2024-07-10 09:30",
    endDate: "2024-07-10 17:30",
    status: "Completed",
  },
  {
    srNo: 7,
    createDate: "2024-08-12 12:00",
    campaignName: "Youth Empowerment",
    timeZone: "(GMT+02:00) Cairo",
    startDate: "2024-08-28 10:00",
    endDate: "2024-08-28 16:00",
    status: "Pending",
  },
  {
    srNo: 8,
    createDate: "2024-07-01 15:30",
    campaignName: "Digital Literacy",
    timeZone: "(GMT+09:00) Tokyo",
    startDate: "2024-09-05 09:00",
    endDate: "2024-09-05 12:00",
    status: "Completed",
  },
  {
    srNo: 9,
    createDate: "2024-08-04 11:15",
    campaignName: "Climate Change Action",
    timeZone: "(GMT-05:00) Eastern Time",
    startDate: "2024-08-22 14:00",
    endDate: "2024-08-22 18:00",
    status: "In Progress",
  },
  {
    srNo: 10,
    createDate: "2024-08-18 13:00",
    campaignName: "Women in Tech",
    timeZone: "(GMT+10:00) Sydney",
    startDate: "2024-09-25 09:00",
    endDate: "2024-09-25 17:00",
    status: "Completed",
  },
  {
    srNo: 11,
    createDate: "2024-08-18 13:00",
    campaignName: "Women in Tech 2",
    timeZone: "(GMT+10:00) Sydney",
    startDate: "2024-09-25 09:00",
    endDate: "2024-09-25 17:00",
    status: "Pending",
  },
];

const eventTypeList = [
  { value: "ONLINE", label: "Online" },
  { value: "OFFLINE", label: "Offline" },
];

const calendarParameter = [
  // { label: "Email Subject", value: "{email-subject}" },
  { label: "Event Start Date", value: "{event-start-datetime}" },
  { label: "Event End Date", value: "{event-end-datetime}" },
];

const attendeeParameter = [
  { label: "Attendee Email", value: "{attendee-email}" },
  { label: "First Name", value: "{first-name}" },
  { label: "Last Name", value: "{last-name}" },
  { label: "Field1", value: "{field1}" },
  { label: "Field2", value: "{field2}" },
  { label: "Field3", value: "{field3}" },
  { label: "Field4", value: "{field4}" },
  { label: "Field5", value: "{field5}" },
];

const labelToKeyMap = {
  "First Name": "firstName",
  "Last Name": "lastName",
  "Attendee Email": "email",
  Field1: "field1",
  Field2: "field2",
  Field3: "field3",
  Field4: "field4",
  Field5: "field5",
};

const columnsList = [
  { label: "Field1", value: "field1" },
  { label: "Field2", value: "field2" },
  { label: "Field3", value: "field3" },
  { label: "Field4", value: "field4" },
  { label: "Field5", value: "field5" },
];

const statusList = [
  {
    label: "Unsend",
    value: "UNSEND",
  },
  {
    label: "Don't Send",
    value: "DONT_SEND",
  },
];

export {
  events,
  widgetEvents,
  dataTable,
  timezones,
  campaignList,
  eventTypeList,
  calendarParameter,
  attendeeParameter,
  columnsList,
  statusList,
  labelToKeyMap,
};
