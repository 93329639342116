import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useDispatch } from "react-redux";
import { cyanBlue } from "../../../components/Common/constants";
import NotificationAlert from "react-notification-alert";
import { getMailingServiceList } from "action/Settings/emailConfiguration";
import { useLocation } from "react-router-dom";
import { getEmailConfig } from "action/Settings/emailConfiguration";
import { updateEmailConfig } from "action/Settings/emailConfiguration";
import { formatDateWithTime } from "components/CommonFunctions";

function EditEmailConfig() {
  const [editableConfigData, setEditableConfigData] = useState({
    title: { value: "", isValid: true, errorMsg: "", isEdited: false },
    accessKey: { value: "", isValid: true, errorMsg: "", isEdited: false },
    secretKey: { value: "", isValid: true, errorMsg: "", isEdited: false },
    senderName: { value: "", isValid: true, errorMsg: "", isEdited: false },
    email: { value: "", isValid: true, errorMsg: "", isEdited: false },
    replyToEmail: { value: "", isValid: true, errorMsg: "", isEdited: false },
    apiKey: { value: "", isValid: true, errorMsg: "", isEdited: false },
  });
  const [copyOfConfigData, setCopyOfConfigData] = useState({});
  const [open, setOpen] = useState(false);
  const [testButtonDisabled, setTestButtonDisabled] = useState(true);
  const [showLoaderOnClick, setShowLoaderOnClick] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false);
  const [showLoaderOrError, setShowLoadeOrError] = useState({
    loader: true,
    error: "",
    errorCode: 0,
  });
  const dispatch = useDispatch();
  const notificationAlertRef = React.useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state.configID) {
      if (!showLoaderOrError.loader) {
        setShowLoadeOrError({
          loader: true,
          error: "",
          errorCode: 0,
        });
      }
      getEmailConfigDetails(location.state.configID);
    }
  }, [location.state.configID]);

  //to get the email config details
  const getEmailConfigDetails = async (ID) => {
    const res = await getEmailConfig(ID);
    if (res.code === 200) {
      const commonKeys = { isValid: true, errorMsg: "", isEdited: false };
      let editablefields;
      if (res.data.integrationType === "SMTP") {
        editablefields = {
          accessKey: {
            value: res.data.smtpValueJson.accessKey,
            ...commonKeys,
          },
          secretKey: {
            value: res.data.smtpValueJson.secretKey,
            ...commonKeys,
          },
          senderName: {
            value: res.data.smtpValueJson.senderName,
            ...commonKeys,
          },
          email: {
            value: res.data.smtpValueJson.email,
            ...commonKeys,
          },
          replyToEmail: {
            value: res.data.smtpValueJson.replyToEmail,
            ...commonKeys,
          },
        };
      } else if (res.data.integrationType === "API") {
        editablefields = {
          senderName: {
            value: res.data.apiValueJson.senderName,
            ...commonKeys,
          },
          email: {
            value: res.data.apiValueJson.email,
            ...commonKeys,
          },
          replyToEmail: {
            value: res.data.apiValueJson.replyToEmail,
            ...commonKeys,
          },
          apiKey: {
            value: res.data.apiValueJson.apiKey,
            ...commonKeys,
          },
        };
      }
      setEditableConfigData({
        title: { value: res.data.title, ...commonKeys },
        ...editablefields,
      });
      setCopyOfConfigData(res.data);
      setShowLoadeOrError({ loader: false, error: "" });
    } else {
      setShowLoadeOrError({
        loader: false,
        error: res.message ? res.message : "Something went wrong",
        errorCode: res.code ? res.code : 0,
      });
    }
  };

  const notify = (type, msgObj) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {msgObj.message}
          </span>
          <span data-notify="message">{msgObj.subMessage}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  //api call to get maling service list
  useEffect(() => {
    getMailingServiceList(dispatch);
  }, []);

  //to check if all fields of an object is filled and isValid
  const checkObjectValueValidAndNonEmpty = (obj) => {
    let isValid = false;
    for (const key in obj) {
      if (
        typeof obj[key].value === "string" &&
        obj[key].value.trim().length > 0 &&
        obj[key].isValid
      ) {
        isValid = true;
      } else if (
        typeof obj[key].value === "number" &&
        obj[key].value > 0 &&
        obj[key].isValid
      ) {
        isValid = true;
      } else {
        return false;
      }
    }
    return isValid;
  };

  //to check if all fields of an object is filled and isValid
  const checkIfAnyFieldEdited = (obj) => {
    for (const key in obj) {
      if (obj[key].isEdited) {
        return true;
      }
    }
    return false;
  };

  //to check if according to integration method all fields are filler or not
  useEffect(() => {
    if (
      checkObjectValueValidAndNonEmpty(editableConfigData) &&
      checkIfAnyFieldEdited(editableConfigData)
    ) {
      setTestButtonDisabled(false); // enable the button
    } else {
      setTestButtonDisabled(true); // disable the button
    }
  }, [editableConfigData]);

  //handle modal of intruction video
  const handleModal = () => {
    setOpen(true);
  };

  //on change of input fields setting the values
  const handleChange = (e, fromJSON) => {
    const { name, value } = e.target;
    let isFieldChanged;
    if (fromJSON === "SMTP") {
      isFieldChanged = copyOfConfigData.smtpValueJson[name] !== value;
    } else if (fromJSON === "API") {
      isFieldChanged = copyOfConfigData.smtpValueJson[name] !== value;
    } else {
      isFieldChanged = copyOfConfigData[name] !== value;
    }
    setEditableConfigData((prevState) => {
      return {
        ...prevState,
        [name]: {
          value: value,
          isValid: true,
          errorMsg: "",
          isEdited: isFieldChanged,
        },
      };
    });
  };

  //on blur setting error if the field is empty
  const handleBlur = (e, fromJSON) => {
    const { name, value } = e.target;
    if (value.trim().length === 0) {
      setEditableConfigData((prevState) => {
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            isValid: false,
            errorMsg: "This field is required.",
          },
        };
      });
    } else if (
      (name === "email" || name === "replyToEmail") &&
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
    ) {
      setEditableConfigData((prevState) => {
        return {
          ...prevState,
          [name]: {
            ...prevState.smtpValueJson[name],
            isValid: false,
            errorMsg: "Please enter a valid email",
          },
        };
      });
    }
  };

  //to get an object whith only those values which are edited
  const getEditedField = (obj) => {
    let editedFields = {};
    for (const key in obj) {
      if (obj[key].isEdited) {
        editedFields = { ...editedFields, [key]: obj[key].value };
      }
    }
    return editedFields;
  };

  //on click of test and save connect call to api of add email config
  const handleTestAndUpdateConnection = async () => {
    const configBodyData = getEditedField(editableConfigData);
    const res = await updateEmailConfig(copyOfConfigData.ID, configBodyData);
    if (res.code === 200) {
      notify("success", {
        message: "Email Configuration updated successfully!",
        subMessage: "",
      });
      getEmailConfigDetails(copyOfConfigData.ID);
    } else if (res.code === 400) {
      notify("error", {
        message: Array.isArray(res.message)
          ? res.message[0].Msg
          : res.message
          ? res.message
          : "Something went wrong",
        subMessage: "Please check the details you entered.",
      });
    } else {
      notify("error", {
        message: res.message ? res.message : "Something went wrong",
        subMessage: "Please check the details you entered.",
      });
    }
    setShowLoaderOnClick(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {/* <SimpleHeader name="Settings" parentName="Settings" /> */}
      <Container className="mt--6" fluid>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Edit Email Configuration</h3>
                <h3 style={styles.noteTextStyle}>
                  <span>Note:</span> The email configurations are securely
                  stored using 256-bit AES-GCM encryption.
                </h3>
              </CardHeader>
              <CardBody>
                {showLoaderOrError.loader ? (
                  <div style={styles.loaderContainer}>
                    <i
                      className="fa fa-spinner fa-spin fa-2xl"
                      style={{ color: cyanBlue }}
                    />
                  </div>
                ) : (
                  <Form>
                    <Row>
                      <Col md="9">
                        {/* <Row>
                          <Col>
                            <FormGroup style={styles.formField}>
                              <Label style={styles.label}>Created On:</Label>
                              <div style={styles.inputContainer}>
                                {formatDateWithTime(
                                  copyOfConfigData?.createdAt
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup style={styles.formField} md="6" sm="6">
                              <Label style={styles.label}>Created By:</Label>
                              <div style={styles.inputContainer}>
                                {`${copyOfConfigData?.userResponse?.firstName} ${copyOfConfigData?.userResponse?.lastName}`}{" "}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <hr className="mt-0" /> */}
                        <FormGroup style={styles.formField}>
                          <Label for="title" style={styles.label}>
                            Title (for internal reference)
                          </Label>
                          <div style={styles.inputContainer}>
                            <Input
                              type="text"
                              id="title"
                              value={editableConfigData.title.value}
                              name="title"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              className={
                                !editableConfigData.title.isValid
                                  ? "is-invalid"
                                  : ""
                              }
                              onBlur={(e) => handleBlur(e)}
                              style={styles.input}
                              placeholder="Enter title"
                              maxLength={60}
                              autoComplete="off"
                            />
                            <div style={styles.errorContainer}>
                              {!editableConfigData.title.isValid && (
                                <small className="text-danger">
                                  {editableConfigData.title.errorMsg}
                                </small>
                              )}
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup style={styles.formField}>
                          <Label style={styles.label}>Emailing Service:</Label>
                          <div style={styles.inputContainer}>
                            <Input
                              type="text"
                              value={copyOfConfigData?.mailingService?.name}
                              style={styles.input}
                              disabled={true}
                              autoComplete="off"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup style={styles.formField}>
                          <Label style={styles.label}>
                            Integration Method:
                          </Label>
                          <div style={styles.inputContainer}>
                            {copyOfConfigData?.integrationType}
                          </div>
                        </FormGroup>
                        {copyOfConfigData.mailingService.name === "SendGrid" ||
                        copyOfConfigData.mailingService.name === "Custom" ||
                        copyOfConfigData.mailingService.name ===
                          "SendInBlue" ? (
                          copyOfConfigData.integrationType === "SMTP" ? (
                            <>
                              <FormGroup style={styles.formField}>
                                <Label for="smtpServerURL" style={styles.label}>
                                  SMTP URL
                                </Label>
                                <div style={styles.inputContainer}>
                                  <Input
                                    type="text"
                                    value={
                                      copyOfConfigData.smtpValueJson
                                        .smtpServerURL
                                    }
                                    style={styles.input}
                                    disabled={true}
                                    autoComplete="off"
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup style={styles.formField}>
                                <Label
                                  for="encryptionType"
                                  style={styles.label}
                                >
                                  Encryption Type
                                </Label>
                                <div style={styles.inputContainer}>
                                  <Input
                                    type="text"
                                    value={
                                      copyOfConfigData.smtpValueJson
                                        .encryptionType
                                    }
                                    style={styles.input}
                                    disabled={true}
                                    autoComplete="off"
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup style={styles.formField}>
                                <Label for="port" style={styles.label}>
                                  Port
                                </Label>
                                <div style={styles.inputContainer}>
                                  <Input
                                    type="text"
                                    value={copyOfConfigData.smtpValueJson.port}
                                    style={styles.input}
                                    disabled={true}
                                    autoComplete="off"
                                  />
                                </div>
                              </FormGroup>
                              <FormGroup style={styles.formField}>
                                <Label for="accessKey" style={styles.label}>
                                  Access Key / Username
                                </Label>
                                <div style={styles.inputContainer}>
                                  <Input
                                    type="text"
                                    id="accessKey"
                                    className={
                                      !editableConfigData.accessKey.isValid
                                        ? "is-invalid"
                                        : ""
                                    }
                                    name="accessKey"
                                    value={editableConfigData.accessKey.value}
                                    onChange={(e) => {
                                      handleChange(e, "SMTP");
                                    }}
                                    onBlur={(e) => handleBlur(e, "SMTP")}
                                    style={styles.input}
                                    placeholder="Enter access key or username"
                                    maxLength={60}
                                    autoComplete="off"
                                  />
                                  <div style={styles.errorContainer}>
                                    {!editableConfigData.accessKey.isValid && (
                                      <small className="text-danger">
                                        {editableConfigData.accessKey.errorMsg}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </FormGroup>
                              <FormGroup style={styles.formField}>
                                <Label for="secretKey" style={styles.label}>
                                  Secret Key / Password
                                </Label>
                                <div style={styles.inputContainer}>
                                  <InputGroup>
                                    <Input
                                      type={showPassword ? "text" : "password"}
                                      className={
                                        !editableConfigData.secretKey.isValid
                                          ? "is-invalid"
                                          : ""
                                      }
                                      id="secretKey"
                                      name="secretKey"
                                      value={editableConfigData.secretKey.value}
                                      onChange={(e) => handleChange(e, "SMTP")}
                                      onBlur={(e) => handleBlur(e, "SMTP")}
                                      style={styles.input}
                                      placeholder="Enter secret key or password"
                                      maxLength={150}
                                      autoComplete="off"
                                    />
                                    {editableConfigData.secretKey.isEdited && (
                                      <InputGroupText
                                        style={{
                                          borderTopLeftRadius: 0,
                                          borderBottomLeftRadius: 0,
                                          border:
                                            !editableConfigData.secretKey
                                              .isValid &&
                                            "0.1rem solid #fb6340",
                                          borderLeft:
                                            !editableConfigData.secretKey
                                              .isValid && "none",
                                        }}
                                      >
                                        <InputGroupAddon addonType="append">
                                          <i
                                            onClick={togglePasswordVisibility}
                                            className={
                                              showPassword
                                                ? "fa fa-eye password-icon"
                                                : "fa fa-eye-slash password-icon"
                                            }
                                          />
                                        </InputGroupAddon>
                                      </InputGroupText>
                                    )}
                                  </InputGroup>
                                  <div style={styles.errorContainer}>
                                    {!editableConfigData.secretKey.isValid && (
                                      <small className="text-danger">
                                        {editableConfigData.secretKey.errorMsg}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          ) : copyOfConfigData.integrationType === "API" ? (
                            <FormGroup style={styles.formField}>
                              <Label for="apiKey" style={styles.label}>
                                API key
                              </Label>
                              <div style={styles.inputContainer}>
                                <InputGroup>
                                  <Input
                                    type={showApiKey ? "text" : "password"}
                                    className={
                                      !editableConfigData.apiKey.isValid
                                        ? "is-invalid"
                                        : ""
                                    }
                                    id="apiKey"
                                    name="apiKey"
                                    value={editableConfigData.apiKey.value}
                                    onChange={(e) => handleChange(e, "API")}
                                    onBlur={(e) => handleBlur(e, "API")}
                                    style={styles.input}
                                    placeholder="Enter API key"
                                    autoComplete="off"
                                  />
                                  {editableConfigData.apiKey.isEdited && (
                                    <InputGroupText
                                      style={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        border:
                                          !editableConfigData.apiKey.isValid &&
                                          "0.1rem solid #fb6340",
                                        borderLeft:
                                          !editableConfigData.apiKey.isValid &&
                                          "none",
                                      }}
                                    >
                                      <InputGroupAddon addonType="append">
                                        <i
                                          onClick={toggleApiKeyVisibility}
                                          className={
                                            showApiKey
                                              ? "fa fa-eye password-icon"
                                              : "fa fa-eye-slash password-icon"
                                          }
                                        />
                                      </InputGroupAddon>
                                    </InputGroupText>
                                  )}
                                </InputGroup>
                                <div style={styles.errorContainer}>
                                  {!editableConfigData.apiKey.isValid && (
                                    <small className="text-danger">
                                      {editableConfigData.apiKey.errorMsg}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </FormGroup>
                          ) : null
                        ) : null}
                        {copyOfConfigData.mailingService.name !== "" &&
                          copyOfConfigData.integrationType.value !== "" && (
                            <>
                              <hr />
                              <FormGroup style={styles.formField}>
                                <Label for="senderName" style={styles.label}>
                                  Sender Name
                                </Label>
                                <div style={styles.inputContainer}>
                                  <Input
                                    type="text"
                                    id="senderName"
                                    className={
                                      !editableConfigData.senderName.isValid
                                        ? "is-invalid"
                                        : ""
                                    }
                                    value={editableConfigData.senderName.value}
                                    name="senderName"
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        copyOfConfigData.integrationType
                                      )
                                    }
                                    onBlur={(e) =>
                                      handleBlur(
                                        e,
                                        copyOfConfigData.integrationType
                                      )
                                    }
                                    style={styles.input}
                                    placeholder="Enter sender name"
                                    maxLength={60}
                                    autoComplete="off"
                                  />
                                  <div style={styles.errorContainer}>
                                    {!editableConfigData.senderName.isValid && (
                                      <small className="text-danger">
                                        {editableConfigData.senderName.errorMsg}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </FormGroup>
                              <FormGroup style={styles.formField}>
                                <Label for="email" style={styles.label}>
                                  Email
                                </Label>
                                <div style={styles.inputContainer}>
                                  <Input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className={
                                      !editableConfigData.email.isValid
                                        ? "is-invalid"
                                        : ""
                                    }
                                    value={editableConfigData.email.value}
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        copyOfConfigData.integrationType
                                      )
                                    }
                                    onBlur={(e) =>
                                      handleBlur(
                                        e,
                                        copyOfConfigData.integrationType
                                      )
                                    }
                                    style={styles.input}
                                    placeholder="Enter email"
                                    maxLength={100}
                                    autoComplete="off"
                                  />
                                  <div style={styles.errorContainer}>
                                    {!editableConfigData.email.isValid && (
                                      <small className="text-danger">
                                        {editableConfigData.email.errorMsg}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </FormGroup>
                              <FormGroup style={styles.formField}>
                                <Label for="replyToEmail" style={styles.label}>
                                  Reply-to Email
                                </Label>
                                <div style={styles.inputContainer}>
                                  <Input
                                    type="email"
                                    id="replyToEmail"
                                    name="replyToEmail"
                                    className={
                                      !editableConfigData.replyToEmail.isValid
                                        ? "is-invalid"
                                        : ""
                                    }
                                    value={
                                      editableConfigData.replyToEmail.value
                                    }
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        copyOfConfigData.integrationType
                                      )
                                    }
                                    onBlur={(e) =>
                                      handleBlur(
                                        e,
                                        copyOfConfigData.integrationType
                                      )
                                    }
                                    style={styles.input}
                                    placeholder="Enter reply-to email"
                                    maxLength={100}
                                    autoComplete="off"
                                  />
                                  <div style={styles.errorContainer}>
                                    {!editableConfigData.replyToEmail
                                      .isValid && (
                                      <small className="text-danger">
                                        {
                                          editableConfigData.replyToEmail
                                            .errorMsg
                                        }
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </FormGroup>
                            </>
                          )}
                        <div className="d-flex justify-content-end">
                          <Button
                            color={testButtonDisabled ? "default" : "info"}
                            disabled={testButtonDisabled || showLoaderOnClick}
                            onClick={() => {
                              setShowLoaderOnClick(true);
                              handleTestAndUpdateConnection();
                            }}
                            md={3}
                            sm={6}
                          >
                            {showLoaderOnClick ? (
                              <i
                                className="fa fa-spinner fa-spin fa-lg"
                                style={{ color: "white" }}
                              />
                            ) : (
                              "Test Connection & Update"
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EditEmailConfig;

// Inline styles
const styles = {
  formInstructions: {
    paddingRight: "15px",
  },
  formField: {
    marginBottom: "1rem",
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    width: "200px",
    textAlign: "left",
    marginRight: "1rem",
  },
  input: {
    flex: 1,
  },
  videoContainer: {
    width: "70%", // Adjust the width as needed
    maxWidth: "1200px", // Set a maximum width
    height: "80vh", // Adjust the height as needed
    maxHeight: "800px", // Set a maximum height
  },
  infoContainer: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    marginTop: "20px",
    backgroundColor: "#fff", // White background for container
  },
  heading: {
    backgroundColor: "#f6f9fc", // Background color for note content
    padding: "10px", // Adjust padding for better visual separation
    borderRadius: "4px", // Optional: Add border radius for rounded corners
  },
  contain: {
    padding: "8px",
  },
  underline: {
    border: "0",
    borderTop: "1px solid #ddd", // Color of the underline
    margin: "1px 0", // Space around the underline
  },
  highlight: {
    color: "blue", // Blue color for "Click here"
    textDecoration: "underline", // Optional: Adds underline for emphasis
    cursor: "pointer", // Optional: Changes cursor to pointer for a link-like effect
  },
  inputContainer: {
    width: "80%",
  },
  errorContainer: {
    color: "red", // Optional: Adjust color of the error message
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  noteTextStyle: {
    marginBottom: "-15px",
    paddingTop: "20px",
    width: "100%",
    fontSize: "0.875rem",
    color: "#6c757d",
  },
};
