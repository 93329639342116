import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Card, CardBody, CardHeader } from "reactstrap";

const CommonScreen = (props) => {
  const navigate = useNavigate();
  const styles = {
    thankYouContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#f0f4f8",
    },
    thankYouCard: {
      textAlign: "center",
    },
    thankYouMessage: {
      fontSize: "1rem",
      color: props.color,
    },
  };
  return (
    <Row className="justify-content-center mt-5">
      <Col md="4" lg="4">
        <Card style={styles.thankYouCard}>
          <CardHeader>
            <img
              alt="..."
              className="navbar-brand-img"
              src={require("assets/img/brand/BulkCalendar-Logo.png")}
              style={{ width: "50%", height: "50%" }}
            />
            <h2>
              {props.res?.code} {props.title}
            </h2>
          </CardHeader>
          <CardBody>
            <p style={styles.thankYouMessage}>{props.description}</p>
            {props.button ? (
              <Button
                color="info"
                onClick={() => {
                  navigate("/auth/login");
                }}
              >
                {props.button}
              </Button>
            ) : null}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CommonScreen;
