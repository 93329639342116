import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  Modal,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ConfigTable from "./ConfigTable";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  inputBorderColor,
  inputTextColor,
} from "../../../components/Common/constants";
import { serviceInfo } from "../constants";
import NotificationAlert from "react-notification-alert";
import {
  getMailingServiceList,
  addEmailConfig,
} from "action/Settings/emailConfiguration";
import { GOOGLE_CONFIG } from "API/apiConstants";

function EmailConfiguration() {
  // States for all fields
  const [configData, setConfigData] = useState({
    mailingService: {
      value: { ID: 0, name: "" },
      isValid: true,
      errorMsg: "",
    },
    integrationMethod: { value: "API", isValid: true, errorMsg: "" },
    title: { value: "", isValid: true, errorMsg: "" },
    smtpValueJson: {
      smtpServerURL: {
        value: "smtp.sendgrid.net",
        isValid: true,
        errorMsg: "",
      },
      encryptionType: {
        value: {
          port: "",
          encryptionType: "",
        },
        isValid: true,
        errorMsg: "",
      },
      port: { value: "", isValid: true, errorMsg: "" },
      accessKey: { value: "", isValid: true, errorMsg: "" },
      secretKey: { value: "", isValid: true, errorMsg: "" },
      senderName: { value: "", isValid: true, errorMsg: "" },
      email: { value: "", isValid: true, errorMsg: "" },
      replyToEmail: { value: "", isValid: true, errorMsg: "" },
    },
    apiValueJson: {
      senderName: { value: "", isValid: true, errorMsg: "" },
      email: { value: "", isValid: true, errorMsg: "" },
      replyToEmail: { value: "", isValid: true, errorMsg: "" },
      apiKey: { value: "", isValid: true, errorMsg: "" },
    },
  });
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [testButtonDisabled, setTestButtonDisabled] = useState(true);
  const [showLoaderOnClick, setShowLoaderOnClick] = useState(false);
  const [newRecordAdded, setNewRecordedAdded] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showApiKey, setShowApiKey] = useState(false);

  const dispatch = useDispatch();
  const listMailingService = useSelector(
    (state) => state.EmailConfigReducers.mailingServiceList
  );
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    if (isNotificationOpen) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };

    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  // Mailing service information
  const mailVideo = {
    SendGrid: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/GRP7SIXS90E?si=oWXvmzLKYqUI6aOG?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    Custom: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/GRP7SIXS90E?si=oWXvmzLKYqUI6aOG?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    SendInBlue: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/JiHId_rPahU?si=4SIJ8rLNr7NLy5Yu?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    MailGun: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/NKMMpgJTDmY?si=AWIQ7O-Ut3IHKqWG?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    AWSSES: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/gVRTKuMFc0c?si=6DpQC25s0B93O2qt?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    ElasticEmail: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/JGwJ8rBvvJo?si=ylWuRe4DhmZm1n9d?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    Mailjet: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/t0lVxW30GuY?si=fNROFRiujc8B0XYG?rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ),
    Google: (
      <iframe
        width="1200px"
        height="700"
        src="https://www.youtube.com/embed/Muhc63a_W9w?si=YWmGz5zY_SR2cnTT"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    ),
  };

  //handle error for add email configuration
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get("error");
    const success = searchParams.get("success");

    if (success) {
      getMailingServiceList(dispatch);
      notify("success", "Email configuration added successfully");
    } else if (error) {
      notify("error", "You have not provided sufficient permission.");
    }
    // Remove search params after handling
    const newURL = window.location.pathname; // Retains only the path without query params
    window.history.replaceState(null, "", newURL); // Update URL without reloading
  }, [dispatch]);

  //api call to get maling service list
  useEffect(() => {
    getMailingServiceList(dispatch);
  }, []);

  //to check if all fields of an object is filled and isValid
  const checkObjectValueeValidAndNonEmpty = (obj) => {
    let isValid = false;
    for (const key in obj) {
      if (
        key === "encryptionType" &&
        obj[key].value.encryptionType.trim().length > 0 &&
        obj[key].isValid
      ) {
        isValid = true;
      } else if (
        typeof obj[key].value === "string" &&
        obj[key].value.trim().length > 0 &&
        obj[key].isValid
      ) {
        isValid = true;
      } else if (
        typeof obj[key].value === "number" &&
        obj[key].value > 0 &&
        obj[key].isValid
      ) {
        isValid = true;
      } else {
        return false;
      }
    }
    return isValid;
  };

  //to check if according to integration method all fields are filler or not
  useEffect(() => {
    if (
      configData.title.value.trim().length > 0 &&
      configData.mailingService.value.ID > 0 &&
      configData.integrationMethod.value !== ""
    ) {
      if (
        configData.integrationMethod.value === "SMTP" &&
        checkObjectValueeValidAndNonEmpty(configData.smtpValueJson)
      ) {
        setTestButtonDisabled(false); // enable the button
      } else if (
        configData.integrationMethod.value === "API" &&
        checkObjectValueeValidAndNonEmpty(configData.apiValueJson)
      ) {
        setTestButtonDisabled(false); // enable the button
      } else {
        setTestButtonDisabled(true);
      }
    } else {
      setTestButtonDisabled(true);
    }
  }, [configData]);

  //for customizing the appearance of the React Select to match other input fields
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.selectProps.className?.includes("is-invalid")
        ? "red"
        : inputBorderColor,
      height: "2.8rem",
      fontSize: "0.85rem",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: inputTextColor,
    }),
    menuList: (provided) => ({
      ...provided,
      fontSize: "0.8rem",
    }),
  };

  //handle modal of intruction video
  const handleModal = () => {
    setOpen(true);
  };

  //on change of input fields setting the values
  const handleChange = (e, fromJSON) => {
    const { name, value } = e.target;
    if (fromJSON === "SMTP") {
      if (name === "email") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            smtpValueJson: {
              ...prevState.smtpValueJson,
              [name]: { value: value, isValid: true, errorMsg: "" },
              replyToEmail: { value: value, isValid: true, errorMsg: "" },
            },
          };
        });
      }
      setConfigData((prevState) => {
        return {
          ...prevState,
          smtpValueJson: {
            ...prevState.smtpValueJson,
            [name]: { value: value, isValid: true, errorMsg: "" },
          },
        };
      });
    } else if (fromJSON === "API") {
      if (name === "email") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            apiValueJson: {
              ...prevState.apiValueJson,
              [name]: { value: value, isValid: true, errorMsg: "" },
              replyToEmail: { value: value, isValid: true, errorMsg: "" },
            },
          };
        });
      }
      setConfigData((prevState) => {
        return {
          ...prevState,
          apiValueJson: {
            ...prevState.apiValueJson,
            [name]: { value: value, isValid: true, errorMsg: "" },
          },
        };
      });
    } else {
      if (name === "email") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            [name]: { value: value, isValid: true, errorMsg: "" },
            replyToEmail: { value: value, isValid: true, errorMsg: "" },
          };
        });
      }
      setConfigData((prevState) => {
        return {
          ...prevState,
          [name]: { value: value, isValid: true, errorMsg: "" },
        };
      });
    }
  };

  //on blur setting error if the field is empty
  const handleBlur = (e, fromJSON) => {
    const { name, value } = e.target;
    if (value.trim().length === 0) {
      if (fromJSON === "SMTP") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            smtpValueJson: {
              ...prevState.smtpValueJson,
              [name]: {
                ...prevState.smtpValueJson[name],
                isValid: false,
                errorMsg: "This field is required.",
              },
            },
          };
        });
      } else if (fromJSON === "API") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            apiValueJson: {
              ...prevState.apiValueJson,
              [name]: {
                ...prevState.apiValueJson[name],
                isValid: false,
                errorMsg: "This field is required.",
              },
            },
          };
        });
      } else {
        setConfigData((prevState) => {
          return {
            ...prevState,
            [name]: {
              ...prevState[name],
              isValid: false,
              errorMsg: "This field is required.",
            },
          };
        });
      }
    } else if (
      (name === "email" || name === "replyToEmail") &&
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
    ) {
      if (fromJSON === "SMTP") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            smtpValueJson: {
              ...prevState.smtpValueJson,
              [name]: {
                ...prevState.smtpValueJson[name],
                isValid: false,
                errorMsg: "Please enter a valid email",
              },
            },
          };
        });
      } else if (fromJSON === "API") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            apiValueJson: {
              ...prevState.apiValueJson,
              [name]: {
                ...prevState.apiValueJson[name],
                isValid: false,
                errorMsg: "Please enter a valid emai.",
              },
            },
          };
        });
      }
    }
  };

  //on change of react select fields
  const handleSelectChange = (selected, e, fromJSON) => {
    if (fromJSON === "SMTP") {
      //this is for encryptionType is selected
      setConfigData((prevState) => {
        return {
          ...prevState,
          smtpValueJson: {
            ...prevState.smtpValueJson,
            [e.name]: {
              value: selected,
              isValid: true,
              errorMsg: "",
            },
            port: {
              value: e.name === "encryptionType" ? selected.port : "",
              isValid: true,
              errorMsg: "",
            },
          },
        };
      });
    } else if (selected.ID === 3) {
      setConfigData((prevState) => {
        return {
          ...prevState,
          [e.name]: {
            value: selected,
            isValid: true,
            errorMsg: "",
          },
          smtpValueJson: {
            ...prevState.smtpValueJson,
            smtpServerURL: {
              value: selected.smtpJson.smtpServerURL,
              isValid: true,
              errorMsg: "",
            },
          },
          integrationMethod: { value: "SMTP", isValid: true, errorMsg: "" },
        };
      });
    } else {
      setConfigData((prevState) => {
        return {
          ...prevState,
          [e.name]: {
            value: selected,
            isValid: true,
            errorMsg: "",
          },
          smtpValueJson: {
            ...prevState.smtpValueJson,
            smtpServerURL: {
              value: selected.smtpJson.smtpServerURL,
              isValid: true,
              errorMsg: "",
            },
          },
          integrationMethod: { value: "API", isValid: true, errorMsg: "" },
        };
      });
    }
  };

  //on change of react select fields
  const handleSelectOnBlur = (name, value, fromJSON) => {
    if (value.trim().length === 0) {
      if (fromJSON === "SMTP") {
        setConfigData((prevState) => {
          return {
            ...prevState,
            smtpValueJson: {
              ...prevState.smtpValueJson,
              [name]: {
                ...prevState.smtpValueJson[name],
                isValid: false,
                errorMsg: "This Field is Required",
              },
            },
          };
        });
      } else {
        setConfigData((prevState) => {
          return {
            ...prevState,
            [name]: {
              ...prevState[name],
              isValid: false,
              errorMsg: "This Field is Required",
            },
          };
        });
      }
    }
  };

  //to reset the state after form is submitted
  const resetState = () => {
    setConfigData({
      mailingService: {
        value: { ID: 0, name: "" },
        isValid: true,
        errorMsg: "",
      },
      integrationMethod: { value: "SMTP", isValid: true, errorMsg: "" },
      title: { value: "", isValid: true, errorMsg: "" }, //the key name for backend is name
      smtpValueJson: {
        smtpServerURL: {
          value: "smtp.sendgrid.net",
          isValid: true,
          errorMsg: "",
        },
        encryptionType: {
          value: {
            port: "",
            encryptionType: "",
          },
          isValid: true,
          errorMsg: "",
        },
        port: { value: "", isValid: true, errorMsg: "" },
        accessKey: { value: "", isValid: true, errorMsg: "" },
        secretKey: { value: "", isValid: true, errorMsg: "" },
        senderName: { value: "", isValid: true, errorMsg: "" },
        email: { value: "", isValid: true, errorMsg: "" },
        replyToEmail: { value: "", isValid: true, errorMsg: "" },
      },
      apiValueJson: {
        senderName: { value: "", isValid: true, errorMsg: "" },
        email: { value: "", isValid: true, errorMsg: "" },
        replyToEmail: { value: "", isValid: true, errorMsg: "" },
        apiKey: { value: "", isValid: true, errorMsg: "" },
      },
    });
  };

  //on click of test and save connect call to api of add email config
  const handleTestConnection = async () => {
    let configBodyData = {
      mailingServiceID: configData.mailingService.value.ID,
      title: configData.title.value,
      integrationType: configData.integrationMethod.value,
    };
    if (configData.integrationMethod.value === "SMTP") {
      configBodyData = {
        ...configBodyData,
        smtpValueJson: {
          smtpServerURL: configData.smtpValueJson.smtpServerURL.value,
          encryptionType:
            configData.smtpValueJson.encryptionType.value.encryptionType,
          port: configData.smtpValueJson.port.value,
          accessKey: configData.smtpValueJson.accessKey.value,
          secretKey: configData.smtpValueJson.secretKey.value,
          senderName: configData.smtpValueJson.senderName.value,
          email: configData.smtpValueJson.email.value,
          replyToEmail: configData.smtpValueJson.replyToEmail.value,
        },
      };
    } else if (configData.integrationMethod.value === "API") {
      configBodyData = {
        ...configBodyData,
        apiValueJson: {
          senderName: configData.apiValueJson.senderName.value,
          email: configData.apiValueJson.email.value,
          replyToEmail: configData.apiValueJson.replyToEmail.value,
          apiKey: configData.apiValueJson.apiKey.value,
        },
      };
    }

    const res = await addEmailConfig(configBodyData);

    if (res.code === 200) {
      notify("success", "Email Configuration added successfully!");
      resetState();
      setNewRecordedAdded(true);
    } else if (res.code === 400) {
      notify(
        "error",
        Array.isArray(res.message)
          ? res.message[0].Msg
          : res.message
          ? res.message
          : "Something went wrong"
      );
    } else {
      notify("error", res.message ? res.message : "Something went wrong");
    }
    setShowLoaderOnClick(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  const handleGoogleLogin = () => {
    if (!configData.title.value) {
      return notify("error", "Please add title first.");
    }
    const token = JSON.parse(localStorage.getItem("p")).token;
    setIsLoading(true);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("token", `${token}`);
    // Fetch request
    fetch(`${GOOGLE_CONFIG}?title=${configData.title.value}`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          window.location.href = res.data;
        } else {
          setIsLoading(false);
          notify(
            "error",
            res.message || "An error occurred during Google login."
          );
        }
      })
      .catch((error) => {
        notify("error", "Failed to connect to the Google login service.");
      });
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height
            backgroundColor: "white", // Blank screen background color
          }}
        >
          <i className="fa fa-spinner fa-spin fa-3x text-black" />
        </div>
      ) : (
        <>
          <div className="rna-wrapper">
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          {/* <SimpleHeader name="Settings" parentName="Settings" /> */}
          <Container className="mt--6" fluid>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">Add Email Configuration</h3>
                    <h3 style={styles.noteTextStyle}>
                      <span>Note:</span> The email configurations are securely
                      stored using 256-bit AES-GCM encryption.
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="9">
                          <FormGroup style={styles.formField}>
                            <Label for="title" style={styles.label}>
                              Title (for internal reference){" "}
                              <strong style={styles.mandatory}>*</strong>
                            </Label>
                            <div style={styles.inputContainer}>
                              <Input
                                type="text"
                                id="title"
                                value={configData.title.value}
                                name="title"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                className={
                                  !configData.title.isValid ? "is-invalid" : ""
                                }
                                onBlur={(e) => handleBlur(e)}
                                style={styles.input}
                                placeholder="Enter title"
                                maxLength={60}
                                autoComplete="off"
                              />
                              <div style={styles.errorContainer}>
                                {!configData.title.isValid && (
                                  <small className="text-danger">
                                    {configData.title.errorMsg}
                                  </small>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                          <FormGroup style={styles.formField}>
                            <Label for="mailingService" style={styles.label}>
                              Email Service{" "}
                              <strong style={styles.mandatory}>*</strong>
                            </Label>
                            <div style={styles.inputContainer}>
                              <Select
                                options={listMailingService.mailingService}
                                placeholder="Select Emaling Service"
                                name="mailingService"
                                getOptionLabel={(options) => options.name}
                                getOptionValue={(options) => options.ID}
                                onChange={(selected, e) =>
                                  handleSelectChange(selected, e)
                                }
                                onBlur={() => {
                                  handleSelectOnBlur(
                                    "mailingService",
                                    configData.mailingService.value.name
                                  );
                                }}
                                styles={customStyles}
                                value={
                                  listMailingService.mailingService?.find(
                                    (option) =>
                                      option.ID ===
                                      configData.mailingService.value.ID
                                  ) || ""
                                } // find the selected option based on value
                                isSearchable={true} // enable search functionality
                                className={
                                  !configData.mailingService.isValid
                                    ? "is-invalid"
                                    : null
                                }
                              />

                              <div style={styles.errorContainer}>
                                {!configData.mailingService.isValid && (
                                  <small className="text-danger">
                                    {configData.mailingService.errorMsg}
                                  </small>
                                )}
                              </div>
                              {configData.mailingService.value.ID === 4 && (
                                <Button
                                  className=" btn-icon text-white mt-3"
                                  style={styles.googleButton}
                                  onClick={handleGoogleLogin}
                                >
                                  <span className="btn-inner--icon bg-white p-2 ml--3">
                                    <img
                                      alt="Google Icon"
                                      src={
                                        require("assets/img/icons/common/google.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  <span className="btn-inner--text">
                                    Configure with Google
                                  </span>
                                </Button>
                              )}
                            </div>
                          </FormGroup>
                          {configData.mailingService.value.ID === 1 ||
                          configData.mailingService.value.ID === 2 ? (
                            <>
                              <FormGroup style={styles.formField}>
                                <Label style={styles.label}>
                                  Integration Method{" "}
                                  <strong style={styles.mandatory}>*</strong>
                                </Label>
                                <div>
                                  <Label check className="ml-4">
                                    <Input
                                      type="radio"
                                      value="API"
                                      name="integrationMethod"
                                      checked={
                                        configData.integrationMethod.value ===
                                        "API"
                                      }
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                    />
                                    API
                                  </Label>
                                  {/* <Label check className="ml-6">
                                    <Input
                                      type="radio"
                                      value="SMTP"
                                      name="integrationMethod"
                                      checked={
                                        configData.integrationMethod.value ===
                                        "SMTP"
                                      }
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                    />
                                    SMTP
                                  </Label> */}
                                </div>
                              </FormGroup>
                            </>
                          ) : null}
                          {configData.mailingService.value.ID === 1 ||
                          configData.mailingService.value.ID === 3 ||
                          configData.mailingService.value.ID === 2 ? (
                            configData.integrationMethod.value === "SMTP" ? (
                              <>
                                <FormGroup style={styles.formField}>
                                  <Label
                                    for="smtpServerURL"
                                    style={styles.label}
                                  >
                                    SMTP URL{" "}
                                    <strong style={styles.mandatory}>*</strong>
                                  </Label>
                                  <div style={styles.inputContainer}>
                                    <Input
                                      type="text"
                                      id="smtpServerURL"
                                      className={
                                        !configData.smtpValueJson.smtpServerURL
                                          .isValid
                                          ? "is-invalid"
                                          : ""
                                      }
                                      name="smtpServerURL"
                                      value={
                                        configData.smtpValueJson.smtpServerURL
                                          .value
                                      }
                                      onChange={(e) => {
                                        handleChange(e, "SMTP");
                                      }}
                                      onBlur={(e) => handleBlur(e, "SMTP")}
                                      style={styles.input}
                                      placeholder="Enter SMTP URL"
                                      // disabled={
                                      //   configData.mailingService.value.name ===
                                      //   "Custom"
                                      //     ? false
                                      //     : true
                                      // }
                                      autoComplete="off"
                                    />
                                    <div style={styles.errorContainer}>
                                      {!configData.smtpValueJson.smtpServerURL
                                        .isValid && (
                                        <small className="text-danger">
                                          {
                                            configData.smtpValueJson
                                              .smtpServerURL.errorMsg
                                          }
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                                <FormGroup style={styles.formField}>
                                  <Label
                                    for="encryptionType"
                                    style={styles.label}
                                  >
                                    Encryption Type{" "}
                                    <strong style={styles.mandatory}>*</strong>
                                  </Label>
                                  <div style={styles.inputContainer}>
                                    <Select
                                      options={
                                        configData.mailingService.value
                                          .encryptionTypeJson
                                      }
                                      placeholder="Select Encription Type"
                                      name="encryptionType"
                                      getOptionLabel={(options) =>
                                        options.encryptionType
                                      }
                                      getOptionValue={(options) => options.port}
                                      onChange={(selected, e) =>
                                        handleSelectChange(selected, e, "SMTP")
                                      }
                                      onBlur={() => {
                                        handleSelectOnBlur(
                                          "encryptionType",
                                          configData.smtpValueJson
                                            .encryptionType.value
                                            .encryptionType,
                                          "SMTP"
                                        );
                                      }}
                                      styles={customStyles}
                                      value={
                                        configData.smtpValueJson.encryptionType
                                          .value.encryptionType
                                          ? configData.smtpValueJson
                                              .encryptionType.value
                                          : ""
                                      }
                                      className={
                                        !configData.smtpValueJson.encryptionType
                                          .isValid
                                          ? "is-invalid"
                                          : null
                                      }
                                    />
                                    <div style={styles.errorContainer}>
                                      {!configData.smtpValueJson.encryptionType
                                        .isValid && (
                                        <small className="text-danger">
                                          {
                                            configData.smtpValueJson
                                              .encryptionType.errorMsg
                                          }
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                                <FormGroup style={styles.formField}>
                                  <Label for="port" style={styles.label}>
                                    Port{" "}
                                    <strong style={styles.mandatory}>*</strong>
                                  </Label>
                                  <div style={styles.inputContainer}>
                                    <Input
                                      type="text"
                                      id="port"
                                      className={
                                        !configData.smtpValueJson.port.isValid
                                          ? "is-invalid"
                                          : ""
                                      }
                                      name="port"
                                      value={
                                        configData.smtpValueJson.port.value
                                      }
                                      onChange={(e) => {
                                        handleChange(e, "SMTP");
                                      }}
                                      onBlur={(e) => handleBlur(e, "SMTP")}
                                      style={styles.input}
                                      placeholder="Enter Port"
                                      maxLength={3}
                                      disabled={true}
                                      autoComplete="off"
                                    />
                                    <div style={styles.errorContainer}>
                                      {!configData.smtpValueJson.port
                                        .isValid && (
                                        <small className="text-danger">
                                          {
                                            configData.smtpValueJson.port
                                              .errorMsg
                                          }
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                                <FormGroup style={styles.formField}>
                                  <Label for="accessKey" style={styles.label}>
                                    Access Key / Username{" "}
                                    <strong style={styles.mandatory}>*</strong>
                                  </Label>
                                  <div style={styles.inputContainer}>
                                    <Input
                                      type="text"
                                      id="accessKey"
                                      className={
                                        !configData.smtpValueJson.accessKey
                                          .isValid
                                          ? "is-invalid"
                                          : ""
                                      }
                                      name="accessKey"
                                      value={
                                        configData.smtpValueJson.accessKey.value
                                      }
                                      onChange={(e) => {
                                        handleChange(e, "SMTP");
                                      }}
                                      onBlur={(e) => handleBlur(e, "SMTP")}
                                      style={styles.input}
                                      placeholder="Enter access key or username"
                                      maxLength={60}
                                      autoComplete="off"
                                    />
                                    <div style={styles.errorContainer}>
                                      {!configData.smtpValueJson.accessKey
                                        .isValid && (
                                        <small className="text-danger">
                                          {
                                            configData.smtpValueJson.accessKey
                                              .errorMsg
                                          }
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                                <FormGroup style={styles.formField}>
                                  <Label for="secretKey" style={styles.label}>
                                    Secret Key / Password{" "}
                                    <strong style={styles.mandatory}>*</strong>
                                  </Label>
                                  <div style={styles.inputContainer}>
                                    <InputGroup>
                                      <Input
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        className={
                                          !configData.smtpValueJson.secretKey
                                            .isValid
                                            ? "is-invalid"
                                            : ""
                                        }
                                        id="secretKey"
                                        name="secretKey"
                                        value={
                                          configData.smtpValueJson.secretKey
                                            .value
                                        }
                                        onChange={(e) =>
                                          handleChange(e, "SMTP")
                                        }
                                        onBlur={(e) => handleBlur(e, "SMTP")}
                                        style={styles.input}
                                        placeholder="Enter secret key or password"
                                        maxLength={150}
                                        autoComplete="off"
                                      />
                                      <InputGroupText
                                        style={{
                                          borderTopLeftRadius: 0,
                                          borderBottomLeftRadius: 0,
                                          border:
                                            !configData.smtpValueJson.secretKey
                                              .isValid &&
                                            "0.1rem solid #fb6340",
                                          borderLeft:
                                            !configData.smtpValueJson.secretKey
                                              .isValid && "none",
                                        }}
                                      >
                                        <InputGroupAddon addonType="append">
                                          <i
                                            onClick={togglePasswordVisibility}
                                            className={
                                              showPassword
                                                ? "fa fa-eye password-icon"
                                                : "fa fa-eye-slash password-icon"
                                            }
                                          />
                                        </InputGroupAddon>
                                      </InputGroupText>
                                    </InputGroup>
                                    <div style={styles.errorContainer}>
                                      {!configData.smtpValueJson.secretKey
                                        .isValid && (
                                        <small className="text-danger">
                                          {
                                            configData.smtpValueJson.secretKey
                                              .errorMsg
                                          }
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                              </>
                            ) : configData.integrationMethod.value === "API" ? (
                              <FormGroup style={styles.formField}>
                                <Label for="apiKey" style={styles.label}>
                                  API key
                                </Label>
                                <div style={styles.inputContainer}>
                                  <InputGroup>
                                    <Input
                                      type={showApiKey ? "text" : "password"}
                                      className={
                                        !configData.apiValueJson.apiKey.isValid
                                          ? "is-invalid"
                                          : ""
                                      }
                                      id="apiKey"
                                      name="apiKey"
                                      value={
                                        configData.apiValueJson.apiKey.value
                                      }
                                      onChange={(e) => handleChange(e, "API")}
                                      onBlur={(e) => handleBlur(e, "API")}
                                      style={styles.input}
                                      placeholder="Enter API key"
                                      autoComplete="off"
                                    />
                                    <InputGroupText
                                      style={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        border:
                                          !configData.apiValueJson.apiKey
                                            .isValid && "0.1rem solid #fb6340",
                                        borderLeft:
                                          !configData.apiValueJson.apiKey
                                            .isValid && "none",
                                      }}
                                    >
                                      <InputGroupAddon addonType="append">
                                        <i
                                          onClick={toggleApiKeyVisibility}
                                          className={
                                            showApiKey
                                              ? "fa fa-eye password-icon"
                                              : "fa fa-eye-slash password-icon"
                                          }
                                        />
                                      </InputGroupAddon>
                                    </InputGroupText>
                                  </InputGroup>
                                  <div style={styles.errorContainer}>
                                    {!configData.apiValueJson.apiKey
                                      .isValid && (
                                      <small className="text-danger">
                                        {
                                          configData.apiValueJson.apiKey
                                            .errorMsg
                                        }
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </FormGroup>
                            ) : null
                          ) : null}
                          {configData.mailingService.value.name !== "" &&
                            configData.mailingService.value.ID !== 4 &&
                            configData.integrationMethod.value !== "" && (
                              <>
                                <hr />
                                <FormGroup style={styles.formField}>
                                  <Label for="senderName" style={styles.label}>
                                    Sender Name{" "}
                                    <strong style={styles.mandatory}>*</strong>
                                  </Label>
                                  <div style={styles.inputContainer}>
                                    <Input
                                      type="text"
                                      id="senderName"
                                      className={
                                        !configData[
                                          configData.integrationMethod.value ===
                                          "SMTP"
                                            ? "smtpValueJson"
                                            : "apiValueJson"
                                        ].senderName.isValid
                                          ? "is-invalid"
                                          : ""
                                      }
                                      value={
                                        configData[
                                          configData.integrationMethod.value ===
                                          "SMTP"
                                            ? "smtpValueJson"
                                            : "apiValueJson"
                                        ].senderName.value
                                      }
                                      name="senderName"
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          configData.integrationMethod.value
                                        )
                                      }
                                      onBlur={(e) =>
                                        handleBlur(
                                          e,
                                          configData.integrationMethod.value
                                        )
                                      }
                                      style={styles.input}
                                      placeholder="Enter sender name"
                                      maxLength={60}
                                      autoComplete="off"
                                    />
                                    <div style={styles.errorContainer}>
                                      {!configData[
                                        configData.integrationMethod.value ===
                                        "SMTP"
                                          ? "smtpValueJson"
                                          : "apiValueJson"
                                      ].senderName.isValid && (
                                        <small className="text-danger">
                                          {
                                            configData[
                                              configData.integrationMethod
                                                .value === "SMTP"
                                                ? "smtpValueJson"
                                                : "apiValueJson"
                                            ].senderName.errorMsg
                                          }
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                                <FormGroup style={styles.formField}>
                                  <Label for="email" style={styles.label}>
                                    Email{" "}
                                    <strong style={styles.mandatory}>*</strong>
                                  </Label>
                                  <div style={styles.inputContainer}>
                                    <Input
                                      type="email"
                                      id="email"
                                      name="email"
                                      className={
                                        !configData[
                                          configData.integrationMethod.value ===
                                          "SMTP"
                                            ? "smtpValueJson"
                                            : "apiValueJson"
                                        ].email.isValid
                                          ? "is-invalid"
                                          : ""
                                      }
                                      value={
                                        configData[
                                          configData.integrationMethod.value ===
                                          "SMTP"
                                            ? "smtpValueJson"
                                            : "apiValueJson"
                                        ].email.value
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          configData.integrationMethod.value
                                        )
                                      }
                                      onBlur={(e) =>
                                        handleBlur(
                                          e,
                                          configData.integrationMethod.value
                                        )
                                      }
                                      style={styles.input}
                                      placeholder="Enter email"
                                      maxLength={100}
                                      autoComplete="off"
                                    />
                                    <div style={styles.errorContainer}>
                                      {!configData[
                                        configData.integrationMethod.value ===
                                        "SMTP"
                                          ? "smtpValueJson"
                                          : "apiValueJson"
                                      ].email.isValid && (
                                        <small className="text-danger">
                                          {
                                            configData[
                                              configData.integrationMethod
                                                .value === "SMTP"
                                                ? "smtpValueJson"
                                                : "apiValueJson"
                                            ].email.errorMsg
                                          }
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                                <FormGroup style={styles.formField}>
                                  <Label
                                    for="replyToEmail"
                                    style={styles.label}
                                  >
                                    Reply-to Email{" "}
                                    <strong style={styles.mandatory}>*</strong>
                                  </Label>
                                  <div style={styles.inputContainer}>
                                    <Input
                                      type="email"
                                      id="replyToEmail"
                                      name="replyToEmail"
                                      className={
                                        !configData[
                                          configData.integrationMethod.value ===
                                          "SMTP"
                                            ? "smtpValueJson"
                                            : "apiValueJson"
                                        ].replyToEmail.isValid
                                          ? "is-invalid"
                                          : ""
                                      }
                                      value={
                                        configData[
                                          configData.integrationMethod.value ===
                                          "SMTP"
                                            ? "smtpValueJson"
                                            : "apiValueJson"
                                        ].replyToEmail.value
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          configData.integrationMethod.value
                                        )
                                      }
                                      onBlur={(e) =>
                                        handleBlur(
                                          e,
                                          configData.integrationMethod.value
                                        )
                                      }
                                      style={styles.input}
                                      placeholder="Enter reply-to email"
                                      maxLength={100}
                                      autoComplete="off"
                                    />
                                    <div style={styles.errorContainer}>
                                      {!configData[
                                        configData.integrationMethod.value ===
                                        "SMTP"
                                          ? "smtpValueJson"
                                          : "apiValueJson"
                                      ].replyToEmail.isValid && (
                                        <small className="text-danger">
                                          {
                                            configData[
                                              configData.integrationMethod
                                                .value === "SMTP"
                                                ? "smtpValueJson"
                                                : "apiValueJson"
                                            ].replyToEmail.errorMsg
                                          }
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                </FormGroup>
                              </>
                            )}
                          <div className="d-flex justify-content-end">
                            {configData.mailingService.value.ID !== 4 ? (
                              <Button
                                color={testButtonDisabled ? "default" : "info"}
                                disabled={
                                  testButtonDisabled || showLoaderOnClick
                                }
                                onClick={() => {
                                  setShowLoaderOnClick(true);
                                  handleTestConnection();
                                }}
                                md={3}
                                sm={6}
                              >
                                {showLoaderOnClick ? (
                                  <i
                                    className="fa fa-spinner fa-spin fa-lg"
                                    style={{ color: "white" }}
                                  />
                                ) : (
                                  "Test Connection & Save"
                                )}
                              </Button>
                            ) : null}
                          </div>
                        </Col>
                        {configData.mailingService.value.name !== "" &&
                          configData.integrationMethod.value !== "" && (
                            <Col md="3">
                              <div style={styles.infoContainer}>
                                <h5 style={styles.heading}>
                                  Service Information:
                                </h5>
                                <p style={styles.contain}>
                                  {
                                    serviceInfo[
                                      configData.mailingService.value.ID
                                    ]
                                  }
                                </p>
                                {/* <hr style={styles.underline} /> */}
                                {/* <p style={styles.contain}>
                                  <Button
                                    className="btn-icon"
                                    color="error"
                                    outline
                                    type="button"
                                    onClick={handleModal}
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fab fa-youtube" />
                                    </span>
                                    <span className="btn-inner--text">
                                      {`${configData.mailingService.value.name} Instructions`}
                                    </span>
                                  </Button>
                                </p> */}
                              </div>
                              <div
                                style={styles.infoContainer}
                                className="mt-3"
                              >
                                <h5 style={styles.heading}>Note</h5>
                                <p style={styles.contain}>
                                  1. Please ensure that the sender email and
                                  domain are also configured and verified at
                                  smtp provider.
                                </p>
                              </div>
                            </Col>
                          )}
                      </Row>
                    </Form>
                    <hr />
                    <ConfigTable
                      setNewRecordedAdded={setNewRecordedAdded}
                      newRecordAdded={newRecordAdded}
                    />
                  </CardBody>
                </Card>
              </Col>

              <Modal
                isOpen={open}
                toggle={() => {
                  setOpen(false);
                }}
                style={styles.videoContainer}
                className="d-flex justify-content-center"
              >
                {mailVideo[configData.mailingService.value.name]}
              </Modal>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default EmailConfiguration;

// Inline styles
const styles = {
  formInstructions: {
    paddingRight: "15px",
  },
  formField: {
    marginBottom: "1rem",
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    width: "200px",
    textAlign: "left",
    marginRight: "1rem",
  },
  input: {
    flex: 1,
  },
  videoContainer: {
    width: "70%", // Adjust the width as needed
    maxWidth: "1200px", // Set a maximum width
    height: "80vh", // Adjust the height as needed
    maxHeight: "800px", // Set a maximum height
  },
  infoContainer: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#fff", // White background for container
  },
  heading: {
    backgroundColor: "#f6f9fc", // Background color for note content
    padding: "10px", // Adjust padding for better visual separation
    borderRadius: "4px", // Optional: Add border radius for rounded corners
  },
  contain: {
    padding: "8px",
  },
  underline: {
    border: "0",
    borderTop: "1px solid #ddd", // Color of the underline
    margin: "1px 0", // Space around the underline
  },
  highlight: {
    color: "blue", // Blue color for "Click here"
    textDecoration: "underline", // Optional: Adds underline for emphasis
    cursor: "pointer", // Optional: Changes cursor to pointer for a link-like effect
  },
  inputContainer: {
    width: "80%",
  },
  errorContainer: {
    color: "red", // Optional: Adjust color of the error message
  },
  mandatory: {
    color: "red",
    fontSize: "0.875rem",
  },
  noteTextStyle: {
    marginBottom: "-15px",
    paddingTop: "20px",
    width: "100%",
    fontSize: "0.875rem",
    color: "#6c757d",
  },
  googleButton: {
    backgroundColor: "#00639b",
  },
};
