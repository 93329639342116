import { attendeeList } from "constants/CampaignConstants/createCampaign";
import * as api from "../../API/index";
import { campaignDetails } from "constants/CampaignConstants/createCampaign";
import { authorizedUser } from "components/CommonFunctions";

export const createCampaignStep1 = async (bodyData) => {
  try {
    const res = await api.create_campaign_step1(bodyData);
    return res.data;
  } catch (error) {
    return error.response.data ? error.response.data : error;
  }
};

export const getAttendeeList = async (
  dispatch,
  campaignCode,
  pageNo,
  filter,
  sortParam
) => {
  try {
    const res = await api.get_attendee_list(
      campaignCode,
      pageNo,
      filter,
      sortParam
    );
    dispatch({ type: attendeeList, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: attendeeList, payload: error.response.data });
  }
};

export const getCampaignDetails = async (dispatch, ID, isReturn) => {
  try {
    const res = await api.get_campaign_detail(ID);
    dispatch({ type: campaignDetails, payload: res.data.data });
    if (isReturn) {
      return res.data.data;
    } 
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: campaignDetails, payload: error.response.data });
  }
};

export const deleteAttendee = async (campaignCode, bodyData) => {
  try {
    const res = await api.delete_attendee(campaignCode, bodyData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data ? error.response.data : error;
  }
};

export const bulkUpdateAttendee = async (campaignCode, bodyData) => {
  try {
    const res = await api.bulk_update_attendee(campaignCode, bodyData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data ? error.response.data : error;
  }
};

export const updateAttendee = async (campaignCode, bodyData) => {
  try {
    const res = await api.update_attendee(campaignCode, bodyData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data ? error.response.data : error;
  }
};

export const addAttendee = async (campaignCode, bodyData) => {
  try {
    const res = await api.add_attendee(campaignCode, bodyData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data ? error.response.data : error;
  }
};

export const sendTestInviteEmail = async (campaignCode, bodyData) => {
  try {
    const res = await api.send_test_invite_email(campaignCode, bodyData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data ? error.response.data : error;
  }
};

export const updateCampaign = async (campaignCode, bodyData) => {
  try {
    const res = await api.update_campaign(campaignCode, bodyData);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data ? error.response.data : error;
  }
};

export const sendCampaign = async (campaignCode) => {
  try {
    const res = await api.send_campaign(campaignCode);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data ? error.response.data : error;
  }
};

export const clearAttendeeListFrmStore = (dispatch) => {
  dispatch({ type: attendeeList, payload: [] });
};

export const clearCampaignDetailsFrmStore = (dispatch) => {
  dispatch({ type: campaignDetails, payload: {} });
};
