import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { USER_REGISTRATION } from "API/apiConstants";
import { useNavigate } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "API/apiConstants";

function RegistrationDetails(props) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null); // Add state for reCAPTCHA
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    if (isNotificationOpen) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };

    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      const strength = getPasswordStrength(value);
      setPasswordStrength(strength);
    }

    setFormData({ ...formData, [name]: value });

    // Remove error when user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    validateField(name, value);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case "Strong":
        return "text-success";
      case "Medium":
        return "text-warning";
      case "Weak":
      default:
        return "text-danger";
    }
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const getPasswordStrength = (password) => {
    let strength = "Weak";
    if (
      password.length >= 6 &&
      /\d/.test(password) &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password)
    ) {
      strength = "Strong";
    } else if (
      password.length >= 6 &&
      (/[a-z]/.test(password) || /[A-Z]/.test(password))
    ) {
      strength = "Medium";
    }
    return strength;
  };

  const validateField = (name, value) => {
    let error = "";
    // Regular expressions
    // const textOnlyRegex = /^[a-zA-Z]+$/;
    // const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    const strength = getPasswordStrength(value);

    switch (name) {
      // case "firstName":
      //   if (!value) {
      //     error = "First name is required.";
      //   } else if (value.length < 2) {
      //     error = "First name must be at least 2 characters long.";
      //   } else if (value.length > 15) {
      //     error = "First name must be less than 15 characters.";
      //   } else if (!textOnlyRegex.test(value)) {
      //     error = "First name can only contain letters.";
      //   }
      //   break;

      // case "lastName":
      //   if (!value) {
      //     error = "Last name is required.";
      //   } else if (value.length < 2) {
      //     error = "Last name must be at least 2 characters long.";
      //   } else if (value.length > 15) {
      //     error = "Last name must be less than 15 characters.";
      //   } else if (!textOnlyRegex.test(value)) {
      //     error = "Last name can only contain letters.";
      //   }
      //   break;

      // case "organizationsName":
      //   if (!value.trim()) {
      //     error = "Organization name is required.";
      //   } else if (value.trim().length < 2) {
      //     error = "Organization name must be at least 2 characters long.";
      //   } else if (value.length > 50) {
      //     error = "Organization name must be less than 50 characters.";
      //   } else if (!alphanumericRegex.test(value)) {
      //     error =
      //       "Organization name can only contain letters, numbers, and spaces.";
      //   }
      //   break;

      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value) {
          error = "Email is required.";
        } else if (!emailRegex.test(value)) {
          error = "Please enter a valid email address.";
        }
        break;

      case "password":
        if (!value) {
          error = "Password is required.";
        } else if (value.length < 6) {
          error = "Password must be at least 6 characters long.";
        } else if (value.length > 32) {
          error = "Password must be less than 32 characters.";
        } else if (strength === "Weak" || strength === "Medium") {
          error = "Please choose a stronger password.";
        }
        break;

      // case "confirmPassword":
      //   if (!value) {
      //     error = "Please confirm your password.";
      //   } else if (value !== formData.password) {
      //     error = "Passwords do not match.";
      //   }
      //   break;

      default:
        if (!value) {
          error = "This field is required.";
        }
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateForm = () => {
    let isValid = true;
    const fieldsToValidate = ["email", "password"];
    // if (!recaptchaValue) {
    //   isValid = false;
    //   notify("error", { message: "Please fill the Captcha." });
    // }
    fieldsToValidate.forEach((field) => {
      validateField(field, formData[field]);
      if (errors[field]) {
        isValid = false;
      }
    });

    return isValid;
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setPasswordVisible(!passwordVisible);
    } else if (field === "confirmPassword") {
      setConfirmPasswordVisible(!confirmPasswordVisible);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      props.setIsLoading(true);
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      fetch(USER_REGISTRATION, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(formData),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.code === 200) {
            localStorage.setItem("p", JSON.stringify(res.data));
            props.setIsLoading(false);
            navigate("/a/dashboard");
          } else {
            props.setIsLoading(false);
            if (Array.isArray(res.message)) notify("error", res.message[0].Msg);
            else notify("error", res.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          notify(
            "error",
            "Something went wrong try again later or login with credential."
          );
        });
    }
  };

  const hasErrorsOrEmptyFields = () => {
    const isEmpty = Object.values(formData).some(
      (field) => field.trim() === ""
    );
    const hasErrors = Object.values(errors).some((error) => error !== "");
    return isEmpty || hasErrors || passwordStrength !== "Strong";
  };

  // const handleGoogleLogin = () => {
  //   setIsLoading(true);
  //   let headers = new Headers();
  //   headers.append("Content-Type", "application/json");

  //   fetch(`${GOOGLE_LOGIN}`, {
  //     method: "GET",
  //     headers: headers,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res.code === 200) {
  //         window.location.href = res.data;
  //       } else {
  //         notify(
  //           "error",
  //           res.message || "An error occurred during Google login."
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       notify("error", "Failed to connect to the Google login service.");
  //     });
  // };

  return (
    <>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full viewport height
            width: "100vw", // Full viewport width
            backgroundColor: "white", // Blank screen background color
          }}
        >
          <i className="fa fa-spinner fa-spin fa-3x text-black" />
        </div>
      )}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {/* <AuthHeader title="Create Forever Free Account" /> */}

      <>
        {" "}
        {/* <CardHeader className="bg-transparent pb-3 text-center">
          Sign Up with
          <div className="text-muted text-center mt-2 mb-3"></div>
          <div className="btn-wrapper text-center">
            <Button
              className="btn-neutral btn-icon"
              color="default"
              onClick={handleGoogleLogin}
            >
              <span className="btn-inner--icon mr-1">
                <img
                  alt="..."
                  src={require("assets/img/icons/common/google.svg").default}
                />
              </span>
              <span className="btn-inner--text">Google</span>
            </Button>
          </div>
        </CardHeader> */}
        {/* <div className="justify-content-center d-flex mt-2 mb--2">
          Add Account Registration Details
        </div> */}
        {/* <CardBody className="px-lg-5 py-lg-4"> */}
        <Form role="form" onSubmit={handleSubmit}>
          <Row>
            {/* <Col md="12" className="mb-3">
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-circle-08" />
                  </InputGroupText>
                </InputGroupAddon>

                <Input
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  style={styles.input}
                  className={errors.firstName ? "is-invalid" : ""}
                />
              </InputGroup>
              {errors.firstName && (
                <small className="text-danger">{errors.firstName}</small>
              )}
            </Col> */}
            {/* <Col md="12" className="mb-3">
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-circle-08" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className={errors.lastName ? "is-invalid" : ""}
                />
              </InputGroup>
              {errors.lastName && (
                <small className="text-danger">{errors.lastName}</small>
              )}
            </Col> */}

            <Col md="12" className="mb-3">
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className={errors.email ? "is-invalid" : ""}
                />
              </InputGroup>
              {errors.email && (
                <small className="text-danger">{errors.email}</small>
              )}
            </Col>
            {/* <Col md="12" className="mb-3">
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-briefcase-24" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="organizationsName"
                  placeholder="Organization Name"
                  type="text"
                  value={formData.organizationsName}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className={errors.organizationsName ? "is-invalid" : ""}
                />
              </InputGroup>
              {errors.organizationsName && (
                <small className="text-danger">
                  {errors.organizationsName}
                </small>
              )}
            </Col> */}

            <Col md="12">
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-lock" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="password"
                  placeholder="New Password"
                  type={passwordVisible ? "text" : "password"}
                  value={formData.password}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className={errors.password ? "is-invalid" : ""}
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i
                      className={`fa ${
                        passwordVisible ? "fa-eye-slash" : "fa-eye"
                      }`}
                      aria-hidden="true"
                      onClick={() => togglePasswordVisibility("password")}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {errors.password && (
                <small className="text-danger">{errors.password}</small>
              )}
              <div className="text-muted">
                <small>
                  Password strength:{" "}
                  <span className={getPasswordStrengthColor()}>
                    {passwordStrength}
                  </span>
                </small>
              </div>
            </Col>
            <ReCAPTCHA
              sitekey={SITE_KEY}
              onChange={onRecaptchaChange}
              className="ml-3 mt-2"
            />
          </Row>

          <div className="text-center">
            <Button
              className="mt-4"
              color="info"
              type="submit"
              disabled={hasErrorsOrEmptyFields()}
            >
              Create account
            </Button>
          </div>
          <Row>
            <div className="ml-3 mt-2" style={styles.terms}>
              <label>
                <span>
                  {" "}
                  By clicking the "Create Account" button, you agree to our{" "}
                  <a
                    className="text-blue ml-1"
                    href="https://www.bulkcalendar.com/terms-of-service/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>{" "}
                  and
                </span>
                <a
                  className="text-blue ml-1"
                  href="https://www.bulkcalendar.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy.
                </a>
              </label>
            </div>
          </Row>
        </Form>
        {/* </CardBody> */}
      </>
    </>
  );
}

export default RegistrationDetails;
const styles = {
  terms: { fontSize: "12px" },
};
