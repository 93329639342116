import { Card, CardBody, CardHeader, Container } from "reactstrap";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUnsubscriberList } from "action/unsubscibe/unsubscribe";
import CustomPagination from "components/Common/Pagination";
import { cyanBlue } from "components/Common/constants";

function UnsubscribeList() {
  const [showListAlternative, setShowListAlternative] = useState({
    loader: true,
    noRecordsFound: false,
  });
  const [pageNum, setPageNum] = useState(1);
  const unsubscriberList = useSelector(
    (state) => state.unsubscribe.unsubscriberList
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getUnsubscriberList(dispatch, pageNum);
  }, [pageNum]);

  useEffect(() => {
    if (
      unsubscriberList.UnsubscriberList &&
      unsubscriberList.UnsubscriberList.length > 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
    } else if (
      unsubscriberList.UnsubscriberList &&
      unsubscriberList.UnsubscriberList.length === 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: true,
      });
    }
  }, [unsubscriberList]);

  return (
    <>
      {/* <SimpleHeader name="Camping" /> */}
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className="mb-0">Unsubscribers</h3>
            <h3 style={styles.noteTextStyle}>
              <span>Note:</span> The following is a list of users who have
              unsubscribed from your organization's emails.
            </h3>
          </CardHeader>
          <CardBody>
            {showListAlternative.loader ? (
              <div style={styles.loaderContainer}>
                <i
                  className="fa fa-spinner fa-spin fa-2xl"
                  style={styles.loaderColor}
                />
              </div>
            ) : showListAlternative.noRecordsFound ? (
              <div style={styles.loaderContainer}>No Records Found</div>
            ) : (
              <ToolkitProvider
                data={unsubscriberList.UnsubscriberList}
                keyField="email"
                columns={[
                  {
                    dataField: "email",
                    text: "Email Address",
                  },
                  {
                    dataField: "reason",
                    text: "Reason of Unsubscribing",
                    formatter: (cell) =>
                      cell == "" ? <span>-</span> : <span>{cell}</span>,
                  },
                  {
                    dataField: "createdAt",
                    text: "Unsubscribed Date",
                  },
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                    />
                    <div>
                      <CustomPagination
                        totalPages={Math.ceil(
                          unsubscriberList.UnsubscriberList.totalRecords /
                            unsubscriberList.UnsubscriberList.noOfRecordsPerPage
                        )}
                        setPageNum={setPageNum}
                        activePage={pageNum}
                      />
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default UnsubscribeList;

const styles = {
  noteTextStyle: {
    marginBottom: "-15px",
    paddingTop: "20px",
    width: "100%",
    fontSize: "0.875rem",
    color: "#6c757d",
  },
  cusrorPointer: { cursor: "pointer" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  loaderColor: { color: cyanBlue },
  deleteLoader: {
    backgroundColor: "white",
    display: "flex",
    padding: "2rem",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "2rem",
  },
  title: {
    cursor: "pointer",
  },
};
