import { Col, Modal, ModalBody, UncontrolledTooltip } from "reactstrap";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";
import { useEffect, useState } from "react";
import { cyanBlue } from "../../../components/Common/constants";
import { useDispatch, useSelector } from "react-redux";
import { listEmailConfig } from "action/Settings/emailConfiguration";
import CustomPagination from "components/Common/Pagination";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { deleteEmailConfigu } from "action/Settings/emailConfiguration";
import EmailConfigDetails from "./EmailConfigDetails";
import { useNavigate } from "react-router-dom";

function ConfigTable({ newRecordAdded, setNewRecordedAdded }) {
  const [showListAlternative, setShowListAlternative] = useState({
    loader: true,
    noRecordsFound: false,
  });
  const [pageNum, setPageNum] = useState(1);
  const [alert, setAlert] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState({
    show: false,
    ID: 0,
  });
  const emailConfigurationList = useSelector(
    (state) => state.EmailConfigReducers.emailConfigList
  );
  const toggleDetailsModal = () => setShowDetailsModal(!showDetailsModal.show);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    listEmailConfig(dispatch, pageNum);
  }, [pageNum]);

  useEffect(() => {
    if (newRecordAdded) {
      setShowListAlternative({
        loader: true,
        noRecordsFound: false,
      });
      if (pageNum === 1) {
        listEmailConfig(dispatch, 1);
      } else {
        setPageNum(1);
      }
      setNewRecordedAdded(false);
    }
  }, [newRecordAdded]);

  useEffect(() => {
    if (
      emailConfigurationList.EmailConfigurations &&
      emailConfigurationList.EmailConfigurations.length > 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
    } else if (
      emailConfigurationList.EmailConfigurations &&
      emailConfigurationList.EmailConfigurations.length === 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: true,
      });
    }
  }, [emailConfigurationList]);

  const hideAlert = () => {
    setAlert(null);
  };

  const confirmDeleteEmailConfig = async (ID) => {
    const res = await deleteEmailConfigu(ID);
    if (res.code === 200) {
      successDelete();
      if (
        emailConfigurationList.EmailConfigurations.length === 1 &&
        pageNum !== 1
      ) {
        const currPage = pageNum - 1;
        setPageNum(currPage);
      } else {
        listEmailConfig(dispatch, pageNum);
      }
    } else {
      notDeleted(res.msg);
    }
  };

  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        title="Deleted!"
        onConfirm={() => hideAlert()}
        showConfirm={false}
      >
        Record Deleted successfully.
      </ReactBSAlert>
    );
    setTimeout(hideAlert, 1500);
  };

  const notDeleted = (msg) => {
    setAlert(
      <ReactBSAlert
        danger
        title="Error"
        onConfirm={() => hideAlert()}
        showConfirm={false}
      >
        Not Deleted.
        {msg ? msg : null}
      </ReactBSAlert>
    );
    setTimeout(hideAlert, 1500);
  };

  const deleteWarning = (ID) => {
    setAlert(
      <ReactBSAlert
        info
        title={
          <span className="form-control-label" style={{ fontSize: "1.8rem" }}>
            Are you sure?
          </span>
        }
        onConfirm={() => {
          showDeleteLoader();
          confirmDeleteEmailConfig(ID);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="secondary"
        confirmBtnText="Delete"
        cancelBtnText="Cancel"
        showCancel
        btnSize="md"
      >
        You want to delete this record?
      </ReactBSAlert>
    );
  };

  const showDeleteLoader = () => {
    setAlert(
      <Modal isOpen={true} centered size="sm">
        <ModalBody>
          <div style={styles.deleteLoader}>
            <i
              className="fa fa-spinner fa-spin fa-2xl"
              style={{ color: cyanBlue }}
            />
            <h1>Deleting...</h1>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  //on click of view details
  const handleConfigDetails = (ID) => {
    setShowDetailsModal({ show: true, ID: ID });
  };

  const handleConfigEdit = (ID) => {
    navigate("/a/email/edit", { state: { configID: ID } });
  };

  return (
    <>
      {alert}
      <EmailConfigDetails
        showDetailsModal={showDetailsModal.show}
        toggleDetailsModal={toggleDetailsModal}
        emailConfigID={showDetailsModal.ID}
      />
      <h3 className="mb-0">Email Configurations</h3>
      {showListAlternative.loader ? (
        <div style={styles.loaderContainer}>
          <i
            className="fa fa-spinner fa-spin fa-2xl"
            style={styles.loaderColor}
          />
        </div>
      ) : showListAlternative.noRecordsFound ? (
        <div style={styles.loaderContainer}>No Records Found</div>
      ) : (
        <>
          <ToolkitProvider
            data={emailConfigurationList?.EmailConfigurations}
            keyField="ID"
            columns={[
              {
                dataField: "MailingService",
                text: "Mailing Service",
              },
              {
                dataField: "Title",
                text: "Title",
                formatter: (cell, row) => (
                  <div
                    className="clickable-text"
                    style={styles.title}
                    onClick={() => handleConfigDetails(row.ID)}
                  >
                    {row.Title}
                  </div>
                ),
              },
              {
                dataField: "CreatedBy",
                text: "Added By",
              },
              {
                dataField: "CreatedOn",
                text: "Created On",
              },
              {
                dataField: "Action",
                text: "Action",
                formatter: (cell, row) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                    }}
                  >
                    {row.ConfigType !== "FREE" ? (
                      <>
                        <div>
                          <a
                            className="table-action"
                            id={`tooltipConfigDetails${row.ID}`}
                            onClick={() => handleConfigDetails(row.ID)}
                            style={styles.cusrorPointer}
                          >
                            <i className="fas fa-info-circle" />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target={`tooltipConfigDetails${row.ID}`}
                          >
                            View Details
                          </UncontrolledTooltip>
                          {row.ConfigType !== "OAUTH_GOOGLE" ? (
                            <>
                              <a
                                className="table-action"
                                id={`tooltipConfigEdit${row.ID}`}
                                onClick={() => handleConfigEdit(row.ID)}
                                style={styles.cusrorPointer}
                              >
                                <i className="fas fa-edit" />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltipConfigEdit${row.ID}`}
                              >
                                Edit Configuration
                              </UncontrolledTooltip>
                            </>
                          ) : (
                            <div style={{ marginLeft: "45px" }} />
                          )}
                        </div>

                        <div className="text-gray">|</div>
                        <a
                          className="table-action table-action-delete"
                          id={`tooltipConfigDel${row.ID}`}
                          onClick={(e) => deleteWarning(row.ID)}
                          style={styles.cusrorPointer}
                        >
                          <i className="fas fa-trash" />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target={`tooltipConfigDel${row.ID}`}
                        >
                          Delete Configuration
                        </UncontrolledTooltip>
                      </>
                    ) : null}
                  </div>
                ),
              },
            ]}
            search
          >
            {(props) => (
              <div className="py-4 table-responsive">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                />
                <div>
                  <CustomPagination
                    totalPages={Math.ceil(
                      emailConfigurationList.totalRecords /
                        emailConfigurationList.noOfRecordsPerPage
                    )}
                    setPageNum={setPageNum}
                    activePage={pageNum}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </>
      )}
    </>
  );
}

export default ConfigTable;

const styles = {
  cusrorPointer: { cursor: "pointer" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  loaderColor: { color: cyanBlue },
  deleteLoader: {
    backgroundColor: "white",
    display: "flex",
    padding: "2rem",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "2rem",
  },
  title: {
    cursor: "pointer",
  },
};
