import {
  Card,
  CardBody,
  CardHeader,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { paymentHistory } from "action/Billing/plans";
import { cyanBlue } from "components/Common/constants";
import CustomPagination from "components/Common/Pagination";

function PaymentHistory() {
  const dispatch = useDispatch();
  const [pageNum, setPageNum] = React.useState(1);
  const paymentHistoryList = useSelector(
    (state) => state.Plans.paymentHistoryList
  );

  React.useEffect(() => {
    paymentHistory(dispatch, pageNum);
  }, [dispatch, pageNum]);
  return (
    <>
      {/* <SimpleHeader name="Camping" /> */}
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className="mb-0">Payment History</h3>
          </CardHeader>
          <CardBody>
            {paymentHistoryList.listPaymentHistory?.length === 0 ? (
              <div style={styles.loaderContainer}>No Records Found</div>
            ) : (
              <ToolkitProvider
                data={paymentHistoryList.listPaymentHistory || []}
                keyField="downloadedId"
                columns={[
                  {
                    dataField: "planName",
                    text: "Plan",
                    align: "center",
                    headerAlign: "center",
                    sort: true,
                  },
                  {
                    dataField: "createdAt",
                    text: "Date",
                    align: "center",
                    headerAlign: "center",
                    sort: true,
                  },
                  {
                    dataField: "amount",
                    text: "Paid Amount",
                    align: "right",
                    headerAlign: "right",
                    sort: true,
                    formatter: (cell) => {
                      return `$${cell}`;
                    },
                  },
                  {
                    dataField: "allocatedCredits",
                    text: "Credits",
                    align: "right",
                    headerAlign: "right",
                    sort: true,
                  },
                  {
                    dataField: "requestedBy",
                    text: "Download Invoice",
                    align: "center",
                    headerAlign: "center",
                    sort: true,
                    formatter: (cell, row, index) => (
                      <div key={row.ID}>
                        <UncontrolledTooltip target={`tooltipEdit${index}`}>
                          Download Invoice
                        </UncontrolledTooltip>

                        <a
                          id={`tooltipEdit${index}`}
                          href={row.pdflink}
                          target="_blank"
                        >
                          <i className="fa fa-download text-lg text-info"></i>
                        </a>
                      </div>
                    ),
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                    />
                    <div>
                      <CustomPagination
                        totalPages={Math.ceil(
                          paymentHistoryList.totalRecords /
                            paymentHistoryList.noOfRecordsPerPage
                        )}
                        setPageNum={setPageNum}
                        activePage={pageNum}
                      />
                    </div>
                  </div>
                )}
              </ToolkitProvider>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default PaymentHistory;
const styles = {
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
};
