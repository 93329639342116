import { campaignDetails } from "constants/CampaignConstants/createCampaign";
import { attendeeList } from "constants/CampaignConstants/createCampaign";

const initialState = {
  attendeeList: {},
  campaignDetails: {},
};

const createCampaign = (state = initialState, action) => {
  switch (action.type) {
    case attendeeList:
      return { ...state, attendeeList: action.payload };

    case campaignDetails:
      return { ...state, campaignDetails: action.payload };

    default:
      return state;
  }
};

export default createCampaign;
