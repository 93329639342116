import { Modal } from "reactstrap";

function InstructionModal(props) {
  return (
    <Modal
      isOpen={props.show}
      toggle={() => props.handleToggle()}
      style={styles.videoContainer}
      className="d-flex justify-content-center bg-info"
    >
      <iframe
        width="1200px"
        height="700"
        src={props.youtubeEmbededURL}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </Modal>
  );
}

export default InstructionModal;

const styles = {
  videoContainer: {
    width: "70%",
    maxWidth: "1200px",
    height: "80vh",
    maxHeight: "800px",
  },
};
