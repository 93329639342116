import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Table,
  Modal,
  ModalBody,
  Input,
  ModalHeader,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { columnsList } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { CREATE_CAMPAIGN_STEP2 } from "API/apiConstants";
import { getAttendeeList } from "action/campaign/createCampaign";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "components/Common/Pagination";
import { default as Select, components } from "react-select";
import {
  inputBorderColor,
  inputTextColor,
  cyanBlue,
} from "components/Common/constants";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  updateAttendee,
  bulkUpdateAttendee,
  addAttendee,
  sendTestInviteEmail,
} from "action/campaign/createCampaign";
import { selectedColor } from "components/Common/constants";
import { formatDateWithTime } from "components/CommonFunctions";
import {
  clearAttendeeListFrmStore,
  getCampaignDetails,
} from "action/campaign/createCampaign";
import ErrorDisplay from "./ErrorsDisplay";
import AddOrEditAttendeeModal from "../AddOrEditAttendeeModal";
import { BulkSendCalendarInviteModal } from "../CampaignDetails/BulkSendModal";
import InviteSendingProgress from "../CampaignDetails/InviteSendingProgress";

function CreateCampaignStep2() {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  //states for import file
  const [selectedCsv, setSelectedCsv] = useState(null);
  const [updateExisting, setUpdateExisting] = useState("NO");
  const [showLoaderOnClick, setShowLoaderOnClick] = useState(false);
  //states for the modal
  const [modalState, setModalState] = useState({
    show: false,
    mode: "add",
    emailSendFields: {
      firstName: { value: "", isValid: true },
      email: { value: "", isValid: true },
      lastName: { value: "", isValid: true },
      field1: { value: "", isValid: true },
      field2: { value: "", isValid: true },
      field3: { value: "", isValid: true },
      field4: { value: "", isValid: true },
      field5: { value: "", isValid: true },
    },
  });
  //states for attendee list
  const [campaignCode, setCampaignCode] = useState(null);
  const [showListAlternative, setShowListAlternative] = useState({
    loader: true,
    noRecordsFound: false,
  });
  const [pageNum, setPageNum] = useState(1);
  const [selectedMoreCols, setSelectedMoreCols] = useState([]);
  const [filter, setFilter] = useState({
    search: "",
    status: null,
  });
  const [filterParams, setFilterParams] = useState("");
  const [attendeeListRecords, setAttendeeListRecords] = useState([]);
  const [alert, setAlert] = useState(null);
  const [errors, setErrors] = useState({ data: [], message: "" });
  const [showLoaderOnSearch, setShowLoaderOnSearch] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "DESC",
    sortParam: "",
  });

  const notificationAlertRef = React.useRef(null);

  const campaignDetails = useSelector(
    (state) => state.createCampaign.campaignDetails
  );
  const attendeeList = useSelector(
    (state) => state.createCampaign.attendeeList
  );

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state?.campaignCode) {
      setCampaignCode(location.state?.campaignCode);
    } else {
      // notify("error", {
      //   message: "Please complete create campaign step 1 first.",
      //   subMessage: "",
      // });
      //when any one directly try to navigate to the step 2
      navigate("/a/campaigns/step-1");
    }
    return () => {
      clearAttendeeListFrmStore(dispatch);
    };
  }, []);

  useEffect(() => {
    if (campaignCode) {
      getCampaignDetails(dispatch, campaignCode);
    }
  }, [campaignCode]);

  //call to get attendee list
  useEffect(() => {
    //get attendee list based on selected
    if (campaignCode && pageNum) {
      getAttendeeList(
        dispatch,
        campaignCode,
        pageNum,
        filterParams,
        sortConfig.sortParam
      );
    }
  }, [pageNum, campaignCode, filterParams, sortConfig.sortParam]);

  //setting the state of attendee list or in other case showing loader or not found msg
  useEffect(() => {
    if (
      attendeeList.CampaignAttendee &&
      attendeeList.CampaignAttendee.length > 0
    ) {
      const attendeeListWithCheckbox = attendeeList.CampaignAttendee.map(
        (item) => {
          return {
            ...item,
            checked: false,
          };
        }
      );
      setAttendeeListRecords(attendeeListWithCheckbox);
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
      setShowLoaderOnSearch(false);
    } else if (
      attendeeList.CampaignAttendee &&
      attendeeList.CampaignAttendee.length === 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: true,
      });
      setAttendeeListRecords([]);
      setShowLoaderOnSearch(false);
    }
  }, [attendeeList]);

  //on change of the fields of the modal fields
  // const handleChangeSendEmail = (name, value) => {
  //   setModalState((prevState) => {
  //     return {
  //       ...prevState,
  //       emailSendFields: {
  //         ...prevState.emailSendFields,
  //         [name]: { value: value, isValid: true },
  //       },
  //     };
  //   });
  // };

  //validation of the modal fields
  // const onBlurSendEmail = (name, value) => {
  //   if (name === "email" && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
  //     setModalState((prevState) => {
  //       return {
  //         ...prevState,
  //         emailSendFields: {
  //           ...prevState.emailSendFields,
  //           [name]: { ...prevState.emailSendFields[name], isValid: false },
  //         },
  //       };
  //     });
  //   }
  //   // else if (value.trim().length === 0) {
  //   //   setModalState((prevState) => {
  //   //     return {
  //   //       ...prevState,
  //   //       emailSendFields: {
  //   //         ...prevState.emailSendFields,
  //   //         [name]: { ...prevState.emailSendFields[name], isValid: false },
  //   //       },
  //   //     };
  //   //   });
  //   // }
  //   else if (
  //     (name === "firstName" || name === "lastName") &&
  //     !/^[a-zA-Z ]*$/.test(value)
  //   ) {
  //     setModalState((prevState) => {
  //       return {
  //         ...prevState,
  //         emailSendFields: {
  //           ...prevState.emailSendFields,
  //           [name]: { ...prevState.emailSendFields[name], isValid: false },
  //         },
  //       };
  //     });
  //   } else {
  //     setModalState((prevState) => {
  //       return {
  //         ...prevState,
  //         emailSendFields: {
  //           ...prevState.emailSendFields,
  //           [name]: { ...prevState.emailSendFields[name], value: value.trim() },
  //         },
  //       };
  //     });
  //   }
  // };

  //on click of download file
  const handleDownload = () => {
    // Path to your CSV file
    const filePath = require("../../../assets/img/Misc/import attendee sample.csv");

    // Create a link element
    const link = document.createElement("a");
    link.href = filePath;

    // Set the download attribute to suggest a filename
    link.download = "sample.csv";

    // Append the link to the body (necessary for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up the link by removing it from the DOM
    document.body.removeChild(link);
  };

  //when to manage the more add cols in attendee table
  const handleChangeMoreColumns = (selected, e) => {
    setSelectedMoreCols(selected);
  };

  //notification component
  const notify = (type, msgObj) => {
    if (isNotificationOpen && !msgObj.showForceFully) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {msgObj.message}
          </span>
          <span data-notify="message">{msgObj.subMessage}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  //Import file upload
  const handleCreateCampaignStep2 = async () => {
    let headers = new Headers();
    const p = JSON.parse(localStorage.getItem("p"));
    headers.append("token", p.token);
    //  headers.append("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("file", selectedCsv);
    formData.append("updateRecords", updateExisting);
    await fetch(`${CREATE_CAMPAIGN_STEP2}${campaignCode}`, {
      method: "POST",
      headers: headers,
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          notify("success", {
            message: "Attendee Imported Sucessfully!",
            subMessage: "",
          });
          setSelectedCsv(null);
          if (pageNum === 1) {
            getAttendeeList(dispatch, campaignCode, 1, filterParams);
            getCampaignDetails(dispatch, campaignCode);
          } else {
            setPageNum(1);
          }
        } else if (res.code === 400) {
          setErrors({ data: res.data, message: res.message });
        } else {
          notify("error", {
            message: res.message ? res.message : "Something went wrong",
            subMessage: "Please check the CSV file.",
          });
        }
        setShowLoaderOnClick(false);
      });
  };

  //on select of file
  const handleSelectCsv = (e) => {
    if (e.target.files && e.target.files[0]) {
      // Convert file size to MB
      const fileSizeInMB = e.target.files[0].size / (1024 * 1024);
      // Check if file size exceeds 50MB
      if (fileSizeInMB > 50) {
        notify("error", {
          message: "File with a maximum size of 50MB is allowed",
          subMessage: "",
        });
        // alert(
        //   "File with a maximum size of 50MB is allowed"
        // );
        setSelectedCsv(null);
      } else {
        setSelectedCsv(e.target.files[0]);
        //remove error
        setErrors({ data: [], message: "" });
      }
    }
    // Reset the input value to allow selecting the same file again
    e.target.value = "";
  };

  //for customizing the appearance of the React Select to match other input fields
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.selectProps.className?.includes("is-invalid")
        ? "red"
        : inputBorderColor,
      height: "2.8rem",
      fontSize: "0.85rem",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: inputTextColor,
    }),
    menuList: (provided) => ({
      ...provided,
      fontSize: "0.8rem",
    }),
  };

  //the multi select dropdown UI
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  //on change of filter in attendee list
  const handleFilterChange = (name, value) => {
    setFilter((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  //to calulate the serial number based on the page Num
  const calculateSerialNum = (index) => {
    // Calculate the serial number based on the current page number
    const serialNum =
      (pageNum - 1) * attendeeList.noOfRecordsPerPage + index + 1;

    return serialNum;
  };

  //on check and uncheck the attendee list rows
  const handleCheckAttendeeList = (index, flagForAll) => {
    if (index === -1) {
      const attendeeListWithCheckbox = attendeeListRecords.map((item) => {
        return {
          ...item,
          checked: !flagForAll,
        };
      });
      setAttendeeListRecords(attendeeListWithCheckbox);
    } else {
      const newAttendeeList = [...attendeeListRecords];
      newAttendeeList[index] = {
        ...newAttendeeList[index],
        checked: !newAttendeeList[index].checked,
      };
      setAttendeeListRecords(newAttendeeList);
    }
  };

  //for success alert
  const successAlert = (action) => {
    setAlert(
      <ReactBSAlert
        success
        title={`${action}!`}
        onConfirm={() => hideAlert()}
        showConfirm={false}
      >
        Record {action} successfully.
      </ReactBSAlert>
    );
    setTimeout(hideAlert, 1500);
  };

  //for error alert
  const errorAlert = (action, msg) => {
    setAlert(
      <ReactBSAlert
        danger
        title="Error"
        onConfirm={() => hideAlert()}
        showConfirm={false}
      >
        Not {action}.{msg ? msg : null}
      </ReactBSAlert>
    );
    setTimeout(hideAlert, 1500);
  };

  //to hide the alert
  const hideAlert = () => {
    setAlert(null);
  };

  //delete api for single as well as bulk delete
  const deleteAttendee = async (ID) => {
    const deleteAttendeeID = ID
      ? [ID]
      : attendeeListRecords
          .filter((item) => item.checked)
          .map((item) => {
            return item.ID;
          });
    const res = await bulkUpdateAttendee(campaignCode, {
      status: "DELETED",
      campaignAttendeeID: deleteAttendeeID,
    });
    if (res.code === 200) {
      successAlert("Deleted");
      getAttendeeList(dispatch, campaignCode, 1, filterParams);
      getCampaignDetails(dispatch, campaignCode);
    } else {
      if (Array.isArray(res.message)) {
        errorAlert("Deleted", res.message[0].Msg);
      } else {
        errorAlert("Deleted", res.message);
      }
    }
  };

  //api call to bulk update status
  const bulkUpdateStatus = async (status) => {
    const attendeeID = attendeeListRecords
      .filter((item) => item.checked)
      .map((item) => {
        return item.ID;
      });
    const res = await bulkUpdateAttendee(campaignCode, {
      status: status,
      campaignAttendeeID: attendeeID,
    });
    if (res.code === 200) {
      successAlert("Updated");
      getAttendeeList(dispatch, campaignCode, 1, filterParams);
      getCampaignDetails(dispatch, campaignCode);
    } else {
      if (Array.isArray(res.message)) {
        errorAlert("Updated", res.message[0].Msg);
      } else {
        errorAlert("Updated", res.message);
      }
    }
  };

  //to show loader while the api call is in progress
  const showModalLoader = (action) => {
    setAlert(
      <Modal isOpen={true} centered size="sm">
        <ModalBody>
          <div style={styles.deleteLoader}>
            <i
              className="fa fa-spinner fa-spin fa-2xl"
              style={{ color: cyanBlue }}
            />
            <h1>{action}...</h1>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  //delete warning
  const deleteWarning = (ID) => {
    setAlert(
      <ReactBSAlert
        info
        title={
          <span className="form-control-label" style={{ fontSize: "1.8rem" }}>
            Are you sure?
          </span>
        }
        onConfirm={() => {
          showModalLoader("Deleting");
          deleteAttendee(ID);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="secondary"
        confirmBtnText="Delete"
        cancelBtnText="Cancel"
        showCancel
        btnSize="md"
      >
        {ID
          ? "You want to delete this record?"
          : `You want to delete selected ${
              attendeeListRecords.filter((item) => item.checked).length
            } records?`}
      </ReactBSAlert>
    );
  };

  //status change warning
  const bulkStatusChange = (status) => {
    setAlert(
      <ReactBSAlert
        info
        title={
          <span className="form-control-label" style={{ fontSize: "1.8rem" }}>
            Are you sure?
          </span>
        }
        onConfirm={() => {
          showModalLoader("updating");
          bulkUpdateStatus(status);
        }}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="secondary"
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        showCancel
        btnSize="md"
      >
        {`You want to change the status of the selected ${
          attendeeListRecords.filter((item) => item.checked).length
        } records to ${status === "DONT_SEND" ? "Don't Send" : "Unsent"}?`}
      </ReactBSAlert>
    );
  };

  //to check if any field changed
  // const checkIfAnyFieldChanged = () => {
  //   const oldAttendeeFields = attendeeListRecords.find(
  //     (item) => item.ID === modalState.emailSendFields.ID
  //   ); // get the old attendee fields by it ID

  //   for (const key in modalState.emailSendFields) {
  //     if (
  //       typeof modalState.emailSendFields[key] === "object" &&
  //       modalState.emailSendFields[key]?.value.trim() !== oldAttendeeFields[key]
  //     ) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  // const getChangedBodyData = () => {
  //   const oldAttendeeFields = attendeeListRecords.find(
  //     (item) => item.ID === modalState.emailSendFields.ID
  //   ); // get the old attendee fields by it ID
  //   let changedFields = { ID: modalState.emailSendFields.ID };
  //   for (const key in modalState.emailSendFields) {
  //     if (
  //       typeof modalState.emailSendFields[key] === "object" &&
  //       modalState.emailSendFields[key]?.value.trim() !== oldAttendeeFields[key]
  //     ) {
  //       //If input is empty then addes 1 space to it so that value will update as blank
  //       let changeValue =
  //         modalState.emailSendFields[key].value !== ""
  //           ? modalState.emailSendFields[key].value
  //           : " ";
  //       changedFields = {
  //         ...changedFields,
  //         [key]: changeValue,
  //       };
  //     }
  //   }
  //   return changedFields;
  // };

  //toggle the modal state of attendee modal
  const toggleModalState = (mode, item) => {
    let attendeeData = {
      firstName: { value: "", isValid: true },
      email: { value: "", isValid: true },
      lastName: { value: "", isValid: true },
      field1: { value: "", isValid: true },
      field2: { value: "", isValid: true },
      field3: { value: "", isValid: true },
      field4: { value: "", isValid: true },
      field5: { value: "", isValid: true },
    };
    if (item) {
      attendeeData = {
        ID: item.ID,
        firstName: {
          value: item.firstName === " " ? "" : item.firstName.trim(),
          isValid: true,
        },
        email: { value: item.email, isValid: true },
        lastName: {
          value: item.lastName === " " ? "" : item.lastName.trim(),
          isValid: true,
        },
        field1: {
          value: item.field1 === " " ? "" : item.field1.trim(),
          isValid: true,
        },
        field2: {
          value: item.field2 === " " ? "" : item.field2.trim(),
          isValid: true,
        },
        field3: {
          value: item.field3 === " " ? "" : item.field3.trim(),
          isValid: true,
        },
        field4: {
          value: item.field4 === " " ? "" : item.field4.trim(),
          isValid: true,
        },
        field5: {
          value: item.field5 === " " ? "" : item.field5.trim(),
          isValid: true,
        },
      };
    }
    setModalState({
      show: !modalState.show,
      mode: mode,
      emailSendFields: attendeeData,
    });
  };

  //on click of update for attendee
  // const handleAttendeeUpdate = async () => {
  //   const bodyData = getChangedBodyData();
  //   const res = await updateAttendee(campaignCode, bodyData);
  //   if (res.code === 200) {
  //     notify("success", {
  //       message: "Attendee record updated successfully",
  //       subMessage: "",
  //     });
  //     getAttendeeList(dispatch, campaignCode, 1);
  //     toggleModalState(modalState.mode);
  //   } else {
  //     notify("error", {
  //       message: "Attendee record updated failed!",
  //       subMessage: "Please check the details you entered",
  //     });
  //   }
  // };

  //on click of add for attendee
  // const handleAttendeeAdd = async () => {
  //   const bodyData = {
  //     email: modalState.emailSendFields.email.value,
  //     firstName: modalState.emailSendFields.firstName.value,
  //     lastName: modalState.emailSendFields.lastName.value,
  //     field1: modalState.emailSendFields.field1.value,
  //     field2: modalState.emailSendFields.field2.value,
  //     field3: modalState.emailSendFields.field3.value,
  //     field4: modalState.emailSendFields.field4.value,
  //     field5: modalState.emailSendFields.field5.value,
  //   };
  //   const res = await addAttendee(campaignCode, bodyData);
  //   if (res.code === 200) {
  //     notify("success", {
  //       message: "Attendee record added successfully",
  //       subMessage: "",
  //     });
  //     getAttendeeList(dispatch, campaignCode, 1);
  //     toggleModalState(modalState.mode);
  //   } else {
  //     notify("error", {
  //       message: "Attendee record creation failed!",
  //       subMessage: "Please check the details you entered",
  //     });
  //   }
  // };

  //send test calendar invite
  // const handleSendTestEmail = async () => {
  //   const bodyData = {
  //     email: modalState.emailSendFields.email.value,
  //     firstName: modalState.emailSendFields.firstName.value,
  //     lastName: modalState.emailSendFields.lastName.value,
  //     field1: modalState.emailSendFields.field1.value,
  //     field2: modalState.emailSendFields.field2.value,
  //     field3: modalState.emailSendFields.field3.value,
  //     field4: modalState.emailSendFields.field4.value,
  //     field5: modalState.emailSendFields.field5.value,
  //   };
  //   const res = await sendTestInviteEmail(campaignCode, bodyData);
  //   if (res.code === 200) {
  //     notify("success", {
  //       message: "Test Email Sent Successfully",
  //       subMessage: "",
  //     });
  //     getAttendeeList(dispatch, campaignCode, 1);
  //     toggleModalState(modalState.mode);
  //   } else {
  //     notify("error", {
  //       message: "Email Sent failed!",
  //       subMessage: "Please check the details you entered",
  //     });
  //   }
  // };

  const handleSearch = () => {
    setShowLoaderOnSearch(true);
    let querryParmFilter = "";
    //make filters query params
    if (filter.search.trim().length > 0) {
      querryParmFilter = "&";
      querryParmFilter += `searchString=${filter.search.toString()}`;
    }
    if (filter.status !== null && filter.status.value !== "") {
      querryParmFilter += `&filtersJSON=[{"field": "Status", "condition": "eq", "filterValues": [${JSON.stringify(
        filter.status.value
      )}]}]`;
    }
    setFilterParams(querryParmFilter);
  };

  const handleClear = () => {
    setFilterParams("");
    setFilter({
      search: "",
      status: null,
    });
  };

  //on click of send test calendar invite button
  const sendTestCalendarInvite = () => {
    if (attendeeListRecords.filter((item) => item.checked).length === 1) {
      toggleModalState(
        "test",
        attendeeListRecords.filter((item) => item.checked)[0]
      );
    } else {
      toggleModalState("test");
    }
  };

  //for handling sor t by col and its direction
  const handleSort = (colname) => {
    let newDirection = sortConfig.direction === "ASC" ? "DESC" : "ASC";
    setSortConfig({
      key: colname,
      direction: newDirection,
      sortParam: `&sortOptionJSON={"SortBy":"${colname}","SortType":"${newDirection}"}`,
    });
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {alert}
      {/* <Modal isOpen={modalState.show} size="lg">
        <ModalHeader
          toggle={() => {
            toggleModalState(modalState.mode);
          }}
        >
          <h3 type="text">
            {modalState.mode === "test"
              ? "Send Test Calendar Invite"
              : modalState.mode === "edit"
              ? "Edit Attendee Record"
              : "Add Attendee"}
          </h3>
        </ModalHeader>
        <ModalBody className="pt-0">
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label">
                  Attendee Email
                  <strong style={{ color: "red" }}> *</strong>
                </label>
                <Input
                  placeholder="Attendee Email"
                  type="text"
                  name="email"
                  maxLength={60}
                  value={modalState.emailSendFields.email.value}
                  onChange={(e) =>
                    handleChangeSendEmail(e.target.name, e.target.value)
                  }
                  onBlur={(e) => onBlurSendEmail(e.target.name, e.target.value)}
                  className={
                    modalState.emailSendFields.email.isValid ? "" : "is-invalid"
                  }
                  autoComplete="off"
                />
                {!modalState.emailSendFields.email.isValid && (
                  <div className="invalid-feedback">
                    Please enter a valid email.
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label">
                  First Name
                  <strong style={{ color: "red" }}> *</strong>
                </label>
                <Input
                  placeholder="First Name"
                  type="text"
                  name="firstName"
                  maxLength={60}
                  value={modalState.emailSendFields.firstName.value}
                  onChange={(e) =>
                    handleChangeSendEmail(e.target.name, e.target.value)
                  }
                  onBlur={(e) => onBlurSendEmail(e.target.name, e.target.value)}
                  className={
                    modalState.emailSendFields.firstName.isValid
                      ? ""
                      : "is-invalid"
                  }
                  autoComplete="off"
                />
                {!modalState.emailSendFields.firstName.isValid && (
                  <div className="invalid-feedback">
                    Please enter first name.
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label">
                  Last Name
                  <strong style={{ color: "red" }}> *</strong>
                </label>
                <Input
                  placeholder="Last Name"
                  type="text"
                  name="lastName"
                  value={modalState.emailSendFields.lastName.value}
                  onChange={(e) =>
                    handleChangeSendEmail(e.target.name, e.target.value)
                  }
                  onBlur={(e) => onBlurSendEmail(e.target.name, e.target.value)}
                  className={
                    modalState.emailSendFields.lastName.isValid
                      ? ""
                      : "is-invalid"
                  }
                  autoComplete="off"
                />
                {!modalState.emailSendFields.lastName.isValid && (
                  <div className="invalid-feedback">
                    Please enter last name.
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label">Field1</label>
                <Input
                  placeholder="Field1"
                  type="text"
                  name="field1"
                  onChange={(e) =>
                    handleChangeSendEmail(e.target.name, e.target.value)
                  }
                  value={modalState.emailSendFields.field1.value}
                  maxLength={60}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label">Field2</label>
                <Input
                  placeholder="Field2"
                  type="text"
                  name="field2"
                  onChange={(e) =>
                    handleChangeSendEmail(e.target.name, e.target.value)
                  }
                  maxLength={60}
                  autoComplete="off"
                  value={modalState.emailSendFields.field2.value}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label">Field3</label>
                <Input
                  placeholder="Field3"
                  type="text"
                  name="field3"
                  maxLength={60}
                  onChange={(e) =>
                    handleChangeSendEmail(e.target.name, e.target.value)
                  }
                  autoComplete="off"
                  value={modalState.emailSendFields.field3.value}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label">Field4</label>
                <Input
                  placeholder="Field4"
                  type="text"
                  name="field4"
                  maxLength={60}
                  onChange={(e) =>
                    handleChangeSendEmail(e.target.name, e.target.value)
                  }
                  autoComplete="off"
                  value={modalState.emailSendFields.field4.value}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label">Field5</label>
                <Input
                  placeholder="Field5"
                  type="text"
                  name="field5"
                  maxLength={60}
                  onChange={(e) =>
                    handleChangeSendEmail(e.target.name, e.target.value)
                  }
                  autoComplete="off"
                  value={modalState.emailSendFields.field5.value}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <Button
                color="info"
                className="mt-1"
                onClick={async () => {
                  if (modalState.mode === "test") {
                    handleSendTestEmail();
                  } else if (modalState.mode === "edit") {
                    handleAttendeeUpdate();
                  } else if (modalState.mode === "add") {
                    handleAttendeeAdd();
                  }
                }}
                size="md"
                disabled={
                  modalState.emailSendFields.firstName.value.trim().length ===
                    0 ||
                  modalState.emailSendFields.lastName.value.trim().length ===
                    0 ||
                  modalState.emailSendFields.email.value.trim().length === 0 ||
                  !modalState.emailSendFields.email.isValid ||
                  (modalState.mode === "edit" && !checkIfAnyFieldChanged())
                }
              >
                {modalState.mode === "test"
                  ? "Send"
                  : modalState.mode === "edit"
                  ? "Update"
                  : "Save"}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal> */}

      {/* New common Modal added for edit or add attendee */}
      <AddOrEditAttendeeModal
        openModal={modalState.show}
        toggleModalState={toggleModalState}
        modalState={modalState}
        setModalState={setModalState}
        campaignCode={campaignCode}
        notify={notify}
        attendeeListRecords={attendeeListRecords}
        usedDynamicParams={campaignDetails?.placeHolders}
      />

      {/* <SimpleHeader
        name="Create Campaign - Step-2"
        showBack
        onClickBack={() => {
          navigate("/a/campaigns/step-1", {
            state: { campaignCode: campaignCode },
          });
        }}
      /> */}
      <Container className="mt--6" fluid>
        <InviteSendingProgress
          campaignCode={campaignCode}
          campaignDetails={campaignDetails}
          unsentCount={campaignDetails?.unsendCount}
          dispatch={dispatch}
          notify={notify}
        />
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="12" md="4">
                <h3 className="mb-0">
                  {" "}
                  <i
                    className="fa fa-arrow-left mr-2 "
                    style={styles.cursor}
                    onClick={() => {
                      navigate("/a/campaigns/step-1", {
                        state: { campaignCode: campaignCode },
                      });
                    }}
                  />{" "}
                  Import Attendees
                </h3>
              </Col>
              <Col xs="12" md="8" className="text-md-right mt-2 mt-md-0">
                <div style={styles.rightBtnContainer}>
                  {attendeeListRecords.length > 0 && (
                    <Button
                      color="info"
                      onClick={() => {
                        navigate("/a/campaigns/details", {
                          state: { campaignCode: campaignCode },
                        });
                      }}
                    >
                      Campaign Details
                    </Button>
                  )}
                  {(campaignDetails?.status === "PENDING" ||
                    campaignDetails?.status === "MODIFIED") &&
                    campaignDetails?.unsendCount > 0 && (
                      <BulkSendCalendarInviteModal
                        availableCredits={
                          campaignDetails?.tenantAvailableCredits
                        }
                        creditsToBeUsed={campaignDetails?.unsendCount}
                        campaignCode={campaignCode}
                        numberOfRecipients={campaignDetails?.unsendCount}
                        notify={notify}
                        dispatch={dispatch}
                        navigate={navigate}
                      />
                    )}
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="form-control-label">
                    Select CSV file to Upload
                  </Label>

                  <div className="custom-file">
                    <input
                      type="file"
                      accept=".csv, text/csv"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleSelectCsv(e);
                      }}
                      id="csvFile"
                    />
                    <label htmlFor="csvFile" className="custom-file-label">
                      {selectedCsv ? selectedCsv.name : "Choose CSV file"}
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      columnGap: "0.5rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    <i
                      className={
                        updateExisting === "YES"
                          ? "fa-solid fa-square-check fa-md text-primary"
                          : "fa-regular fa-square fa-md"
                      }
                      onClick={() =>
                        setUpdateExisting(
                          updateExisting === "YES" ? "NO" : "YES"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    ></i>
                    <Label
                      check
                      className="form-control-label"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setUpdateExisting(
                          updateExisting === "YES" ? "NO" : "YES"
                        )
                      }
                    >
                      Update Existing Attendee Details
                    </Label>
                  </div>
                  <div className="mt-2">
                    <p style={{ fontSize: "0.9rem" }}>
                      Note: You can upload 100,000 records in one campaign.
                    </p>
                    <p
                      className="text-primary font-weight-600 "
                      style={styles.csvExampleInnerText}
                      onClick={() => handleDownload()}
                    >
                      Download a sample file
                    </p>
                  </div>
                </FormGroup>
              </Col>
              <Col>
                CSV file format Requirements
                <ul className="my-2">
                  <li>
                    A valid email is required and must be less than 100
                    characters.
                  </li>
                  <li>FirstName must be less than 50 characters.</li>
                  <li>LastName must be less than 50 characters.</li>

                  <li>
                    Ensure there are no extra rows or columns in your CSV file.
                  </li>
                </ul>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center">
              <Button
                color="info"
                onClick={() => {
                  handleCreateCampaignStep2();
                  setShowLoaderOnClick(true);
                }}
                disabled={selectedCsv === null || showLoaderOnClick}
              >
                {showLoaderOnClick ? (
                  <i className="fa fa-spinner fa-spin fa-lg text-white" />
                ) : (
                  "Import"
                )}
              </Button>
            </Row>
            {errors.message && (
              <ErrorDisplay errors={errors.data} title={errors.message} />
            )}
          </CardBody>
        </Card>

        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="12" md="4">
                <h3 className="mb-sm-3">Attendee List</h3>
              </Col>
              <Col className="d-flex justify-content-end gap-2" xs="12" md="8">
                <Button
                  className="btn-icon"
                  color="info"
                  outline
                  type="button"
                  onClick={() => toggleModalState("add")}
                  size="sm"
                >
                  <span className="btn-inner--icon">
                    <i className="fa-solid fa-plus" />
                  </span>
                  <span className="btn-inner--text">Add Attendee</span>
                </Button>
                <Button
                  className="btn-icon"
                  color="success"
                  outline
                  type="button"
                  onClick={() => sendTestCalendarInvite()}
                  size="sm"
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-email-83" />
                  </span>
                  <span className="btn-inner--text">
                    Send Test Calendar Invite
                  </span>
                </Button>
              </Col>
            </Row>
            {attendeeList?.totalRecords > 0 && (
              <Row className="mt-4 d-flex justify-content-between align-items-center">
                {/* Align Total Attendees on the left with margin-bottom on small screens */}
                <Col xs={12} md="auto" className="mb-3 mb-md-0">
                  Total Attendees: {attendeeList?.totalRecords}
                </Col>

                {/* Align Select More Columns on the right with responsive class */}
                <Col xs={12} md="auto" className="ml-md-auto">
                  <Select
                    options={columnsList}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    placeholder="Select More Columns"
                    onChange={(selected, e) =>
                      handleChangeMoreColumns(selected, e)
                    }
                    styles={customStyles}
                    value={selectedMoreCols}
                    isSearchable={true} // enable search functionality
                  />
                </Col>
              </Row>
            )}
            <hr className="my-3" />

            <Row className="p-0 align-items-center">
              {/* Filter options on the right side */}
              <Col
                xs={12}
                md={7}
                className="d-flex flex-wrap justify-content-start align-items-center p-0"
              >
                {/* Search Input */}
                <Col xs={12} sm={6} md={5} className="mb-2 mb-md-0 pr-md-0">
                  <InputGroup>
                    <InputGroupText
                      style={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    >
                      <i className="fa fa-search" />
                    </InputGroupText>
                    <Input
                      placeholder="Search"
                      type="text"
                      name="search"
                      autoComplete="off"
                      value={filter.search}
                      onChange={(e) =>
                        handleFilterChange(e.target.name, e.target.value)
                      }
                    />
                  </InputGroup>
                </Col>

                {/* Select Status */}
                <Col xs={12} sm={6} md={3} className="mb-2 mb-md-0 pr-md-0">
                  <Select
                    options={[
                      { label: "Unsent", value: "UNSEND" },
                      { label: "Sent", value: "SENT" },
                      { label: "Don't Send", value: "DONT_SEND" },
                      { label: "Unsubscribed", value: "UNSUBSCRIBED" },
                    ]}
                    placeholder="Search by status"
                    onChange={(selected, e) =>
                      handleFilterChange("status", selected)
                    }
                    styles={customStyles}
                    value={filter.status}
                    isSearchable={true}
                    isClearable
                  />
                </Col>

                {/* Search Button */}
                <Col xs="auto" className="mb-2 mb-md-0">
                  <Button
                    className="btn-round btn-icon"
                    color="info"
                    outline
                    onClick={handleSearch}
                    size="md"
                    disabled={showLoaderOnSearch}
                  >
                    {showLoaderOnSearch ? (
                      <i className="fa fa-spinner fa-spin fa-lg text-info px-3" />
                    ) : (
                      "Search"
                    )}
                  </Button>
                </Col>

                {/* Clear Button */}
                {filterParams !== "" && (
                  <Col xs="auto" className="mb-2 mb-md-0 p-0">
                    <Button
                      className="btn-round btn-icon"
                      color="light"
                      outline
                      onClick={handleClear}
                      size="md"
                    >
                      Clear
                    </Button>
                  </Col>
                )}
              </Col>

              {/* Action Buttons on the left side */}
              {attendeeListRecords.some((item) => item.checked) && (
                <Col
                  xs={12}
                  md={5}
                  className="d-flex justify-content-end gap-2"
                >
                  {/* Mark as Unsent */}
                  {attendeeListRecords.some(
                    (item) =>
                      item.checked &&
                      (item.status === "DONT_SEND" || item.status === "SENT")
                  ) && (
                    <>
                      <Button
                        className="btn-round btn-icon"
                        color="light"
                        outline
                        id="tooltipUnsent"
                        onClick={() => bulkStatusChange("UNSEND")}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fa-solid fa-envelope-open"></i>
                        </span>
                        <span className="btn-inner--text">Mark as Unsent</span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="tooltipUnsent">
                        Mark Selected as Unsent
                      </UncontrolledTooltip>
                    </>
                  )}

                  {/* Mark as Don't Send */}
                  {attendeeListRecords.some(
                    (item) =>
                      item.checked &&
                      (item.status === "UNSEND" || item.status === "SENT")
                  ) && (
                    <>
                      <Button
                        className="btn-round btn-icon"
                        color="light"
                        id="tooltipDontSend"
                        onClick={() => bulkStatusChange("DONT_SEND")}
                        size="sm"
                        outline
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-close" />
                        </span>
                        <span className="btn-inner--text">
                          Mark as Don't Send
                        </span>
                      </Button>
                      <UncontrolledTooltip delay={0} target="tooltipDontSend">
                        Mark Selected as Don't Send
                      </UncontrolledTooltip>
                    </>
                  )}

                  {/* Delete Button */}
                  <Button
                    className="btn-round btn-icon"
                    color="danger"
                    id="tooltip28070728"
                    onClick={() => deleteWarning()}
                    size="sm"
                    outline
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-trash" />
                    </span>
                    <span className="btn-inner--text">Delete</span>
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip28070728">
                    Delete Selected
                  </UncontrolledTooltip>
                </Col>
              )}
            </Row>

            {/* )} */}
          </CardHeader>

          {showListAlternative.loader ? (
            <div style={styles.loaderContainer}>
              <i className="fa fa-spinner fa-spin fa-2xl text-info" />
            </div>
          ) : showListAlternative.noRecordsFound ? (
            <div style={styles.loaderContainer}>No Records Found!</div>
          ) : (
            <>
              <Table
                className="align-items-center table-flush"
                hover
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th>
                      <i
                        className={
                          attendeeListRecords.every((item) => item.checked)
                            ? "fa-solid fa-square-check fa-xl text-primary"
                            : "fa-regular fa-square fa-xl"
                        }
                        onClick={() => {
                          handleCheckAttendeeList(
                            -1,
                            attendeeListRecords.every((item) => item.checked)
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </th>
                    <th>Sr. no</th>
                    <th
                      style={styles.cursor}
                      onClick={() => handleSort("Email")}
                    >
                      <span className="d-flex align-items-center justify-content-between">
                        Email ID
                        <span className="d-flex align-items-center">
                          <span
                            style={{
                              color:
                                sortConfig.key === "Email" &&
                                sortConfig.direction === "ASC"
                                  ? "gray"
                                  : "lightgray",
                              ...styles.sortIcon,
                            }}
                          >
                            ↑
                          </span>
                          <span
                            style={{
                              color:
                                sortConfig.key === "Email" &&
                                sortConfig.direction === "DESC"
                                  ? "gray"
                                  : "lightgray",
                              ...styles.sortIcon,
                            }}
                          >
                            ↓
                          </span>
                        </span>
                      </span>
                    </th>

                    <th
                      style={styles.cursor}
                      onClick={() => handleSort("FirstName")}
                    >
                      <span className="d-flex align-items-center justify-content-between">
                        First Name
                        <span className="d-flex align-items-center">
                          <span
                            style={{
                              color:
                                sortConfig.key === "FirstName" &&
                                sortConfig.direction === "ASC"
                                  ? "gray"
                                  : "lightgray",
                              ...styles.sortIcon,
                            }}
                          >
                            ↑
                          </span>
                          <span
                            style={{
                              color:
                                sortConfig.key === "FirstName" &&
                                sortConfig.direction === "DESC"
                                  ? "gray"
                                  : "lightgray",
                              ...styles.sortIcon,
                            }}
                          >
                            ↓
                          </span>
                        </span>
                      </span>
                    </th>
                    <th
                      style={styles.cursor}
                      onClick={() => handleSort("LastName")}
                    >
                      <span className="d-flex align-items-center justify-content-between">
                        Last Name
                        <span className="d-flex align-items-center">
                          <span
                            style={{
                              color:
                                sortConfig.key === "LastName" &&
                                sortConfig.direction === "ASC"
                                  ? "gray"
                                  : "lightgray",
                              ...styles.sortIcon,
                            }}
                          >
                            ↑
                          </span>
                          <span
                            style={{
                              color:
                                sortConfig.key === "LastName" &&
                                sortConfig.direction === "DESC"
                                  ? "gray"
                                  : "lightgray",
                              ...styles.sortIcon,
                            }}
                          >
                            ↓
                          </span>
                        </span>
                      </span>
                    </th>
                    <th
                      style={styles.cursor}
                      onClick={() => handleSort("SentOn")}
                    >
                      <span className="d-flex align-items-center justify-content-between">
                        Sent On
                        <span className="d-flex align-items-center">
                          <span
                            style={{
                              color:
                                sortConfig.key === "SentOn" &&
                                sortConfig.direction === "ASC"
                                  ? "gray"
                                  : "lightgray",
                              ...styles.sortIcon,
                            }}
                          >
                            ↑
                          </span>
                          <span
                            style={{
                              color:
                                sortConfig.key === "SentOn" &&
                                sortConfig.direction === "DESC"
                                  ? "gray"
                                  : "lightgray",
                              ...styles.sortIcon,
                            }}
                          >
                            ↓
                          </span>
                        </span>
                      </span>
                    </th>
                    <th>Status</th>

                    {selectedMoreCols.length > 0 &&
                      selectedMoreCols.map((colItem) => {
                        return <th key={colItem.label}>{colItem.label}</th>;
                      })}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {attendeeListRecords.map((item, index) => {
                    return (
                      <tr
                        style={{
                          backgroundColor: item.checked
                            ? selectedColor
                            : "white",
                        }}
                        key={item.email}
                      >
                        <th>
                          <i
                            className={
                              item.checked
                                ? "fa-solid fa-square-check fa-lg text-primary"
                                : "fa-regular fa-square fa-lg"
                            }
                            onClick={() => handleCheckAttendeeList(index)}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </th>
                        <td className="text-muted">
                          {calculateSerialNum(index)}
                        </td>
                        <td className="text-muted">
                          <span>{item.email}</span>
                        </td>
                        <td className="text-muted">
                          <span>{item.firstName}</span>
                        </td>
                        <td className="text-muted">
                          <span>{item.lastName}</span>
                        </td>
                        <td className="text-muted">
                          {item.sentOn !== "" ? (
                            <span>{formatDateWithTime(item.sentOn)}</span>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          {item.status === "UNSEND" ? (
                            <span className="text-muted">Unsent</span>
                          ) : item.status === "SENT" ? (
                            <span className="text-success font-weight-bold">
                              Sent
                            </span>
                          ) : item.status === "DONT_SEND" ? (
                            <span className="text-primary">Don't Send</span>
                          ) : item.status === "UNSUBSCRIBED" ? (
                            <span className="text-orange">Unsubscribed</span>
                          ) : (
                            <span className="text-muted">{item.status}</span>
                          )}
                        </td>
                        {selectedMoreCols.length > 0 &&
                          selectedMoreCols.map((colItem) => {
                            return (
                              <td className="text-muted" key={colItem.value}>
                                {item[colItem.value]}
                              </td>
                            );
                          })}
                        <td>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "1rem",
                            }}
                          >
                            <a
                              className="table-action"
                              id={`tooltipEditRecord${item.ID}`}
                              onClick={(e) => {
                                toggleModalState("edit", item);
                              }}
                            >
                              <i className="fas fa-edit" />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target={`tooltipEditRecord${item.ID}`}
                            >
                              Edit Record
                            </UncontrolledTooltip>
                            <div className="text-gray">|</div>
                            <a
                              className="table-action table-action-delete"
                              id={`tooltipDeleteRecord${item.ID}`}
                              onClick={(e) => deleteWarning(item.ID)}
                            >
                              <i className="fas fa-trash" />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target={`tooltipDeleteRecord${item.ID}`}
                            >
                              Delete Record
                            </UncontrolledTooltip>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div>
                <CustomPagination
                  totalPages={Math.ceil(
                    attendeeList.totalRecords / attendeeList.noOfRecordsPerPage
                  )}
                  setPageNum={setPageNum}
                  activePage={pageNum}
                />
              </div>
            </>
          )}
        </Card>
      </Container>
    </>
  );
}

export default CreateCampaignStep2;

const styles = {
  cursor: { cursor: "pointer" },
  csvExampleInnerText: { cursor: "pointer", fontSize: 14 },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  actionConatiner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    columnGap: "1rem",
  },
  deleteLoader: {
    backgroundColor: "white",
    display: "flex",
    padding: "2rem",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "2rem",
  },
  rightBtnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  sortIcon: {
    fontSize: "1.5em",
    transform: "scale(1.2)",
  },
};

//code for progress bar may be needed in future
// {
//   !importingStarted ? (
//     <Row className="d-flex justify-content-center">
//       <Button
//         color="info"
//         type="submit"
//         onClick={() => {
//           setImportingStarted(true);
//         }}
//       >
//         Import
//       </Button>
//     </Row>
//   ) : (
//     <>
//       <Row className="d-flex justify-content-center ">
//         <div className="progress-wrapper">
//           <div className="progress-info">
//             <div className="progress-label">
//               <span>File Uploding</span>
//             </div>
//             <div className="progress-percentage">
//               <span>60%</span>
//             </div>
//           </div>
//           <Progress max="100" value="60" color="info" />
//         </div>
//         {/* <Alert color="success">
//                   <span className="alert-icon">
//                     <i className="ni ni-like-2" />
//                   </span>
//                   <span className="alert-text ml-1">
//                     <strong>Your Import is Processing!</strong> Please wait
//                     until all records are uploaded
//                   </span>
//                 </Alert> */}
//       </Row>
//       <Row className="d-flex justify-content-center">
//         <div className="progress-wrapper">
//           <div className="progress-info">
//             <div className="progress-label">
//               <span>Attendee Added</span>
//             </div>
//             <div className="progress-percentage">
//               <span>200/1000</span>
//             </div>
//           </div>
//           <Progress max="100" value="20" color="primary" />
//         </div>
//         {/* <Alert color="success">
//                   <span className="alert-icon">
//                     <i className="ni ni-like-2" />
//                   </span>
//                   <span className="alert-text ml-1">
//                     <strong>Your Import is Processing!</strong> Please wait
//                     until all records are uploaded
//                   </span>
//                 </Alert> */}
//       </Row>
//     </>
//   );
// }

// {
//   attendeeImported && (
//     <Card className="border">
//       <CardBody
//         style={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           flexDirection: "column",
//           rowGap: "1rem",
//         }}
//       >
//         <Row>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               columnGap: "10",
//             }}
//           >
//             <p className="h3">Attendee Imported Successfully!</p>{" "}
//             <i className="fa-regular fa-circle-check fa-lg text-green ml-2 mb-2"></i>
//           </div>
//         </Row>
//         <Row className="my-2">
//           {/* <i class="fa-solid fa-envelope-circle-check fa-9x text-light"></i> */}
//           <i className="fa-solid fa-envelopes-bulk fa-9x text-black"></i>
//         </Row>
//         <Row>
//           <Button
//             className="btn-round btn-icon"
//             color="info"
//             href="#pablo"
//             onClick={(e) => e.preventDefault()}
//             size="md"
//           >
//             {/* <span className="btn-inner--icon mr-1">
//                     <i className="fas fa-user-edit" />
//                   </span> */}
//             <span className="btn-inner--text">Next</span>
//           </Button>
//         </Row>
//       </CardBody>
//     </Card>
//   );
// }
