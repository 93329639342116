import { unsubscribingUser } from "action/unsubscibe/unsubscribe";
import { getUserInfoForUnsubscribing } from "action/unsubscibe/unsubscribe";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "reactstrap";

const UnsubscribeScreen = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [unsubscribeOption, setUnsubscribeOption] = useState("");
  const [reason, setReason] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [uuidValidation, setUuidValidation] = React.useState(true);
  const [res, setRes] = React.useState("");

  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const uuid = pathSegments[pathSegments.length - 1];

  React.useEffect(() => {
    fetchData();
  }, [uuid]);

  const fetchData = async () => {
    try {
      const res = await getUserInfoForUnsubscribing(uuid);
      if (res.code === 200) {
        setUserInfo(res.data);
        if (res.data.isCampaignUbsubscribed === "NO") {
          setUnsubscribeOption("CAMPAIGN");
        }
        setIsLoading(false);
      } else {
        setRes(res);
        setUuidValidation(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error verifying UUID:", error);
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    setIsLoading(true);
    const data = {
      unsubscribeTo: unsubscribeOption,
      reason: reason || "No reason provided",
    };
    try {
      const res = await unsubscribingUser(uuid, data);
      if (res.code === 200) {
        setIsSuccess(true);
        setIsLoading(false);
      } else {
        setRes(res);
        setUuidValidation(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error unsubscribing:", error);
    }
  };

  return (
    <div style={styles.container}>
      {isLoading ? (
        <div style={styles.spinner}>
          <i className="fa fa-spinner fa-spin fa-3x text-black" />
        </div>
      ) : isSuccess ? (
        <div style={styles.successMessage}>
          <h1 style={styles.heading}>Unsubscribe Successful!</h1>
          <p>
            You will no longer receive emails{" "}
            {unsubscribeOption === "TENANT" ? "from " : "for "}
            <strong>
              {unsubscribeOption === "TENANT"
                ? userInfo.organizationName
                : userInfo.campaignName}
            </strong>
            .
          </p>
        </div>
      ) : uuidValidation ? (
        userInfo.isTenantUnsubscribed === "YES" &&
        userInfo.isCampaignUbsubscribed === "YES" ? (
          <div style={styles.successMessage}>
            <h1 style={styles.heading}>You are already Unsubscribed!</h1>
            <p>You will not receive emails from {userInfo.organizationName}.</p>
          </div>
        ) : (
          <>
            <h2 style={styles.heading}>Unsubscribe Options</h2>
            {/* Display User Email */}
            <p style={styles.userEmail}>
              You are unsubscribing for - <strong>{userInfo.email}</strong>
            </p>
            <form onSubmit={handleSubmit} style={styles.form}>
              {/* Option to unsubscribe from the campaign */}
              {userInfo.isCampaignUbsubscribed === "NO" && (
                <div style={styles.option}>
                  <input
                    type="radio"
                    id="CAMPAIGN"
                    name="unsubscribeOption"
                    value="CAMPAIGN"
                    checked={unsubscribeOption === "CAMPAIGN"}
                    onChange={() => setUnsubscribeOption("CAMPAIGN")}
                  />
                  <label htmlFor="CAMPAIGN" style={styles.label}>
                    Unsubscribe from Campaign
                  </label>
                  <p style={styles.description}>
                    This will only stop emails and calendar invites related to
                    this <strong>{userInfo.campaignName}</strong>. You will
                    still receive emails from other campaigns and updates from
                    the organization.
                  </p>
                </div>
              )}
              {/* Option to unsubscribe from the entire organization */}
              {userInfo.isTenantUnsubscribed === "NO" && (
                <div style={styles.option}>
                  <input
                    type="radio"
                    id="TENANT"
                    name="unsubscribeOption"
                    value="TENANT"
                    checked={unsubscribeOption === "TENANT"}
                    onChange={() => setUnsubscribeOption("TENANT")}
                  />
                  <label htmlFor="TENANT" style={styles.label}>
                    Unsubscribe from Organization
                  </label>
                  <p style={styles.description}>
                    This will stop all future emails and calendar invites from{" "}
                    {userInfo.organizationName != "" ? (
                      <strong>{userInfo.organizationName}</strong>
                    ) : (
                      "this Organization"
                    )}
                    . You will no longer receive any updates or campaigns from
                    them.
                  </p>
                </div>
              )}

              {/* Optional reason input */}
              <div style={styles.option}>
                <label htmlFor="reason" style={styles.label}>
                  Reason for Unsubscribing (Optional)
                </label>
                <textarea
                  id="reason"
                  name="reason"
                  placeholder="Tell us why you're unsubscribing (optional)"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  style={styles.textArea}
                  maxLength={100}
                />
              </div>

              {/* Submit Button */}
              <Button
                type="submit"
                color="info"
                style={styles.button}
                disabled={unsubscribeOption === ""}
              >
                Unsubscribe
              </Button>
            </form>
          </>
        )
      ) : (
        <div style={styles.successMessage}>
          <h1 style={styles.heading}>Error {res.code}</h1>
          <p className="text-red h3">{res.message}</p>
        </div>
      )}
    </div>
  );
};
export default UnsubscribeScreen;

// CSS-in-JS styling
const styles = {
  container: {
    maxWidth: "600px",
    margin: "40px auto",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
  heading: {
    textAlign: "center",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  option: {
    marginBottom: "20px",
  },
  label: {
    fontSize: "16px",
    fontWeight: "bold",
    marginLeft: "8px",
  },
  description: {
    marginLeft: "30px",
    fontSize: "14px",
    color: "#555",
  },
  textArea: {
    width: "100%",
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "14px",
  },
  button: {
    padding: "12px",
    fontSize: "1rem",
  },
  successMessage: {
    textAlign: "center",
    marginBottom: "20px",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "150px",
    backgroundColor: "white", // Blank screen background color
  },
};
