import React from "react";

const ErrorDisplay = ({ errors, title }) => {
  return (
    <div style={styles.errorContainer}>
      <h2 style={styles.errorTitle}>{title}</h2>
      <div style={styles.errorListContainer}>
        {errors && errors.length > 0 ? (
          <ul style={styles.errorList}>
            {errors.map((error, index) => (
              <li key={index} style={styles.errorItem}>
                {error}
              </li>
            ))}
          </ul>
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default ErrorDisplay;

const styles = {
  errorContainer: {
    backgroundColor: "#ffffff",
    border: "0.0625rem solid #e0b4b4",
    padding: "1.25rem",
    borderRadius: "0.5rem",
    maxWidth: "100%",
    margin: "1.25rem auto",
    boxShadow: "0 0.25rem 0.625rem rgba(0, 0, 0, 0.1)",
  },
  errorTitle: {
    color: "#d8000c",
    fontSize: "1.125rem",
    marginBottom: "0.9375rem",
  },
  errorListContainer: {
    maxHeight: "18.75rem",
    overflowY: "auto",
    paddingRight: "0.625rem",
  },
  errorList: {
    listStyleType: "none",
    padding: 0,
  },
  errorItem: {
    backgroundColor: "#fff3f3",
    color: "#d8000c",
    padding: "0.625rem",
    marginBottom: "0.5rem",
    borderRadius: "0.25rem",
    fontSize: "0.875rem",
    border: "0.03125rem solid #e0b4b4",
  },
};
