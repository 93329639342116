/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  NavbarBrand,
  Navbar,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { resetPassword } from "action/Settings/SubUser";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyUuid } from "action/Auth/publicScreen";
import NotificationAlert from "react-notification-alert";
import CommonScreen from "./CommonScreen";
import AdminNavbar from "components/Navbars/AdminNavbar";

function ResetPassword() {
  const [res, setRes] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [passwordStrength, setPasswordStrength] = React.useState("");
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [confPasswordVisible, setConfPasswordVisible] = React.useState(false);
  const [uuidValidation, setUuidValidation] = React.useState(true);
  const [formData, setFormData] = React.useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = React.useState({
    password: "",
    confirmPassword: "",
  });
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const uuid = pathSegments[pathSegments.length - 1];
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    if (isNotificationOpen) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };

    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  React.useEffect(() => {
    fetchData();
  }, [uuid]);

  const fetchData = async () => {
    try {
      const res = await verifyUuid(uuid);
      if (res.code === 200) {
        setUuidValidation(true);
        setIsLoading(false);
      } else {
        setUuidValidation(false);
        setRes(res);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error verifying UUID:", error);
    }
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case "Strong":
        return "text-success";
      case "Medium":
        return "text-warning";
      case "Weak":
      default:
        return "text-danger";
    }
  };
  const getPasswordStrength = (password) => {
    let strength = "Weak";
    if (
      password.length >= 6 &&
      /\d/.test(password) &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password)
    ) {
      strength = "Strong";
    } else if (
      password.length >= 6 &&
      (/\d/.test(password) || /[@$!%*?&]/.test(password))
    ) {
      strength = "Medium";
    }
    return strength;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "confirmPassword") {
      setErrors({
        ...errors,
        confirmPassword: value ? "" : "This field is required",
      });
    }

    if (name === "password") {
      setErrors({ ...errors, password: value ? "" : "This field is required" });
      const strength = getPasswordStrength(value);
      setPasswordStrength(strength);
    }
    if (value === formData.confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }
  };
  //
  const validateForm = () => {
    let isValid = true;
    // Password Strength Validation: Only return true if the password is strong
    if (!formData.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "This field is required",
      }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }

    // Confirm Password Validation: Check if it matches the password
    if (!formData.confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "This field is required",
      }));
      isValid = false;
    } else if (formData.password !== formData.confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match. Please try again.",
      }));
      isValid = false;
    } else if (passwordStrength === "Weak" || passwordStrength === "Medium") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Please choose a stronger password.",
      }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }
    return isValid;
  };

  const changePassword = async () => {
    if (validateForm()) {
      const res = await resetPassword({
        uuid: uuid,
        password: formData.password,
      });
      if (res.code === 200) {
        notify("success", res.message);
        navigate("/auth/login", {
          state: { msg: "Your password is reset now you can login." },
        });
      } else {
        notify("error", res.message);
      }
    }
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfPasswordVisibility = (field) => {
    setConfPasswordVisible(!confPasswordVisible);
  };
  const hasErrors = Object.values(errors).some((error) => error !== "");
  const handleDisabled = () => {
    return (
      formData.password.length < 6 ||
      formData.confirmPassword.length < 6 ||
      hasErrors
    );
  };

  return (
    <>
      {isLoading ? (
        <div style={styles.spinner}>
          <i className="fa fa-spinner fa-spin fa-3x text-black" />
        </div>
      ) : (
        <>
          {uuidValidation ? (
            <div className="bg-default">
              <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
              </div>
              <div
                className="header py-lg-7 pt-lg-8"
                style={{ backgroundColor: "#0da5c0" }}
              >
                <Container className="mt--4">
                  <NavbarBrand>
                    <img
                      alt="..."
                      className="navbar-brand-img mt--9"
                      src={require("assets/img/brand/logo.png")}
                      style={{ width: "150px", height: "auto" }}
                    />
                  </NavbarBrand>
                </Container>
                <Container>
                  <div className="header-body text-center mt-4">
                    <Row className="justify-content-center mt--4">
                      <Col className="px-5" lg="6" md="8" xl="5">
                        <h1 className="text-white">Welcome!</h1>
                      </Col>
                    </Row>
                  </div>
                </Container>
                <div className="separator separator-bottom separator-skew zindex-100 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 256 100"
                    x="0"
                    y="0"
                  >
                    <polygon
                      className="fill-default"
                      points="2560 0 256 100 0 100"
                    />
                  </svg>
                </div>
              </div>
              <Container className="mt--6 pb-8">
                <Row className="justify-content-center">
                  <Col lg="5" md="7">
                    <Card className="bg-secondary border-0 mb-0">
                      <CardHeader className="bg-transparent pb-3 text-center">
                        Reset Password
                      </CardHeader>
                      <CardBody className="px-lg-5 py-lg-5">
                        <Form role="form">
                          <FormGroup
                            className={classnames({ focused: focusedPassword })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fa fa-lock" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Password"
                                type={passwordVisible ? "text" : "password"}
                                name="password"
                                onChange={handleChange}
                                onFocus={() => setfocusedPassword(true)}
                                onBlur={() => setfocusedPassword(true)}
                              />
                              <InputGroupText>
                                <i
                                  className={`fa ${
                                    passwordVisible ? "fa-eye-slash" : "fa-eye"
                                  }`}
                                  aria-hidden="true"
                                  onClick={() =>
                                    togglePasswordVisibility("password")
                                  }
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </InputGroupText>
                            </InputGroup>
                            {errors.password && (
                              <small className="text-danger">
                                {errors.password}
                              </small>
                            )}
                          </FormGroup>
                          <FormGroup
                            className={classnames({ focused: focusedPassword })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fa fa-lock" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                type={confPasswordVisible ? "text" : "password"}
                                onChange={handleChange}
                                onFocus={() => setfocusedPassword(true)}
                                onBlur={() => setfocusedPassword(true)}
                              />
                              <InputGroupText>
                                <i
                                  className={`fa ${
                                    confPasswordVisible
                                      ? "fa-eye-slash"
                                      : "fa-eye"
                                  }`}
                                  aria-hidden="true"
                                  onClick={() =>
                                    toggleConfPasswordVisibility(
                                      "confirmPassword"
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </InputGroupText>
                            </InputGroup>
                            {errors.confirmPassword && (
                              <small className="text-danger">
                                {errors.confirmPassword}
                              </small>
                            )}

                            <div className="text-muted">
                              <small>
                                Password strength:{" "}
                                <span className={getPasswordStrengthColor()}>
                                  {passwordStrength}
                                </span>
                              </small>
                            </div>
                          </FormGroup>
                          <div className="text-center">
                            <Button
                              className="my-4"
                              color={handleDisabled() ? "secondary" : "info"}
                              type="button"
                              disabled={handleDisabled()}
                              onClick={() => changePassword()}
                            >
                              Reset Password
                            </Button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <div style={styles.footer}>
                <div style={{ flex: 1 }}></div>
                <AuthFooter />
              </div>
            </div>
          ) : (
            <CommonScreen
              res={res}
              title={"Error"}
              description={
                " This link has expired. Please contact your administrator for assistance."
              }
              button={"BulkCalendar.com"}
              color="red"
            />
          )}
        </>
      )}
    </>
  );
}

export default ResetPassword;

const styles = {
  footer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "16vh",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Full viewport height
    width: "100vw", // Full viewport width
    backgroundColor: "white", // Blank screen background color
  },
};
