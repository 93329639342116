import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import { getInviteCount } from "action/campaign/campaignListing";
import { Card, CardBody, CardTitle, Progress, Row } from "reactstrap";
import { getCampaignDetails } from "action/campaign/createCampaign";
import { getAttendeeList } from "action/campaign/createCampaign";
import { useSelector } from "react-redux";

const InviteSendingProgress = ({ campaignCode, dispatch, notify }) => {
  const [emailsSent, setEmailsSent] = useState(0); // Track number of emails sent
  const [fetchingError, setFetchingError] = useState(null); // Track error if any during API calls
  const [totalEmails, setTotalEmails] = useState(0);
  const campaignDetails = useSelector(
    (state) => state.createCampaign.campaignDetails
  );

  const getTotalInviteCount = async () => {
    const getInviteCountRes = await getInviteCount(campaignCode);

    if (
      getInviteCountRes.code === 200 &&
      getInviteCountRes.data.status === "IN-PROGRESS"
    ) {
      const sent = getInviteCountRes.data.attendeeCount;
      setTotalEmails(sent);
    }
  };

  useEffect(() => {
    if (
      campaignDetails.unsendCount &&
      campaignDetails.status !== "IN-PROGRESS"
    ) {
      setTotalEmails(campaignDetails.unsendCount);
    } else if (campaignCode) {
      getTotalInviteCount();
    }

    let intervalId;

    // Start polling only if campaign is in progress
    if (campaignDetails && campaignDetails.status === "IN-PROGRESS") {
      intervalId = setInterval(async () => {
        try {
          const getInviteCountRes = await getInviteCount(campaignCode);

          if (getInviteCountRes.code === 200) {
            const sent = getInviteCountRes.data.sentCount;
            setEmailsSent(sent);
            if (totalEmails == 0) {
              setTotalEmails(getInviteCountRes.data.attendeeCount);
            }

            // Stop polling if campaign is no longer in progress or when total to be sent invite is equal to sent invites
            // the OR condition is added bcoz in some cases the pogressBar was not getting closed and continue polling was done
            if (
              getInviteCountRes.data.status !== "IN-PROGRESS" ||
              getInviteCountRes.data.sentCount ===
                getInviteCountRes.data.attendeeCount
            ) {
              clearInterval(intervalId);
              //persist progressbar for 5 seconds
              setTimeout(() => {
                //show the calendar invite sent message only when invite is send completed
                //or when total to be sent ===  to sent count
                if (
                  getInviteCountRes.data.status === "COMPLETED" ||
                  getInviteCountRes.data.sentCount ===
                    getInviteCountRes.data.attendeeCount
                ) {
                  notify("success", {
                    message: "Calendar invites sent completed!",
                    subMessage: "",
                    showForceFully: true,
                  });
                } else if (getInviteCountRes.data.status === "FAILED") {
                  notify("error", {
                    message: "Calendar invites sent Failed!",
                    subMessage: "",
                    showForceFully: true,
                  });
                }
                getCampaignDetails(dispatch, campaignCode);
                getAttendeeList(dispatch, campaignCode);
              }, 5000);
            }
          } else {
            setFetchingError("Error fetching sent count");
            clearInterval(intervalId);
          }
        } catch (error) {
          setFetchingError("Error fetching sent count");
          clearInterval(intervalId);
        }
      }, 3000); // Poll every second
    }

    // Cleanup the interval when the component unmounts or if campaignDetails changes
    return () => clearInterval(intervalId);
  }, [campaignDetails, campaignCode]);

  // Conditional rendering: Show nothing if campaign is not in progress
  if (!campaignDetails || campaignDetails.status !== "IN-PROGRESS") {
    return null; // Return null to render nothing if the campaign is not in progress
  }

  return (
    <Card>
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0 ">
              Sending Invites...
            </CardTitle>
            {/* <span className="h2 font-weight-bold mb-0 ">{`${emailsSent}/${totalEmails}`}</span> */}
            <span className="h2 font-weight-bold mb-0 ">
              {`${emailsSent}/${totalEmails}`}
            </span>
            <Progress
              className="progress-sm mt-3 mb-0"
              max={totalEmails}
              value={emailsSent}
              color="success"
            />
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default InviteSendingProgress;
