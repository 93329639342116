import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ReactBSAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import { useEffect, useState } from "react";
import { getCampaignList } from "action/campaign/campaignListing";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "components/Common/Pagination";
import { cyanBlue, statusColor1 } from "components/Common/constants";
import { deleteCampaign } from "action/campaign/campaignListing";
import { useNavigate } from "react-router-dom";

function ListCampaign() {
  const [pageNum, setPageNum] = useState(1);
  const [campaignCode, setCampaignCode] = useState(0);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [showListAlternative, setShowListAlternative] = useState({
    loader: true,
    noRecordsFound: false,
  });
  const [sort, setSort] = useState({
    sortBy: "",
    sortType: "",
    param: "",
  });
  const [filterParams, setFilterParams] = useState("");
  const [showLoaderOnSearch, setShowLoaderOnSearch] = useState(false);
  const [filter, setFilter] = useState({
    search: "",
    status: null,
  });
  const campaignList = useSelector(
    (state) => state.campaignListReducers.campaignList
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    getCampaignList(dispatch, pageNum, sort.param, filterParams);
  }, [pageNum, sort.param, filterParams]);

  useEffect(() => {
    if (campaignList.Campaign && campaignList.Campaign.length > 0) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
      setShowLoaderOnSearch(false);
    } else if (campaignList.Campaign && campaignList.Campaign.length === 0) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: filterParams === "" ? true : false,
      });
      setShowLoaderOnSearch(false);
    }
  }, [campaignList]);

  const formattedCampaignList = campaignList?.Campaign?.map((item, index) => {
    return {
      ...item,
      srNo: (pageNum - 1) * 10 + index + 1,
      createdAt: item.createdAt,
      startAt: item.startAt,
      endAt: item.endAt,
    };
  });

  const deleteCampaignRecord = async () => {
    showDeleteLoader();
    const res = await deleteCampaign(campaignCode);
    if (res.code === 200) {
      confirmAlert("Deleted!", res.message, "success");
      setDeleteConfirm(false);
      getCampaignList(dispatch, pageNum);
    } else {
      confirmAlert("Deleted!", res.message, "error");
    }
  };

  const handleModal = () => {
    setDeleteConfirm(false);
  };

  const confirmAlert = (title, msg, type) => {
    setAlert(
      <ReactBSAlert
        {...{ [type]: true }}
        title={title}
        onConfirm={() => hideAlert()}
        showConfirm={false}
      >
        {msg}
      </ReactBSAlert>
    );
    setTimeout(hideAlert, 1500);
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const deleteWarning = (ID) => {
    setDeleteConfirm(true);
    setCampaignCode(ID);
  };
  const handleModalChange = () => {
    setDeleteConfirm(false);
  };
  const showDeleteLoader = () => {
    setAlert(
      <Modal isOpen={true} centered size="sm">
        <ModalBody>
          <div style={styles.deleteLoader}>
            <i
              className="fa fa-spinner fa-spin fa-2xl"
              style={{ color: cyanBlue }}
            />
            <h1>Deleting...</h1>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const handleOnClickTitle = (item) => {
    navigate("/a/campaigns/details", {
      state: { campaignCode: item.campaignCode },
    });
  };

  //to capitalize fisrt letter of the word
  const capitalizeFirstLetter = (str) => {
    return str[0].toUpperCase() + str.slice(1);
  };

  const onTableChange = (type, newState) => {
    // handle any data change here
    if (type === "sort") {
      const columnName = capitalizeFirstLetter(newState.sortField);
      setSort({
        sortBy: columnName,
        sortType: newState.sortOrder,
        param: `&sortOptionJSON={"SortBy":"${columnName}","SortType":"${newState.sortOrder.toUpperCase()}"}`,
      });
    }
  };

  //search filters
  const handleSearch = () => {
    setShowLoaderOnSearch(true);
    let querryParmFilter = "";
    //make filters query params
    if (filter.search.trim().length > 0) {
      querryParmFilter = `&filtersJSON=[{"field": "CampaignName", "condition": "like", "filterValues": [${JSON.stringify(
        filter.search
      )}]}]`;
    }
    setFilterParams(querryParmFilter);
  };

  //on change of filter in attendee list
  const handleFilterChange = (name, value) => {
    setFilter((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  //on clear filters
  const handleClear = () => {
    setFilterParams("");
    setFilter({
      search: "",
      status: null,
    });
  };

  return (
    <>
      {/* <SimpleHeader name="Campaign" /> */}
      {alert}
      <Modal
        isOpen={deleteConfirm}
        toggle={handleModal}
        // style={styles.videoContainer}
        className={`modal-dialog-centered`}
      >
        <ModalHeader>
          <span className="form-control-label" style={{ fontSize: "1.8rem" }}>
            Are you sure?
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="custom-control custom-checkbox mb-3">
            <input
              className="custom-control-input"
              id="customCheck2"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label
              className="custom-control-label ml--3"
              htmlFor="customCheck2"
            >
              This campaign will be permanently deleted and cannot be recovered.
            </label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => handleModalChange()}
            color="info"
          >
            Cancel
          </Button>
          <Button
            variant="info"
            onClick={deleteCampaignRecord}
            disabled={!isChecked} // Disable button until checkbox is checked
            color="error"
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row>
              <Col xs={12} md={4}>
                <h3 className="mb-0">Campaigns</h3>
              </Col>
              {/* {!showListAlternative.noRecordsFound && ( */}
              <Col className="text-right" md={8}>
                <Button
                  color="info"
                  onClick={() => {
                    navigate("/a/campaigns/step-1");
                  }}
                >
                  Create Campaign
                </Button>
              </Col>
              {/* )} */}
            </Row>
          </CardHeader>
          <CardBody>
            {showListAlternative.loader ? (
              <div style={styles.loaderContainer}>
                <i
                  className="fa fa-spinner fa-spin fa-2xl"
                  style={styles.loaderColor}
                />
              </div>
            ) : showListAlternative.noRecordsFound ? (
              <div style={styles.loaderContainer} className="text-muted">
                <p className="mb-0">No campaigns yet! </p>
                <p className="mb-0 text-center">
                  Create your first campaign and send invites in minutes!
                </p>{" "}
              </div>
            ) : (
              <>
                <Row className="p-0 align-items-center mb-4">
                  {/* Search Input */}
                  <Col xs={12} sm={6} md={5} className="mb-2 mb-md-0 pr-md-0">
                    <InputGroup>
                      <InputGroupText
                        style={{
                          borderTopRighttRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      >
                        <InputGroupAddon addonType="prepend">
                          <i className="fa fa-search" />
                        </InputGroupAddon>
                      </InputGroupText>
                      <Input
                        placeholder="Search by Campaign Name"
                        type="text"
                        name="search"
                        autoComplete="off"
                        value={filter.search}
                        onChange={(e) =>
                          handleFilterChange(e.target.name, e.target.value)
                        }
                      />
                    </InputGroup>
                  </Col>
                  {/* Search Button */}
                  <Col xs="auto" className="mb-2 mb-md-0">
                    <Button
                      className="btn-round btn-icon"
                      color="info"
                      outline
                      onClick={(e) => handleSearch()}
                      size="md"
                      disabled={showLoaderOnSearch}
                    >
                      {showLoaderOnSearch ? (
                        <i className="fa fa-spinner fa-spin fa-lg text-info px-3" />
                      ) : (
                        "Search"
                      )}
                    </Button>
                  </Col>
                  {/* Clear Button */}
                  {filterParams !== "" && (
                    <Col xs="auto" className="mb-2 mb-md-0 p-0">
                      <Button
                        className="btn-round btn-icon"
                        color="light"
                        outline
                        onClick={() => handleClear()}
                        size="md"
                      >
                        Clear
                      </Button>
                    </Col>
                  )}
                </Row>
                <ToolkitProvider
                  data={formattedCampaignList}
                  keyField="campaignCode"
                  columns={[
                    {
                      text: "Sr No.",
                      dataField: "srNo",
                    },
                    {
                      text: "Campaign Name",
                      dataField: "campaignName",
                      headerStyle: { width: "20%" },
                      sort: true,
                      formatter: (cell, row) => (
                        <div
                          className="text-truncate clickable-text"
                          style={{ maxWidth: "180px", cursor: "pointer" }}
                          onClick={() => handleOnClickTitle(row)}
                          title={cell}
                        >
                          {cell}
                        </div>
                      ),
                    },
                    {
                      text: "Time Zone",
                      dataField: "timeZoneLocation",
                      headerStyle: { width: "18%" },
                      formatter: (cell) => (
                        <div
                          className="text-truncate"
                          style={{ maxWidth: "100px" }}
                          title={cell}
                        >
                          {cell}
                        </div>
                      ),
                    },
                    {
                      text: "Campaign Dates", // More user-friendly column name
                      dataField: "startAt",
                      sort: true,
                      formatter: (cell, row) => (
                        <span>
                          {row.startAt}
                          <br />
                          <span
                            style={{ display: "block", textAlign: "center" }}
                          >
                            to
                          </span>
                          {/* <br /> */}
                          {row.endAt}
                        </span>
                      ),
                    },
                    // {
                    //   text: "End Date",
                    //   dataField: "endAt",
                    // },
                    {
                      text: "Total Attendees",
                      dataField: "attendeeCount",
                      formatter: (cell) => (
                        <div
                          className="d-flex"
                          style={{ justifyContent: "center" }}
                        >
                          {cell}
                        </div>
                      ),
                    },
                    {
                      text: "Created At",
                      dataField: "createdAt",
                      sort: true,
                    },
                    {
                      text: "Status",
                      dataField: "status",
                      formatter: (cell) => (
                        <div
                          style={{
                            color:
                              cell === "FAILED"
                                ? "red"
                                : cell === "IN-PROGRESS"
                                ? "orange"
                                : cell === "COMPLETED"
                                ? "green"
                                : statusColor1,
                          }}
                        >
                          {cell}
                        </div>
                      ),
                    },
                    {
                      text: "Action",
                      dataField: "Action",
                      formatter: (cell, row) => (
                        <div key={row.campaignCode} style={styles.actionRow}>
                          <div>
                            <a
                              className="table-action"
                              id={`tooltipDetails${row.campaignCode}`}
                              onClick={(e) =>
                                navigate("/a/campaigns/details", {
                                  state: { campaignCode: row.campaignCode },
                                })
                              }
                            >
                              <i className="fas fa-info-circle" />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target={`tooltipDetails${row.campaignCode}`}
                            >
                              Campaign Details
                            </UncontrolledTooltip>
                            {row.status !== "IN-PROGRESS" && (
                              <>
                                <a
                                  className="table-action"
                                  id={`tooltipEdit${row.campaignCode}`}
                                  onClick={(e) =>
                                    navigate("/a/campaigns/step-1", {
                                      state: { campaignCode: row.campaignCode },
                                    })
                                  }
                                >
                                  <i className="fas fa-edit" />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltipEdit${row.campaignCode}`}
                                >
                                  Edit Campaign
                                </UncontrolledTooltip>
                              </>
                            )}
                          </div>
                          <div className="text-gray">|</div>
                          <a
                            className="table-action table-action-delete"
                            id={`tooltipDel${row.campaignCode}`}
                            onClick={(e) => deleteWarning(row.campaignCode)}
                          >
                            <i className="fas fa-trash" />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target={`tooltipDel${row.campaignCode}`}
                          >
                            Delete Campaign
                          </UncontrolledTooltip>
                        </div>
                      ),
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-1 table-responsive">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        remote={{ sort: true }}
                        onTableChange={onTableChange}
                        defaultSortDirection={"asc"}
                      />
                      <div>
                        <CustomPagination
                          totalPages={Math.ceil(
                            campaignList.totalRecords /
                              campaignList.noOfRecordsPerPage
                          )}
                          setPageNum={setPageNum}
                          activePage={pageNum}
                        />
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default ListCampaign;

const styles = {
  cusrorPointer: { cursor: "pointer" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    flexDirection: "column",
    rowGap: "1rem",
  },
  loaderColor: { color: cyanBlue },
  actionRow: {
    display: "flex",
    flexDirection: "row",
    columnGap: "1rem",
  },
};
