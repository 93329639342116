/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { getCampaignList } from "action/campaign/campaignListing";
import { cyanBlue } from "components/Common/constants";
import { formatDateWithTime } from "components/CommonFunctions";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

function Dashboard() {
  const navigate = useNavigate();
  const campaignList = useSelector(
    (state) => state.campaignListReducers.campaignList
  );
  const [showListAlternative, setShowListAlternative] = useState({
    loader: true,
    noRecordsFound: false,
    error: false,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (campaignList?.Campaign && campaignList?.Campaign?.length > 0) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
    } else if (campaignList?.Campaign && campaignList?.Campaign?.length === 0) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: true,
      });
    } else if (campaignList?.code) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
        error: true,
      });
    }
  }, [campaignList]);
  useEffect(() => {
    const filterParams = `&filtersJSON=[{"field": "StartAt", "condition": "gt", "filterValues": [""]}]`;
    getCampaignList(dispatch, 1, "", filterParams);
  }, []);

  const formattedCampaignList = campaignList?.Campaign?.map((item, index) => {
    return {
      ...item,
      srNo: index + 1,
      createdAt: formatDateWithTime(item.createdAt),
      startAt: formatDateWithTime(item.startAt),
      endAt: formatDateWithTime(item.endAt),
    };
  });

  return (
    <>
      {/* <SimpleHeader name="Company Name" /> */}
      <Container className="mt--6" fluid>
        <Row md={12} lg={12} sm={12}>
          <Col md={12} lg={12} sm={12}>
            <Card>
              <CardBody className="h3 d-flex justify-content-between mb--4">
                <div>Upcoming Campaigns </div>
                {!showListAlternative.noRecordsFound && (
                  <Col className="text-right" xs="8">
                    <Button
                      color="info"
                      onClick={() => {
                        navigate("/a/campaigns/step-1");
                      }}
                    >
                      Create Campaign
                    </Button>
                  </Col>
                )}
              </CardBody>
              <CardBody>
                {showListAlternative.loader ? (
                  <div style={styles.loaderContainer}>
                    <i
                      className="fa fa-spinner fa-spin fa-2xl"
                      style={styles.loaderColor}
                    />
                  </div>
                ) : showListAlternative.noRecordsFound ? (
                  <div style={styles.loaderContainer}>
                    <Button
                      color="info"
                      onClick={() => {
                        navigate("/a/campaigns/step-1");
                      }}
                    >
                      Create Campaign
                    </Button>
                  </div>
                ) : showListAlternative?.error ? (
                  <div style={styles.loaderContainer}>
                    Something went wrong Please try after sometime.
                  </div>
                ) : (
                  <ToolkitProvider
                    data={formattedCampaignList}
                    keyField="srNo"
                    columns={[
                      {
                        text: "SR.NO",
                        dataField: "srNo",
                        headerStyle: { width: "120px" },
                      },
                      {
                        text: "Campaign Name",
                        dataField: "campaignName",
                        headerStyle: { width: "150px" },

                        formatter: (cell) => (
                          <div
                            className="text-truncate"
                            style={{ maxWidth: "150px" }}
                            title={cell}
                          >
                            {cell}
                          </div>
                        ),
                      },
                      {
                        text: "Campaign Dates", // More user-friendly column name
                        dataField: "startAt",
                        headerStyle: { width: "150px" },
                        headerAlign: "left",
                        align: "left",
                        formatter: (cell, row) => (
                          <span>
                            {row.startAt}
                            <br />
                            <span
                              style={{
                                marginLeft: "12%",
                              }}
                            >
                              to
                            </span>
                            <br />
                            {row.endAt}
                          </span>
                        ),
                      },
                      // {
                      //   text: "End Date",
                      //   dataField: "endAt",
                      //   headerStyle: { width: "150px" },
                      // },
                      {
                        text: "Status",
                        dataField: "status",
                        headerStyle: { width: "100px" },
                      },
                      {
                        text: "Created On",
                        dataField: "createdAt",
                        headerStyle: { width: "150px" },
                      },
                    ]}
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                        />
                        <div></div>
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;

const styles = {
  cusrorPointer: { cursor: "pointer" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  loaderColor: { color: cyanBlue },
};
